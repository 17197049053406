import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTypography from "../muicomponents/shared/CustomTypography";
import logo from "../Images/homepage/logo.png";

const NavbarCart = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let currentStep = "cart";
  if (location.pathname.includes("address")) {
    currentStep = "address";
  } else if (location.pathname.includes("payment")) {
    currentStep = "payment";
  }
  const getStepStyle = (step) => ({
    borderBottom: step === currentStep ? "2px solid #20BD99" : "none",
    color: step === currentStep ? "#20BD99" : "inherit",
    fontWeight: step === currentStep ? "600" : "400",
  });
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down("desktop"));

  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderBottom: "1px solid #eaeaec",
      }}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          padding: isTabletScreen ? "1rem" : "1rem 2rem",
        }}
      >
        <Box>
          <img
            onClick={() => navigate("/")}
            src={logo}
            alt=""
            style={{ width: "150px", height: "50px" }}
          />
        </Box>

        <Box display={"flex"} alignItems={"center"} gap={"0.5rem"}>
          <Box>
            <CustomTypography sx={getStepStyle("cart")}>BAG</CustomTypography>
          </Box>
          <Box
            sx={{
              borderTop: "1px dashed black",
              width: "5rem",
              height: "1px",
            }}
          />
          <Box>
            <CustomTypography sx={getStepStyle("address")}>
              ADDRESS
            </CustomTypography>
          </Box>
          <Box
            sx={{
              borderTop: "1px dashed black",
              width: "5rem",
              height: "1px",
            }}
          />
          <Box>
            <CustomTypography sx={getStepStyle("payment")}>
              PAYMENT
            </CustomTypography>
          </Box>
        </Box>

        <Box>
          <CustomTypography sx={{ letterSpacing: "3px", color: "#535766" }}>
            100% SECURE
          </CustomTypography>
        </Box>
      </Box>
    </Box>
  );
};

export default NavbarCart;
