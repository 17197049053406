import { Box } from '@mui/material';
import React from 'react';

const FlexBetween = ({ children, sx, rest }) => {
  return (
    <Box display={'flex'} justifyContent={'space-between'} {...rest}>
      {children}
    </Box>
  );
};

export default FlexBetween;
