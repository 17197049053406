import { Box, useMediaQuery } from '@mui/material';
import React from 'react';

const OfferCard = ({ children, sx, props }) => {
  const islaptopScreen = useMediaQuery('(max-width:1024px)');
  return (
    <Box
      sx={{
        width: islaptopScreen ? '100%' : '80%',
        Height: '150px',
        padding: '1rem',
        borderRadius: '1rem',
        '&:hover': {
          cursor: 'pointer',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default OfferCard;
