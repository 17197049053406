import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import CustomTypography from '../../../../../muicomponents/shared/CustomTypography';
import AddAddressModal from '../modals/AddAddressModal';
import AddressComponent from './AddressComponent';
import AddNewAddressComponent from './AddNewAddressComponent';
import { useRecoilState } from 'recoil';
import { selectedAddressIdState } from '../../../../../store/atoms/addAddress/addAddressAtoms';
import DefaultAddressComponent from './DefaultAddressComponent';
import NonDefaultAddress from './NonDefaultAddress';

const AllAddress = ({ addresses }) => {
  const defaultAddress = addresses.find((address) => address.isDefault);
  const nonDefaultAddress = addresses.filter((address) => !address.isDefault);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useRecoilState(
    selectedAddressIdState
  );

  return (
    <>
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{ borderRight: '1px solid #eaeaec', padding: '1rem 1rem 0 0' }}
      >
        <Box display={'flex'} justifyContent={'space-between'}>
          <CustomTypography variant={'subtitle1'} sx={{ fontWeight: '600' }}>
            Selected Delivery Address
          </CustomTypography>

          <Button
            variant={'contained'}
            sx={{
              backgroundColor: 'white',
              color: 'black',
              boxShadow: 'none',
              border: '1px solid #C0C0C0',
              '&:hover': {
                color: 'rgb(6, 155, 170)',
                backgroundColor: 'white',
                boxShadow: 'none',
              },
            }}
            onClick={() => setModalOpen(true)}
          >
            ADD NEW ADDRESS
          </Button>
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
          {defaultAddress ? (
            <DefaultAddressComponent
              address={defaultAddress}
              isSelected={selectedAddressId === defaultAddress.id}
              onSelect={() => setSelectedAddressId(defaultAddress.id)}
            />
          ) : (
            <CustomTypography>No default address set</CustomTypography>
          )}
          {nonDefaultAddress.length > 0 && (
            <NonDefaultAddress allAddress={nonDefaultAddress} />
          )}

          <AddNewAddressComponent />
        </Box>
      </Box>
      <AddAddressModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </>
  );
};

export default AllAddress;
