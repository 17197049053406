import { Box } from '@mui/material';
import React from 'react';

const CustomCard = ({ children, sx, ...props }) => {
  return (
    <Box
      sx={{
        padding: '1rem',
        backgroundColor: 'rgb(206, 237, 240)',
        border: '1px solid rgb(180, 225, 229)',
        borderRadius: '0.5rem',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default CustomCard;
