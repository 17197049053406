import { Box } from '@mui/material';
import React from 'react';
import CustomTypography from '../../../../../muicomponents/shared/CustomTypography';
import AddressComponentMobile from './AddressComponentMobile';

const DefaultAddressMobile = ({ defaultAddress }) => {

  return (
    <Box display={'flex'} flexDirection={'column'} sx={{ width: '100%' }}>
      <Box sx={{ padding: '1rem' }}>
        <CustomTypography
          variant={'subtitle2'}
          sx={{ fontWeight: '600', textTransform: 'uppercase' }}
        >
          Default Address
        </CustomTypography>
      </Box>
      <Box>
        <AddressComponentMobile defaultAddress={defaultAddress} />
      </Box>
    </Box>
  );
};

export default DefaultAddressMobile;
