import { Close } from '@mui/icons-material';
import {
  Slide,
  Modal,
  Box,
  Typography,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import ReactHtmlParser from 'react-html-parser';

import React from 'react';
import { ThumbUpOutlined } from '@mui/icons-material';
const ConditionModal = ({ setModalOpen, modalOpen, modalData }) => {
  const isSmallScreen = useMediaQuery('(max-width:768px)');
  const handleModalClose = () => {
    setModalOpen(false);
  };


  return (
    <Modal open={modalOpen} onClose={handleModalClose} closeAfterTransition>
      <Slide
        direction={isSmallScreen ? 'up' : 'left'}
        in={modalOpen}
        mountOnEnter
        unmountOnExit
      >
        <Box
          sx={{
            position: 'fixed',
            right: isSmallScreen ? 'auto' : 0,
            top: isSmallScreen ? 'auto' : 0,
            bottom: isSmallScreen ? 0 : 'auto',
            width: isSmallScreen ? '100%' : 'auto',
            height: isSmallScreen ? '100%' : '100%',
            minHeight: isSmallScreen ? '50%' : '100%',
            minWidth: !isSmallScreen ? '40%' : '100%',
            maxWidth: isSmallScreen ? '100%' : '60%',
            bgcolor: 'background.paper',
            p: isSmallScreen ? '2rem' : '4rem',
            overflowY: 'auto',
            transform: 'translateX(0)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
              cursor: 'pointer',
              borderBottom: isSmallScreen ? '1px solid 	#B2BEB5' : '',
              paddingBottom: '0.3rem',
            }}
          >
            <Box display={'flex'} gap={'0.2rem'}>
              <Box>
                {isSmallScreen && (
                  <Typography
                    variant='h6'
                    component='h2'
                    sx={{
                      fontFamily: '"Source Sans 3", sans-serif',
                      fontWeight: '500',
                    }}
                  >
                    {modalData.title}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box>
              <IconButton onClick={handleModalClose}>
                <Close />
              </IconButton>
            </Box>
          </Box>
          {!isSmallScreen && (
            <Typography
              variant='h6'
              component='h2'
              sx={{
                fontFamily: '"Source Sans 3", sans-serif',
                fontWeight: '500',
              }}
            >
              {modalData.title}
            </Typography>
          )}
          <Typography
            variant='body1'
            sx={{
              fontFamily: '"Work Sans", sans-serif',
              marginTop: '1rem',
              padding: '0.5rem',
              color: '#818589',
            }}
          >
            {ReactHtmlParser(modalData.description)}
          </Typography>
        </Box>
      </Slide>
    </Modal>
  );
};

export default ConditionModal;
