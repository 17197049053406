import React from 'react';
import AssuredCard from '../../muiComponents/AssuredCard';
import Card1 from '../../../../Images/homepage/card1.png';
import Card2 from '../../../../Images/homepage/card2.png';
import Card3 from '../../../../Images/homepage/card3.png';
import Card4 from '../../../../Images/homepage/card4.png';
import { Box, useMediaQuery } from '@mui/material';
import CustomTypography from '../../../../muicomponents/shared/CustomTypography';

const HelloFiAssured = () => {
  const isSmallScreen = useMediaQuery('(max-width:767px)');

  const data = [
    {
      img: Card1,
      description: '32 Points Quality Checks',
    },
    {
      img: Card2,
      description: 'Easy Replacement Policy',
    },
    {
      img: Card3,
      description: '3 Months HelloFi Service Warranty',
    },
    {
      img: Card4,
      description: 'Lifetime On Call Assistance Post Sales',
    },
  ];

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={'1rem'}
      marginTop={isSmallScreen ? '1rem' : ''}
    >
      <Box>
        <CustomTypography
          variant='h4'
          sx={{
            fontOpticalSizing: 'auto',
            fontWeight: '600',
            fontSize: isSmallScreen ? '20px' : '24px',
          }}
        >
          HelloFi Assured
        </CustomTypography>
      </Box>
      <Box
        display='flex'
        flexDirection='row'
        gap='1rem'
        sx={{
          width: '100%',
          mx: 'auto',
          overflowX: 'scroll',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',

          '&::-webkit-scrollbar': {
            display: 'none',
          },
          flexWrap: 'nowrap',
        }}
      >
        {data.map((item, index) => (
          <AssuredCard
            key={index}
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            sx={{
              flex: '1 0 auto',
              width: '240px',
              height: '200px',
            }}
          >
            <Box>
              <img
                src={item.img}
                alt={item.description}
                style={{ maxWidth: '100%' }}
              />
            </Box>
            <Box>
              <CustomTypography sx={{ textAlign: 'center', fontWeight: '500' }}>
                {item.description}
              </CustomTypography>
            </Box>
          </AssuredCard>
        ))}
      </Box>
    </Box>
  );
};

export default HelloFiAssured;
