import { Box } from '@mui/material';
import React from 'react';
import NavbarCartMobile from '../../../../../layout/NavbarCartMobile';
import Form from '../../addAddress/addressForm/Form';
import useGetAllAddress from '../../../hooks/addAddress/useGetAllAddress';

const AddNewAddress = () => {
  const userId = localStorage.getItem('userId');
  const { allAddress, loading, error, refetch } = useGetAllAddress(userId);
  const isAddress = allAddress.length > 0 ? false : true;
  return (
    <>
      <NavbarCartMobile
        title={'add new Address'}
        isAddressMain={false}
        isAddAddressPage={true}
        navigateReturn={isAddress ? '/checkout/cart' : '/checkout/address/list'}
      />
      <Form isMobile={true} />
    </>
  );
};

export default AddNewAddress;
