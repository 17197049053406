import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState } from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import useDeleteAddress from "../../../hooks/addAddress/useDeleteAddress";
import AddAddressModal from "../modals/AddAddressModal";

const AddressComponent = ({ address, isSelected, onSelect }) => {
  const { deleteAddressById } = useDeleteAddress();
  const [modalOpen, setModalOpen] = useState(false);
  const handleDeleteAddress = () => {
    deleteAddressById(address.id, address.user);
  };
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  return (
    <>
      <Box
        display={"flex"}
        marginTop={"1rem"}
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          borderRadius: "0.2rem",
          padding: "1rem",
        }}
      >
        <Box>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
            <FormControlLabel
              checked={isSelected}
              onChange={onSelect}
              control={<Radio />}
            />
          </RadioGroup>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          gap={"0.5rem"}
        >
          <Box display={"flex"} gap={"1rem"}>
            <CustomTypography variant={"subtitle1"} sx={{ fontWeight: "600" }}>
              {address.name}
            </CustomTypography>
            <Box
              sx={{
                padding: "0.4rem 1rem",
                border: "1px solid #03a685",
                borderRadius: "1rem",
              }}
            >
              <CustomTypography
                variant={"subtitle2"}
                sx={{
                  fontWeight: "500",
                  color: "#03a685",
                  fontSize: "12px",
                  textTransform: "uppercase",
                }}
              >
                {address.addressType}
              </CustomTypography>
            </Box>
          </Box>
          <Box>
            <CustomTypography variant={"subtitle2"}>
              {address.address}
            </CustomTypography>
            <CustomTypography variant={"subtitle2"}>
              {`${address.cityDistrict}, ${address.state} - ${address.pinCode}`}
            </CustomTypography>
          </Box>
          <Box>
            <CustomTypography variant={"subtitle2"}>
              {`Mobile: ${address.contact}`}
            </CustomTypography>
          </Box>

          <Box display={"flex"} gap={"1rem"}>
            <Button
              onClick={handleDeleteAddress}
              variant={"contained"}
              sx={{
                color: "black",
                borderRadius: "0.2rem",
                backgroundColor: "white",
                border: "1px solid black",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: "white",
                },
              }}
            >
              REMOVE
            </Button>
            <Button
              variant={"contained"}
              onClick={handleOpenModal}
              sx={{
                color: "black",
                borderRadius: "0.2rem",
                backgroundColor: "white",
                border: "1px solid black",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: "white",
                },
              }}
            >
              EDIT
            </Button>
          </Box>
        </Box>
      </Box>
      <AddAddressModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        isEdit={true}
        address={address}
      />
    </>
  );
};

export default AddressComponent;
