import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../ComponentsCss/Footer.css";
import { Link } from "react-router-dom";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaLongArrowAltRight,
  FaRegEnvelope,
  FaTelegramPlane,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import CategoryModal from "./HomePageModals/Category";
import BuyCategoryModal from "./HomePageModals/BuyCategoryModal";

export default function Footer() {
  const navigate = useNavigate();

  const handleNavLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleNavigateTo = (to) => {
    handleNavLinkClick();
    navigate(to);
  };

  const handleLinksClick = (e) => {
    e.preventDefault();
  };
  const [showCategory, setShowCategory] = useState(false);
  const [showCategory2, setShowCategory2] = useState(false);

  return (
    <>
      <Container className="footermain" fluid style={{ minWidth: "300px" }}>
        <Row>
          <Col md={3}>
            <div className="footertxt mx-auto d-block">
              <p>
                {" "}
                <Link className="lnktxt" to="/contact">
                  Contact Us
                </Link>
              </p>
              <p>
                {" "}
                <Link className="lnktxt" to="/faq">
                  FAQ
                </Link>
              </p>
              <p>
                {" "}
                <Link className="lnktxt" to="/careers">
                  Career
                </Link>
              </p>
              <p>
                <Link className="lnktxt" to="/aboutus">
                  About Us
                </Link>
              </p>
              <p>
                <Link className="lnktxt" to="/terms-condition">
                  Term & Conditions
                </Link>
              </p>
            </div>
            <hr className="hrfooter"></hr>
          </Col>
          <Col md={3}>
            <div className="footertxt mx-auto d-block">
              {/* <p><Link className='lnktxt' to="/contact" >PLACE RETURN REQUEST</Link></p> */}
              <p>
                {" "}
                <Link className="lnktxt" to="/privacy-policy">
                  PRIVACY POLICY
                </Link>
              </p>
              <p className="text-uppercase">
                {" "}
                <Link className="lnktxt" to="/shipping-delivery-policy">
                  Shipping and delivery policy
                </Link>
              </p>
              <p className="text-uppercase">
                {" "}
                <Link className="lnktxt" to="/return-refund-policy">
                  Return and Refund Policy
                </Link>
              </p>
              <p>
                {" "}
                <Link className="lnktxt">GST Number - 29AAQFH3388A1Z4</Link>
              </p>
            </div>
            <hr className="hrfooter"></hr>
          </Col>
          <Col md={3}>
            <div className="footertxt mx-auto d-block">
              <h4 style={{ fontSize: 14, letterSpacing: 3 }}>CUSTOMER CARE</h4>

              <p style={{ marginTop: 10 }}>
                Timings: 11AM - 8PM (MON - SUN)
                <br />
                Call: +91 8150835583
                <br />
                E-mail:{" "}
                <a
                  href="mailto:contact@hellofi.in"
                  style={{ color: "#ffffff", textDecoration: "underline" }}
                >
                  contact@hellofi.in
                </a>
              </p>
            </div>
            <hr className="hrfooter"></hr>
            <div
              className="d-flex justify-content-start"
              style={{ width: "100%" }}
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.6765468479884!2d77.6334457748406!3d12.928498587382931!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae150c3f13efb3%3A0x7c63fb5ed43e5ec!2sHelloFi%20Recommerce!5e0!3m2!1sen!2sin!4v1702113122992!5m2!1sen!2sin"
                width="100%"
                height="100"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </Col>
          <Col md={3}>
            <div className="footertxt mx-auto d-block">
              <h4 style={{ fontSize: 14, letterSpacing: 3 }}>
                SIGN UP AND SAVE
              </h4>
              <p style={{ fontSize: 12, marginTop: 10 }}>
                Sign up now and be the first to know about exclusive offers,
                latest updates.
              </p>

              {/* <Button className='btnfooter'>Chat With Us <FaLongArrowAltRight /></Button> */}
            </div>
            <div>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <div className="foottxtin">
                    {" "}
                    <Form.Control
                      className="foottext"
                      type="email"
                      placeholder="Enter email"
                    />
                    <Link to={"mailto:contact@hellofi.in"}>
                      <FaRegEnvelope
                        size={30}
                        style={{ cursor: "pointer" }}
                        className="text-dark"
                      />
                    </Link>
                  </div>
                </Form.Group>
              </Form>
              <div className="iconsfoot">
                <div className="socialicn">
                  <a
                    href="https://www.facebook.com/profile.php?id=100087955281339&mibextid=ZbWKwL"
                    target="_blank"
                  >
                    <FaFacebookF style={{ color: "#ffffff" }} />
                  </a>
                </div>
                <div className="socialicn" style={{ marginLeft: 10 }}>
                  <a
                    href="https://wa.me/message/LVQPSJWMOT57O1"
                    target="_blank"
                  >
                    <FaWhatsapp style={{ color: "#ffffff" }} />
                  </a>
                </div>
                {/* <div className='socialicn' style={{ marginLeft: 10 }}>
                                    <a href='' target='_blank' >

                                        <FaTelegramPlane style={{ color: '#ffffff' }} />
                                    </a>

                                </div> */}
                <div className="socialicn" style={{ marginLeft: 10 }}>
                  <a
                    href="https://www.instagram.com/hellofi_recommerce?igshid=NTc4MTIwNjQ2YQ"
                    target="_blank"
                  >
                    <FaInstagram style={{ color: "#ffffff" }} />
                  </a>
                </div>
                <div className="socialicn" style={{ marginLeft: 10 }}>
                  <a href="https://twitter.com/hellofi_recom" target="_blank">
                    <FaTwitter style={{ color: "#ffffff" }} />
                  </a>
                </div>
                <div className="socialicn" style={{ marginLeft: 10 }}>
                  <a
                    href="https://www.linkedin.com/company/hellofi-recommerce/"
                    target="_blank"
                  >
                    <FaLinkedinIn style={{ color: "#ffffff" }} />
                  </a>
                </div>
              </div>
            </div>
            <hr className="hrfooter"></hr>
          </Col>
        </Row>
        <Row>
          <Col md={9} style={{ fontSize: 13, width: "100%" }}>
            <div className="footertxt mx-auto d-block custom-link">
              <h4 style={{ fontSize: 18 }}>Sell Phones | Laptops | Tablets</h4>
              <Link
                to="/selling/Phones"
                onClick={() => handleNavigateTo("/selling/Phones")}
                className="custom-link"
              >
                sell Old Mobile Phones In Koramangala
              </Link>{" "}
              |
              <Link
                to="/selling/Phones"
                onClick={() => handleNavigateTo("/selling/Phones")}
                className="custom-link"
              >
                sell Old Mobile Phones In Mumbai
              </Link>{" "}
              |
              <Link
                to="/selling/Phones"
                onClick={() => handleNavigateTo("/selling/Phones")}
                className="custom-link"
              >
                sell Old Mobile Phones In Hyderabad
              </Link>{" "}
              |
              <Link
                to="/selling/Phones"
                onClick={() => handleNavigateTo("/selling/Phones")}
                className="custom-link"
              >
                sell Old Mobile Phones In Bangalore
              </Link>{" "}
              |
              <Link
                to="/selling/Phones"
                onClick={() => handleNavigateTo("/selling/Phones")}
                className="custom-link"
              >
                sell Old Mobile Phones In Kolkata
              </Link>{" "}
              |
              <Link
                to="/selling/Phones"
                onClick={() => handleNavigateTo("/selling/Phones")}
                className="custom-link"
              >
                sell apple iPhone in Bangalore
              </Link>{" "}
              |
              <Link
                to="/selling/Phones"
                onClick={() => handleNavigateTo("/selling/Phones")}
                className="custom-link"
              >
                sell apple iPhone in Mumbai
              </Link>{" "}
              |
              <Link
                to="/selling/Phones"
                onClick={() => handleNavigateTo("/selling/Phones")}
                className="custom-link"
              >
                sell Old Mobile Phones In Hyderabad
              </Link>{" "}
              |
              <Link
                to="/selling/Phones"
                onClick={() => handleNavigateTo("/selling/Phones")}
                className="custom-link"
              >
                sell Old Mobile Phones In Bhubaneswar
              </Link>{" "}
              |
              <Link
                to="/selling/Phones"
                onClick={() => handleNavigateTo("/selling/Phones")}
                className="custom-link"
              >
                sell Old Mobile Phones In Agartala
              </Link>{" "}
              |
              <Link
                to="/selling/Phones"
                onClick={() => handleNavigateTo("/selling/Phones")}
                className="custom-link"
              >
                sell apple iPhone in Mumbai
              </Link>{" "}
              |
              <Link
                to="/selling/Phones"
                onClick={() => handleNavigateTo("/selling/Phones")}
                className="custom-link"
              >
                sell apple iPhone in Kolkata
              </Link>{" "}
              |
              <Link
                to="/selling/Phones"
                onClick={() => handleNavigateTo("/selling/Phones")}
                className="custom-link"
              >
                sell apple iPhone in Koramangala
              </Link>{" "}
              |
              <Link
                to="/selling/Laptops"
                onClick={() => handleNavigateTo("/selling/Laptops")}
                className="custom-link"
              >
                sell Old Laptops in Koramangala
              </Link>{" "}
              |
              <Link
                to="selling/Laptops"
                onClick={() => handleNavigateTo("/selling/Laptops")}
                className="custom-link"
              >
                sell Old Laptops in Mumbai
              </Link>{" "}
              |
              <Link
                to="/selling/Laptops"
                onClick={() => handleNavigateTo("/selling/Laptops")}
                className="custom-link"
              >
                sell Old Laptops in Bangalore
              </Link>{" "}
              |
              <Link
                to="/selling/Laptops"
                onClick={() => handleNavigateTo("/selling/Laptops")}
                className="custom-link"
              >
                sell Old Laptops in Kolkata
              </Link>{" "}
              |
              <Link
                to="/selling/Laptops"
                onClick={() => handleNavigateTo("/selling/Laptops")}
                className="custom-link"
              >
                sell old apple macbook in Mumbai
              </Link>{" "}
              |
              <Link
                to="/selling/Laptops"
                onClick={() => handleNavigateTo("/selling/Laptops")}
                className="custom-link"
              >
                sell old apple macbook in Mumbai
              </Link>{" "}
              |
              <Link
                to="/selling/Laptops"
                onClick={() => handleNavigateTo("/selling/Laptops")}
                className="custom-link"
              >
                sell old apple macbook in Bangalore
              </Link>{" "}
              |
              <Link
                to="/selling/Tablets"
                onClick={() => handleNavigateTo("/selling/Tablets")}
                className="custom-link"
              >
                sell Tablets in Mumbai
              </Link>{" "}
              |
              <Link
                to="/selling/Tablets"
                onClick={() => handleNavigateTo("/selling/Tablets")}
                className="custom-link"
              >
                sell Tablets in Mumbai
              </Link>{" "}
              |
              <Link
                to="/selling/Tablets"
                onClick={() => handleNavigateTo("/selling/Tablets")}
                className="custom-link"
              >
                sell apple iPad in Bangalore
              </Link>{" "}
              |
              <Link
                to="/selling/Tablets"
                onClick={() => handleNavigateTo("/selling/Tablets")}
                className="custom-link"
              >
                sell apple iPad in Mumbai
              </Link>{" "}
              |
              <Link
                to="/selling/Tablets"
                onClick={() => handleNavigateTo("/selling/Tablets")}
                className="custom-link"
              >
                sell used Samsung tablets
              </Link>{" "}
              |
              <Link
                to="/selling/Tablets"
                onClick={() => handleNavigateTo("/selling/Tablets")}
                className="custom-link"
              >
                sell used Mi pads
              </Link>{" "}
              |
              <Link
                to="/selling/Tablets"
                onClick={() => handleNavigateTo("/selling/Tablets")}
                className="custom-link"
              >
                sell used OnePlus tabs
              </Link>{" "}
              |
              <Link
                to="/selling/Tablets"
                onClick={() => handleNavigateTo("/selling/Tablets")}
                className="custom-link"
              >
                sell used apple iPads
              </Link>{" "}
              |
              <Link
                to="/selling/Tablets"
                onClick={() => handleNavigateTo("/selling/Tablets")}
                className="custom-link"
              >
                sell Tablets in Bangalore
              </Link>{" "}
              |
              <Link
                to="/selling/Tablets"
                onClick={() => handleNavigateTo("/selling/Tablets")}
                className="custom-link"
              >
                sell Tablets in Kolkata
              </Link>{" "}
              |
              <Link
                to="/selling/Tablets"
                onClick={() => handleNavigateTo("/selling/Tablets")}
                className="custom-link"
              >
                sell Tablets in Koramangala
              </Link>{" "}
              |
              <Link
                to="/selling/Tablets"
                onClick={() => handleNavigateTo("/selling/Tablets")}
                className="custom-link"
              >
                sell apple iPad in Kolkata
              </Link>{" "}
              |
              <Link
                to="/selling/Tablets"
                onClick={() => handleNavigateTo("/selling/Tablets")}
                className="custom-link"
              >
                sell apple iPad in Koramangala
              </Link>{" "}
              |
              <Link
                to="/selling/Desktop-Imac"
                onClick={() => handleNavigateTo("/selling/Desktop-Imac")}
                className="custom-link"
              >
                sell macmini in Bangalore
              </Link>{" "}
              |
              <Link
                to="/selling/Desktop-Imac"
                onClick={() => handleNavigateTo("/selling/Desktop-Imac")}
                className="custom-link"
              >
                sell macmini in Kolkata
              </Link>{" "}
              |
              <Link
                to="/selling/Desktop-Imac"
                onClick={() => handleNavigateTo("/selling/Desktop-Imac")}
                className="custom-link"
              >
                sell macmini in Mumbai
              </Link>{" "}
              |
              <Link
                to="/selling/Desktop-Imac"
                onClick={() => handleNavigateTo("/selling/Desktop-Imac")}
                className="custom-link"
              >
                sell macmini in Bhubaneswar
              </Link>{" "}
              |
              <Link
                to="/selling/Desktop-Imac"
                onClick={() => handleNavigateTo("/selling/Desktop-Imac")}
                className="custom-link"
              >
                sell old apple macbook in Kolkata
              </Link>{" "}
              |
              <Link
                to="/selling/Desktop-Imac"
                onClick={() => handleNavigateTo("/selling/Desktop-Imac")}
                className="custom-link"
              >
                sell old apple macbook in Koramanala
              </Link>{" "}
              |
              <Link
                to="/otheraccessories"
                onClick={() => handleNavigateTo("/otheraccessories")}
                className="custom-link"
              >
                sell smartwatch in Mumbai
              </Link>{" "}
              |
              <Link
                to="/otheraccessories"
                onClick={() => handleNavigateTo("/otheraccessories")}
                className="custom-link"
              >
                sell smartwatch in Bangalore
              </Link>{" "}
              |
              <Link
                to="/otheraccessories"
                onClick={() => handleNavigateTo("/otheraccessories")}
                className="custom-link"
              >
                sell smartwatch in Bhubaneswar
              </Link>{" "}
              |
              <Link
                to="/otheraccessories"
                onClick={() => handleNavigateTo("/otheraccessories")}
                className="custom-link"
              >
                sell smartwatch in Hyderabad
              </Link>{" "}
              |
              <Link
                to="/otheraccessories"
                onClick={() => handleNavigateTo("/otheraccessories")}
                className="custom-link"
              >
                sell smartwatch in Kolkata
              </Link>{" "}
              |
              <Link
                to="/otheraccessories"
                onClick={() => handleNavigateTo("/otheraccessories")}
                className="custom-link"
              >
                sell old phone
              </Link>{" "}
              |
              <Link
                to="/otheraccessories"
                onClick={() => handleNavigateTo("/otheraccessories")}
                className="custom-link"
              >
                sell used apple smartwatch
              </Link>{" "}
              |
              <Link
                to="/otheraccessories"
                onClick={() => handleNavigateTo("/otheraccessories")}
                className="custom-link"
              >
                sell used Samsung smartwatch
              </Link>{" "}
              |
              <Link
                to="/otheraccessories"
                onClick={() => handleNavigateTo("/otheraccessories")}
                className="custom-link"
              >
                sell iWatch online
              </Link>
            </div>
          </Col>
          <Col md={9} style={{ fontSize: 13, width: "100%" }}>
            <div className="footertxt mx-auto d-block custom-link">
              <h4 style={{ fontSize: 18 }}>
                Buy Used Phones | Used Laptops | Used Tablets
              </h4>
              <Link
                to="/buying-main-page/Laptops"
                onClick={() => handleNavigateTo("/buying-main-page/Laptops")}
                className="custom-link"
              >
                Buy old laptop
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Laptops"
                onClick={() => handleNavigateTo("/buying-main-page/Laptops")}
                className="custom-link"
              >
                buy apple macbook
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Laptops"
                onClick={() => handleNavigateTo("/buying-main-page/Laptops")}
                className="custom-link"
              >
                buy used apple macbook
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Laptops"
                onClick={() => handleNavigateTo("/buying-main-page/Laptops")}
                className="custom-link"
              >
                buy used macbook
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Laptops"
                onClick={() => handleNavigateTo("/buying-main-page/Laptops")}
                className="custom-link"
              >
                buy apple iphone
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Laptops"
                onClick={() => handleNavigateTo("/buying-main-page/Laptops")}
                className="custom-link"
              >
                old phones buy
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Laptops"
                onClick={() => handleNavigateTo("/buying-main-page/Laptops")}
                className="custom-link"
              >
                mobile for cash
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Laptops"
                onClick={() => handleNavigateTo("/buying-main-page/Laptops")}
                className="custom-link"
              >
                buy used apple ipad
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Laptops"
                onClick={() => handleNavigateTo("/buying-main-page/Laptops")}
                className="custom-link"
              >
                buy used tablet
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Laptops"
                onClick={() => handleNavigateTo("/buying-main-page/Laptops")}
                className="custom-link"
              >
                buy apple smartwatch
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Phones"
                onClick={() => handleNavigateTo("/buying-main-page/Phones")}
                className="custom-link"
              >
                buy iphone in bangalore
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Phones"
                onClick={() => handleNavigateTo("/buying-main-page/Phones")}
                className="custom-link"
              >
                buy used phones
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Phones"
                onClick={() => handleNavigateTo("/buying-main-page/Phones")}
                className="custom-link"
              >
                buy second hand mobile
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Phones"
                onClick={() => handleNavigateTo("/buying-main-page/Phones")}
                className="custom-link"
              >
                second hand mobile phone
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Phones"
                onClick={() => handleNavigateTo("/buying-main-page/Phones")}
                className="custom-link"
              >
                buy used apple mobile
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Phones"
                onClick={() => handleNavigateTo("/buying-main-page/Phones")}
                className="custom-link"
              >
                buy used Samsung mobile
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Phones"
                onClick={() => handleNavigateTo("/buying-main-page/Phones")}
                className="custom-link"
              >
                buy used oneplus mobile
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Phones"
                onClick={() => handleNavigateTo("/buying-main-page/Phones")}
                className="custom-link"
              >
                buy used oppo mobile
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Phones"
                onClick={() => handleNavigateTo("/buying-main-page/Phones")}
                className="custom-link"
              >
                buy used realme mobile
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Phones"
                onClick={() => handleNavigateTo("/buying-main-page/Phones")}
                className="custom-link"
              >
                buy used nothing mobile
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Phones"
                onClick={() => handleNavigateTo("/buying-main-page/Phones")}
                className="custom-link"
              >
                buy used xiaomi mobile
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Phones"
                onClick={() => handleNavigateTo("/buying-main-page/Phones")}
                className="custom-link"
              >
                buy used iqoo mobile
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Phones"
                onClick={() => handleNavigateTo("/buying-main-page/Phones")}
                className="custom-link"
              >
                buy used google mobile
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Phones"
                onClick={() => handleNavigateTo("/buying-main-page/Phones")}
                className="custom-link"
              >
                buy used vivo mobile
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Laptops"
                onClick={() => handleNavigateTo("/buying-main-page/Laptops")}
                className="custom-link"
              >
                buy used apple laptop
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Laptops"
                onClick={() => handleNavigateTo("/buying-main-page/Laptops")}
                className="custom-link"
              >
                buy used dell laptop
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Laptops"
                onClick={() => handleNavigateTo("/buying-main-page/Laptops")}
                className="custom-link"
              >
                buy used hp laptop
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Laptops"
                onClick={() => handleNavigateTo("/buying-main-page/Laptops")}
                className="custom-link"
              >
                buy used Lenovo laptop
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Laptops"
                onClick={() => handleNavigateTo("/buying-main-page/Laptops")}
                className="custom-link"
              >
                buy used Samsung laptop
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Laptops"
                onClick={() => handleNavigateTo("/buying-main-page/Laptops")}
                className="custom-link"
              >
                buy used asus laptop
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Laptops"
                onClick={() => handleNavigateTo("/buying-main-page/Laptops")}
                className="custom-link"
              >
                buy used acer laptop
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Laptops"
                onClick={() => handleNavigateTo("/buying-main-page/Laptops")}
                className="custom-link"
              >
                buy used xiaomi laptop
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Tablets"
                onClick={() => handleNavigateTo("/buying-main-page/Tablets")}
                className="custom-link"
              >
                buy used apple ipads
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Tablets"
                onClick={() => handleNavigateTo("/buying-main-page/Tablets")}
                className="custom-link"
              >
                buy used Samsung tablets
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Tablets"
                onClick={() => handleNavigateTo("/buying-main-page/Tablets")}
                className="custom-link"
              >
                buy used mi pads
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Tablets"
                onClick={() => handleNavigateTo("/buying-main-page/Tablets")}
                className="custom-link"
              >
                buy used oneplus tabs
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Laptops"
                onClick={() => handleNavigateTo("/buying-main-page/Laptops")}
                className="custom-link"
              >
                buy used apple smartwatch
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Laptops"
                onClick={() => handleNavigateTo("/buying-main-page/Laptops")}
                className="custom-link"
              >
                buy used Samsung smartwatch
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Laptops"
                onClick={() => handleNavigateTo("/buying-main-page/Laptops")}
                className="custom-link"
              >
                buy used iwatch online
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Laptops"
                onClick={() => handleNavigateTo("/buying-main-page/Laptops")}
                className="custom-link"
              >
                buy used electronic gadgets
              </Link>{" "}
              |
              <Link
                to="/buying-main-page/Laptops"
                onClick={() => handleNavigateTo("/buying-main-page/Laptops")}
                className="custom-link"
              >
                buy used macmini
              </Link>{" "}
              |
              <Link
                to="/buying-products/Desktop-Imac/Superb"
                onClick={() =>
                  handleNavigateTo("/buying-products/Desktop-Imac/Superb")
                }
                className="custom-link"
              >
                buy used mac studio
              </Link>{" "}
              |
              <Link
                to="/buying-products/Other%20Accessories/Unboxed"
                onClick={() =>
                  handleNavigateTo(
                    "/buying-products/Other%20Accessories/Unboxed"
                  )
                }
                className="custom-link"
              >
                buy used camera
              </Link>
            </div>
          </Col>
        </Row>
        <h6
          className="custom-link"
          style={{ textAlign: "center", marginTop: "30px", fontSize: "13px" }}
        >
          © Copyright 2023 Hellofi Recommerce
        </h6>
      </Container>
      {showCategory ? (
        <CategoryModal
          showCategory={showCategory}
          setShowCategory={setShowCategory}
        />
      ) : (
        ""
      )}
      {showCategory2 ? (
        <BuyCategoryModal
          showCategory2={showCategory2}
          setShowCategory2={setShowCategory2}
        />
      ) : (
        ""
      )}
    </>
  );
}
