import { useEffect, useState } from "react";
import Footer from "../../Footer"
import Navigation from "../../Navigation"
import { useMutation, useQuery } from '@apollo/client';
import { Container, Row, Col, Image, Form, Button, Card, Breadcrumb, Spinner, Modal } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa'
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { QUERY_GET_LAPTOP_QUESTIONS } from '../../../graphql/Query'
import { QUERY_GET_PRODUCT_BY_ID } from '../../../graphql/Query'
import '../../../ComponentsCss/sellSection/sellPricePage.css'

import { MUTATION_SEND_USEROTP, MUTATION_USER_OTP_LOGIN } from '../../../graphql/Mutations'
import swal from 'sweetalert';
import { QUERY_GET_ALL_USER } from '../../../graphql/Query'
import imglog from '../../../Images/5856-removebg-preview.png'
import Formfile from '../../../Documents/IndemnityBond.pdf'
import Userlogin from "../../User/Userlogin";

const LaptopPricePage = () => {
    const userId = localStorage.getItem('userId')
    const [basePrice, setBasePrice] = useState(0);
    const [productSummary, setProductSummary] = useState([]);
    const location = useLocation();
    const navigate = useNavigate()
    const { data: getLaptopQuestios, loading: questionLoading } = useQuery(QUERY_GET_LAPTOP_QUESTIONS);
    const { data: getProductById, loading: productLoading } = useQuery(QUERY_GET_PRODUCT_BY_ID,
        {
            variables:
            {
                "id": location.state.id

            }
        });
    console.table("all question 12", getLaptopQuestios?.getLaptopQuestios[0]);
    console.table("all selection 11", location.state);
    const handleDownload = () => {
        // Create a new link element
        const link = document.createElement('a');

        // Set the URL of the file you want to download
        link.href = Formfile;

        // Use download attribute to set the file name
        link.download = 'Indemnity Bond.pdf';

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Remove the link from the body
        document.body.removeChild(link);
    }
    function calculatePriceDeduction(price, percentage) {
        percentage = parseFloat(percentage);
        const decimalPercentage = percentage / 100;
        const deduction = price * decimalPercentage;
        const finalPrice = price - deduction;
        return finalPrice;
    }
    function calculatePriceIncrement(price, percentage) {
        percentage = parseFloat(percentage);
        const decimalPercentage = percentage / 100;
        const deduction = price * decimalPercentage;
        const finalPrice = price + deduction;

        return finalPrice;
    }

    useEffect(() => {
        window.scrollTo(0, 0)

        setProductSummary([])
        if (getProductById?.getProductById[0] && getLaptopQuestios?.getLaptopQuestios[0]) {
            let price = getProductById?.getProductById[0].productPrice;
            const selection = location.state;
            const questionPer = getLaptopQuestios?.getLaptopQuestios[0];

            if (selection.keyBoardState) {
                price = calculatePriceDeduction(price, questionPer?.KeyboardFaultyPer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Keyboard Faulty"]);
            }
            if (selection.faceSensorIssueState) {
                price = calculatePriceDeduction(price, questionPer?.FaceSensorIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Face Sensor"]);
            }
            if (selection.fingerPrintIssueState) {
                price = calculatePriceDeduction(price, questionPer?.FingerPrintIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Finger Print"]);
            }
            if (selection.isDeviceSwitch == "no") {
                price = calculatePriceDeduction(price, questionPer?.isDeviceSwitchPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }

            if (selection.issueWithDeviceDisplay == "yes") {
                price = calculatePriceDeduction(price, questionPer?.issueWithDeviceDisplayPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.deviceScreen == "original") {
                price = calculatePriceDeduction(price, questionPer?.deviceScreenOriginPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.deviceScreen == "change1") {
                price = calculatePriceDeduction(price, questionPer?.deviceScreenChangesPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.deviceScreen == "change2") {
                price = calculatePriceDeduction(price, questionPer?.deviceScreenOriginalChangedPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.deviceEverRepair == "yes1") {
                price = calculatePriceDeduction(price, questionPer?.deviceRepairAuthCenterPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.deviceEverRepair == "yes2") {
                price = calculatePriceDeduction(price, questionPer?.deviceRepairUnAuthCenterPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.deviceEverRepair == "no") {
                price = calculatePriceDeduction(price, questionPer?.deviceRepairNoPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.ConditionDeviceScreen == "minor") {
                price = calculatePriceDeduction(price, questionPer?.deviceConditionMinorPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.ConditionDeviceScreen == "major") {
                price = calculatePriceDeduction(price, questionPer?.deviceConditionMajorPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.ConditionDeviceScreen == "no") {
                price = calculatePriceDeduction(price, questionPer?.deviceConditionNoScratchPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.ConditionDeviceBody == "excellent") {
                price = calculatePriceDeduction(price, questionPer?.deviceBodyConditionExcellentPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.ConditionDeviceBody == "good") {
                price = calculatePriceDeduction(price, questionPer?.deviceBodyConditionGoodPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.ConditionDeviceBody == "average") {
                price = calculatePriceDeduction(price, questionPer?.deviceBodyConditionAveragePer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.ConditionDeviceBody == "poor") {
                price = calculatePriceDeduction(price, questionPer?.deviceBodyConditionPoorPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }

            if (selection.AutoRestartIssue) {

                price = calculatePriceDeduction(price, questionPer?.AutoRestartIssuePer);

                setProductSummary(prevProductSummary => [...prevProductSummary, "Auto Restart"]);
            }
            if (selection.BatteryIssue) {
                price = calculatePriceDeduction(price, questionPer?.BatteryIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Battery"]);

            }
            if (selection.BluetoothIssue) {
                price = calculatePriceDeduction(price, questionPer?.BluetoothIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Bluetooth"]);

            }
            if (selection.trackpadState) {
                price = calculatePriceDeduction(price, questionPer?.TrackpadFaultyPer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Trackpad"]);

            }
            if (selection.CharginPortIssue) {
                price = calculatePriceDeduction(price, questionPer?.CharginPortIssuePer);
                setProductSummary(prevProductSummary => [...prevProductSummary, "Charging Port"]);


            }
            if (selection.EarphonePortIssue) {
                price = calculatePriceDeduction(price, questionPer?.EarphonePortIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Earphone Port"]);


            }
            if (selection.webCamState) {
                price = calculatePriceDeduction(price, questionPer?.WebcamFaulty)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Webcam Faulty"]);


            }
            if (selection.usbPortState) {
                price = calculatePriceDeduction(price, questionPer?.UsbPortIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Usb Port"]);
            }
            if (selection.hingeLooseState) {
                price = calculatePriceDeduction(price, questionPer?.HingeLoosePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Hinge Loose"]);
            }
            if (selection.MicIssue) {
                price = calculatePriceDeduction(price, questionPer?.MicIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Mic"]);


            }
            if (selection.chargerFaultyState) {
                price = calculatePriceDeduction(price, questionPer?.ChargerFaultyPer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Charger"]);
            }
            if (selection.hardDriveState) {
                price = calculatePriceDeduction(price, questionPer?.HardDriveFaultyPer
                );
                setProductSummary(prevProductSummary => [...prevProductSummary, "Hard Drive"]);

            }
            if (selection.ramIssueState) {
                price = calculatePriceDeduction(price, questionPer?.RamIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Ram Issue"]);


            }
            if (selection.SpeakerIssue) {
                price = calculatePriceDeduction(price, questionPer?.SpeakerIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Speaker"]);


            }
            if (selection.graphicsCardState) {
                price = calculatePriceDeduction(price, questionPer?.GraphicsCardIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Graphics card"]);

            }
            if (selection.WifiIssue) {
                price = calculatePriceDeduction(price, questionPer?.WifiIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Wiffi "]);

            }
            // calculating warranty Period
            if (selection.billClick) {
                if (selection.WarrantyPeriod == "1") {
                    price = calculatePriceDeduction(price, questionPer?.billBelowOneMonthPer)

                } else if (selection.WarrantyPeriod === "3") {

                    price = calculatePriceDeduction(price, questionPer?.billOneToThreeMonthPer)

                }
                else if (selection.WarrantyPeriod == "6") {
                    price = calculatePriceDeduction(price, questionPer?.billThreeToSixMonthPer)

                }
                else if (selection.WarrantyPeriod == "9") {
                    price = calculatePriceDeduction(price, questionPer?.billSixToNineMonthPer)

                }
                else if (selection.WarrantyPeriod == "12") {
                    price = calculatePriceDeduction(price, questionPer?.billNineToTwelveMonthPer)

                } else if (selection.WarrantyPeriod == "default") {
                    price = calculatePriceDeduction(price, questionPer?.billAboveOneYear)

                }

            } else {
                price = calculatePriceDeduction(price, questionPer?.HaveBillPer)

            }
            // calculating original accessories available
            if (selection.HaveAdapter == false) {
                price = calculatePriceDeduction(price, questionPer?.HaveAdapterPer)

            }
            if (selection.HaveBoxWithIMEI == false) {
                price = calculatePriceDeduction(price, questionPer?.HaveBoxWithIMEIPer)

            }
            if (selection.powerCableClick == false) {
                price = calculatePriceDeduction(price, questionPer?.HavePowerCablePer)

            }

            // calculating purchased place
            if (selection.PurchasedPlace == "India") {
                price = calculatePriceDeduction(price, questionPer?.purchasePlaceIndiaPer)

            }
            if (selection.PurchasedPlace == "Abroad") {
                price = calculatePriceDeduction(price, questionPer?.purchasePlaceAbroadPer)

            }
            // calculating Additional Insurance
            if (selection.additionalClick == "Apple Care+") {
                price = calculatePriceIncrement(price, questionPer?.addInsAppleCarePer)

            }
            if (selection.additionalClick == "Samsung Care+") {
                price = calculatePriceIncrement(price, questionPer?.addInsSamsungCarePer)

            }
            if (selection.additionalClick == "3rd Party") {
                price = calculatePriceIncrement(price, questionPer?.addInsThirdPartyPer)

            }
            if (selection.additionalClick == "Not applicable") {
                price = calculatePriceIncrement(price, questionPer?.addInsNotApplicablePer)

            }
            // Calculating Screen Size  

            if (selection.screenSizeState == "10-11 Inch") {
                price = calculatePriceDeduction(price, questionPer?.ScreenSize10To11Per)

            }
            if (selection.screenSizeState == "12-13 Inch") {
                price = calculatePriceDeduction(price, questionPer?.ScreenSize12To13Per)

            }
            if (selection.screenSizeState == "13-14 Inch") {
                price = calculatePriceDeduction(price, questionPer?.ScreenSize13To14Per)

            }
            if (selection.screenSizeState == "14-15 Inch") {
                price = calculatePriceDeduction(price, questionPer?.ScreenSize14To15Per)

            }
            if (selection.screenSizeState == "Above 15 Inch") {
                price = calculatePriceDeduction(price, questionPer?.ScreenSizeAbove15Per)

            }

            const ramPriceMap = {
                "2 GB": questionPer?.ram2gb,
                "3 GB": questionPer?.ram3gb,
                "4 GB": questionPer?.ram4gb,
                "6 GB": questionPer?.ram6gb,
                "8 GB": questionPer?.ram8gb,
                "12 GB": questionPer?.ram12gb,
                "16 GB": questionPer?.ram16gb,
                "32 GB": questionPer?.ram32gb,
                "18 GB": questionPer?.ram18gb,
                "24 GB": questionPer?.ram24gb,
                "64 GB": questionPer?.ram64gb,
                "128 GB": questionPer?.ram128gb,
            };

            // Map for Storage options
            const storagePriceMap = {
                "250 GB HDD": questionPer?.storage250GbHDD,
                "320 GB HDD": questionPer?.storage320GbHDD,
                "500 GB HDD": questionPer?.storage500GbHDD,
                "640 GB HDD": questionPer?.storage640GbHDD,
                "750 GB HDD": questionPer?.storage750GbHDD,
                "1 TB HDD": questionPer?.storage1TbHDD,
                "1.5 TB HDD": questionPer?.storage1P5TbHDD,
                "128 GB SSD": questionPer?.storage128SDD,
                "256 GB SSD": questionPer?.storage256SDD,
                "2 TB HDD": questionPer?.storage2TbHDD,
                "500 GB HDD + 128 GB SSD": questionPer?.storage500Plus128SDD,
                "1 TB HDD + 128 GB SSD": questionPer?.storage1TbPlus128SDD,
                "512 GB SSD": questionPer?.storage512SSD,
                "1 TB HDD + 256 GB SSD": questionPer?.storage1TbHDDPlus256Ssd,
                "1 TB HDD + 512 GB SSD": questionPer?.storage1TbHDDPlus512Ssd,
                "1 TB SSD": questionPer?.storage1Tbssd,
                "2 TB SSD": questionPer?.storage2Tbssd,
                "3 TB SSD": questionPer?.storage3Tbssd,
                "4 TB SSD": questionPer?.storage4Tbssd,
                "2 TB": questionPer?.storage2tb,
                "1TB HDD + 128GB SSD": questionPer?.storage1tbHDDPlus128GBSSD,
                "2TB HDD + 128GB SSD": questionPer?.storage2tbHDDPlus128GBSSD,
                "2TB HDD + 256GB SSD": questionPer?.storage2tbHDDPlus256GBSSD,
                "2TB HDD + 512GB SSD": questionPer?.storage2tbHDDPlus512GBSSD,
            };

            // Calculate RAM price
            const selectedRamOption = selection.laptopRam;
            const ramPrice = ramPriceMap[selectedRamOption];

            if (ramPrice !== undefined) {
                price -= parseInt(ramPrice, 10) || 0;
            } 
            // Calculate Storage price
            const selectedStorageOption = selection.laptopStorage;
            const storagePrice = storagePriceMap[selectedStorageOption];

            if (storagePrice !== undefined) {
                price -= parseInt(storagePrice, 10) || 0;
            } 


            // graphics Card Calculation

            if (selection.graphicsState == "Yes") {
                price += parseInt(questionPer?.GraphicsCardYesPer)

            }
            if (selection.graphicsState == "No") {
                price += parseInt(questionPer?.GraphicsCardNoPer)
            }



            const processorPriceMap = {
                "Intel Core i3 3rd Gen": questionPer?.intelCoreI33Gen,
                "Intel Core i3 4th Gen": questionPer?.intelCoreI34Gen,
                "Intel Core i3 5th Gen": questionPer?.intelCoreI35Gen,
                "Intel Core i3 6th Gen": questionPer?.intelCoreI36Gen,
                "Intel Core i3 7th Gen": questionPer?.intelCoreI37Gen,
                "Intel Core i3 8th Gen": questionPer?.intelCoreI38Gen,
                "Intel Core i3 9th Gen": questionPer?.intelCoreI39Gen,
                "Intel Core i3 10th Gen": questionPer?.intelCoreI310Gen,
                "Intel Core i3 11th Gen": questionPer?.intelCoreI311Gen,
                "Intel Core i3 12th Gen": questionPer?.intelCoreI312Gen,
                "Intel Core i5 3rd Gen": questionPer?.intelCoreI53Gen,
                "Intel Core i5 4th Gen": questionPer?.intelCoreI54Gen,
                "Intel Core i5 5th Gen": questionPer?.intelCoreI55Gen,
                "Intel Core i5 6th Gen": questionPer?.intelCoreI56Gen,
                "Intel Core i5 7th Gen": questionPer?.intelCoreI57Gen,
                "Intel Core i5 8th Gen": questionPer?.intelCoreI58Gen,
                "Intel Core i5 9th Gen": questionPer?.intelCoreI59Gen,
                "Intel Core i5 10th Gen": questionPer?.intelCoreI510Gen,
                "Intel Core i5 11th Gen": questionPer?.intelCoreI511Gen,
                "Intel Core i5 12th Gen": questionPer?.intelCoreI512Gen,
                "Intel Core i7 3rd Gen": questionPer?.intelCoreI73Gen,
                "Intel Core i7 4th Gen": questionPer?.intelCoreI74Gen,
                "Intel Core i7 5th Gen": questionPer?.intelCoreI75Gen,
                "Intel Core i7 6th Gen": questionPer?.intelCoreI76Gen,
                "Intel Core i7 7th Gen": questionPer?.intelCoreI77Gen,
                "Intel Core i7 8th Gen": questionPer?.intelCoreI78Gen,
                "Intel Core i7 9th Gen": questionPer?.intelCoreI79Gen,
                "Intel Core i7 10th Gen": questionPer?.intelCoreI710Gen,
                "Intel Core i7 11th Gen": questionPer?.intelCoreI711Gen,
                "Intel Core i9 8th Gen": questionPer?.intelCoreI98Gen,
                "Intel Core i9 9th Gen": questionPer?.intelCoreI99Gen,
                "Intel Core i9 10th Gen": questionPer?.intelCoreI910Gen,
                "Intel Core i9 11th Gen": questionPer?.intelCoreI911Gen,
                "Apple M1 Chip": questionPer?.appleM1Chip,
                "Apple M1 Max Chip": questionPer?.appleM1MaxChip,
                "Apple M1 Pro Chip": questionPer?.appleM1ProChip,
                "Apple M1 Plus Chip": questionPer?.appleM1PlusChip,
                "Apple M2 Chip": questionPer?.appleM2Chip,
                "Apple M2 Max Chip": questionPer?.appleM2MaxChip,
                "Apple M2 Pro Chip": questionPer?.appleM2ProChip,
                "Apple M2 Plus Chip": questionPer?.appleM2PlusChip,
                "AMD A9": questionPer?.amdA9,
                "AMD A10": questionPer?.amdA10,
                "AMD A4": questionPer?.amdA4,
                "AMD A6": questionPer?.amdA6,
                "AMD A8": questionPer?.amdA8,
                "AMD Brazos Dual Core C-70": questionPer?.amdBrazosDualCoreC70,
                "AMD Dual Core A6": questionPer?.amdDualCoreA6,
                "AMD Dual Core A9 7th Gen": questionPer?.amdDualCoreA97gen,
                "AMD Dual Core E1": questionPer?.amdDualCoreE1,
                "AMD Dual Core E2": questionPer?.amdDualCoreE2,
                "AMD E Series": questionPer?.amdSeries,
                "AMD Dual E 1": questionPer?.amdE1,
                "AMD Quad Core": questionPer?.amdQuadCore,
                "AMD Quad Core A10": questionPer?.amdQuadCoreA10,
                "AMD Quad Core A15": questionPer?.amdQuadCoreA15,
                "AMD Quad Core A4": questionPer?.amdQuadCoreA4,
                "AMD Quad Core A6": questionPer?.amdQuadCoreA6,
                "AMD Quad Core A8": questionPer?.amdQuadCoreA8,
                "AMD Quad Core E2 7th Gen": questionPer?.amdQuadCoreE27thGen,
                "AMD Quad Core E2 6th Gen": questionPer?.amdQuadCoreE26thGen,
                "AMD Ryzen 3": questionPer?.amdRyzen3,
                "AMD Ryzen 5": questionPer?.amdRyzen5,
                "AMD Ryzen 5 Pro": questionPer?.amdRyzen5Pro,
                "AMD Ryzen 7": questionPer?.amdRyzen7,
                "AMD Ryzen 7 Pro": questionPer?.amdRyzen7Pro,
                "AMD Ryzen 9": questionPer?.amdRyzen9,
                "AMD Ryzen Threadripper": questionPer?.amdRyzenThreadDripper,
                "Intel Celeron Dual Core 4th Gen": questionPer?.intelCeleronDualCore4gen,
                "Intel Celeron Dual Core 3th Gen": questionPer?.intelCeleronDualCore3gen,
                "Intel Celeron Dual Core 5th Gen": questionPer?.intelCeleronDualCore5gen,
                "Intel Celeron Quad Core 4th Gen": questionPer?.intelCeleronQuadCore4gen,
                "Intel Core M3 6th Gen": questionPer?.intelCoreM36gen,
                "Intel Core M3 7th Gen": questionPer?.intelCoreM37gen,
                "Intel Core M3 8th Gen": questionPer?.intelCoreM38gen,
                "Intel Core M5 5th Gen": questionPer?.intelCoreM55gen,
                "Intel Core M5 6th Gen": questionPer?.intelCoreM56gen,
                "Intel Core M7 6th Gen": questionPer?.intelCoreM76gen,
                "Intel Pentium Dual Core 3rd Gen": questionPer?.intelPentiumDualCore3Gen,
                "Intel Pentium Dual Core 4th Gen": questionPer?.intelPentiumDualCore4Gen,
                "Intel Pentium Octa Core": questionPer?.intelPentiumOctaCore,
                "Intel Pentium Quad Core": questionPer?.intelPentiumQuadCore,
                "Intel Pentium Quad Core 3rd Gen": questionPer?.intelPentiumQuadCore3rdGen,
                "Intel Pentium Quad Core 4rd Gen": questionPer?.intelPentiumQuadCore4Gen,
                "Intel Pentium Quad Core 6rd Gen": questionPer?.intelPentiumQuadCore6Gen,
                "Intel Xeon": questionPer?.intelXeon,
                "Microsoft SQ1": questionPer?.microSoftSq1,
                "Microsoft SQ2": questionPer?.microSoftSq2,
                "Quad Core": questionPer?.quadCore,

                // Intel Core Processors
                "Intel Core i3 13th Gen": questionPer?.intelCoreI313Gen,
                "Intel Core i5 13th Gen": questionPer?.intelCoreI513Gen,
                "Intel Core i7 13th Gen": questionPer?.intelCoreI713Gen,
                "Intel Core i9 13th Gen": questionPer?.intelCoreI913Gen,
                "Intel Core i7 12th Gen": questionPer?.intelCoreI712Gen,
                "Intel Core i9 12th Gen": questionPer?.intelCoreI912Gen,

                // AMD Ryzen Processors
                "AMD Ryzen 3 2nd Gen": questionPer?.amdRyzen32Gen,
                "AMD Ryzen 3 3rd Gen": questionPer?.amdRyzen33Gen,
                "AMD Ryzen 3 4th Gen": questionPer?.amdRyzen34Gen,
                "AMD Ryzen 3 5th Gen": questionPer?.amdRyzen35Gen,
                "AMD Ryzen 3 6th Gen": questionPer?.amdRyzen36Gen,
                "AMD Ryzen 3 7th Gen": questionPer?.amdRyzen37Gen,
                "AMD Ryzen 5 2nd Gen": questionPer?.amdRyzen52Gen,
                "AMD Ryzen 5 3rd Gen": questionPer?.amdRyzen53Gen,
                "AMD Ryzen 5 4th Gen": questionPer?.amdRyzen54Gen,
                "AMD Ryzen 5 5th Gen": questionPer?.amdRyzen55Gen,
                "AMD Ryzen 5 6th Gen": questionPer?.amdRyzen56Gen,
                "AMD Ryzen 5 7th Gen": questionPer?.amdRyzen57Gen,
                "AMD Ryzen 7 2nd Gen": questionPer?.amdRyzen72Gen,
                "AMD Ryzen 7 3rd Gen": questionPer?.amdRyzen73Gen,
                "AMD Ryzen 7 4th Gen": questionPer?.amdRyzen74Gen,
                "AMD Ryzen 7 5th Gen": questionPer?.amdRyzen75Gen,
                "AMD Ryzen 7 6th Gen": questionPer?.amdRyzen76Gen,
                "AMD Ryzen 7 7th Gen": questionPer?.amdRyzen77Gen,
                "AMD Ryzen 9 2nd Gen": questionPer?.amdRyzen92Gen,
                "AMD Ryzen 9 3rd Gen": questionPer?.amdRyzen93Gen,
                "AMD Ryzen 9 4th Gen": questionPer?.amdRyzen94Gen,
                "AMD Ryzen 9 5th Gen": questionPer?.amdRyzen95Gen,
                "AMD Ryzen 9 6th Gen": questionPer?.amdRyzen96Gen,
                "AMD Ryzen 9 7th Gen": questionPer?.amdRyzen97Gen,

                // AMD Ryzen Pro Processors
                "AMD Ryzen 3 Pro": questionPer?.amdRyzen3Pro,
                "AMD Ryzen 3 Pro 2nd Gen": questionPer?.amdRyzen3Pro2Gen,
                "AMD Ryzen 3 Pro 3rd Gen": questionPer?.amdRyzen3Pro3Gen,
                "AMD Ryzen 3 Pro 4th Gen": questionPer?.amdRyzen3Pro4Gen,
                "AMD Ryzen 3 Pro 5th Gen": questionPer?.amdRyzen3Pro5Gen,
                "AMD Ryzen 3 Pro 6th Gen": questionPer?.amdRyzen3Pro6Gen,
                "AMD Ryzen 3 Pro 7th Gen": questionPer?.amdRyzen3Pro7Gen,
                "AMD Ryzen 5 Pro 2nd Gen": questionPer?.amdRyzen5Pro2Gen,
                "AMD Ryzen 5 Pro 3rd Gen": questionPer?.amdRyzen5Pro3Gen,
                "AMD Ryzen 5 Pro 4th Gen": questionPer?.amdRyzen5Pro4Gen,
                "AMD Ryzen 5 Pro 5th Gen": questionPer?.amdRyzen5Pro5Gen,
                "AMD Ryzen 5 Pro 6th Gen": questionPer?.amdRyzen5Pro6Gen,
                "AMD Ryzen 5 Pro 7th Gen": questionPer?.amdRyzen5Pro7Gen,
                "AMD Ryzen 7 Pro 2nd Gen": questionPer?.amdRyzen7Pro2Gen,
                "AMD Ryzen 7 Pro 3rd Gen": questionPer?.amdRyzen7Pro3Gen,
                "AMD Ryzen 7 Pro 4th Gen": questionPer?.amdRyzen7Pro4Gen,
                "AMD Ryzen 7 Pro 5th Gen": questionPer?.amdRyzen7Pro5Gen,
                "AMD Ryzen 7 Pro 6th Gen": questionPer?.amdRyzen7Pro6Gen,
                "AMD Ryzen 7 Pro 7th Gen": questionPer?.amdRyzen7Pro7Gen,

                // Apple M-series Processors
                "Apple M3 Chip": questionPer?.appleM3Chip,
                "Apple M3 Pro Chip": questionPer?.appleM3ProChip,
                "Apple M3 Max Chip": questionPer?.appleM3MaxChip,
                "Apple M1 Ultra Chip": questionPer?.appleM1UltraChip,
                "Apple M2 Ultra Chip": questionPer?.appleM2UltraChip,

            };

            const selectedProcessorState = selection.processorState;
            const processorPrice = processorPriceMap[selectedProcessorState];

            if (processorPrice !== undefined) {
                price -= parseInt(processorPrice, 10) || 0;
            } 

            if (price != null) {
                price = parseFloat(price);
                if (!isNaN(price)) {
                    price = Math.floor(price);
                    setBasePrice(price);
                }
            }
        }

    }, [getLaptopQuestios?.getLaptopQuestios[0], getProductById?.getProductById[0], location.state])

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const handleSubmit = () => {
        if (!userId) {
            handleClose()
            handleShow2()
        } else {
            navigate("/addaddress", {
                state: {
                    id: location.state.id, isDeviceSwitch: location.state.isDeviceSwitch, issueWithDeviceDisplay: location.state.issueWithDeviceDisplay, deviceScreen: location.state.deviceScreen, deviceEverRepair: location.state.deviceEverRepair,
                    productCategory: location.state.productCategory, productBrand: location.state.productBrand,
                    productName: location.state.productName, imgurl: location.state.imgurl, processorState: location.state.processorState, laptopStorage: location.state.laptopStorage, laptopRam: location.state.laptopRam, graphicsState: location.state.graphicsState, screenSizeState: location.state.screenSizeState, ConditionDeviceScreen: location.state.ConditionDeviceBody, ConditionDeviceBody: location.state.ConditionDeviceBody, boxClick: location.state.boxClick, powerCableClick: location.state.powerCableClick, adapterClick: location.state.adapterClick, purchasedPlaceClick: location.state.PurchasedPlace, additionalClick: location.state.additionalClick, warrantyPeriod: location.state.WarrantyPeriod, defects: productSummary, basePrice: basePrice, HaveBill: location.state.billClick, financeClick: location.state.financeClick, HaveBoxWithIMEI: location.state.HaveBoxWithIMEI, HaveAdapter: location.state.HaveAdapter, HavePowerCable: location.state.HavePowerCable,
                }
            })
        }
    }
    //login Functionality

    const userToken = localStorage.getItem('userToken')

    const { data: userData } = useQuery(QUERY_GET_ALL_USER)

    const [validated, setValidated] = useState(false);
    const [validated2, setValidated2] = useState(false);
    const [contact, setContact] = useState('')
    const [otpSent, setOtpSent] = useState(false)
    const [gOtp, setGeneratedOtp] = useState('')
    const [enteredOtp, setEnteredOtp] = useState('')

    const [userLoginDone, setUserLoginDone] = useState(false)
    const [sendOtp] = useMutation(MUTATION_SEND_USEROTP)
    const [userOtpLogin, { data: logindata, error }] = useMutation(MUTATION_USER_OTP_LOGIN)

    // const { data: user_data } = useQuery(GET_USERDATA_BY_USERID,{
    //   variables:{
    //     "userId": `${userId}`
    //   }
    // })

    const [otpSendClick, setOtpSendClick] = useState(false)
    const [otpState, setOtpState] = useState('')
    const [otpError, setOtpError] = useState(false)
    const [otpResend, setOtpResend] = useState(false)


    function generateUserOtp(event) {
        if (contact === '' || contact.length < 10 || contact.length > 10) {
            setValidated(true)
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            const gOtp = Math.floor(1000 + Math.random() * 9000);
            setValidated(false)
            localStorage.removeItem("userOtp");
            localStorage.setItem('userOtp', gOtp);
            sendOtp({
                variables: {
                    "contact": `${contact}`,
                    "otp": `${gOtp}`
                }
            })
            swal({
                title: "OTP Send",
                text: "OTP Send on your mobile no.",
                icon: "success",
            })
            setGeneratedOtp(gOtp)
            setOtpSent(true)
        }
    }

    function handleClick(event) {
        const otp = localStorage.getItem('userOtp');
        if (otp === otpState) {
            setValidated2(true);
            event.preventDefault();
            event.stopPropagation();
            userOtpLogin({
                variables: {
                    "contact": `${contact}`,
                    "otp": `${otp}`
                }
            })

        } else {
            event.preventDefault();
            setValidated2(false);
            alert("OTP Not Match !!!")
        }
    }


    function contactEnterAgain() {
        localStorage.removeItem("userOtp")
        setOtpError(false)
        setContact('')
        setOtpState('')
        setOtpSendClick(false)
    }

    function otpSendAgain() {
        setOtpResend(true)
        setOtpError(false)
        localStorage.removeItem("userOtp")
        setOtpState('')
        generateUserOtp()
    }


    if (logindata) {
        localStorage.setItem('userId', logindata.userOtpLogin.userId)
        // setUserLoginDone(true)
        localStorage.setItem('userToken', logindata.userOtpLogin.userToken)
        localStorage.setItem('userTokenExpiration', logindata.userOtpLogin.userTokenExpiration)
        localStorage.removeItem("userOtp")
        if (userToken) {
            navigate("/addaddress", {
                state: {
                    id: location.state.id, isDeviceSwitch: location.state.isDeviceSwitch, issueWithDeviceDisplay: location.state.issueWithDeviceDisplay, deviceScreen: location.state.deviceScreen, deviceEverRepair: location.state.deviceEverRepair,
                    keyBoardState: location.state.keyBoardState, trackpadState: location.state.trackpadState, SpeakerIssue: location.state.SpeakerIssue, MicIssue: location.state.MicIssue, usbPortState: location.state.usbPortState, CharginPortIssue: location.state.CharginPortIssue, webCamState: location.state.webCamState, BatteryIssue: location.state.BatteryIssue, hingeLooseState: location.state.hingeLooseState, WifiIssue: location.state.WifiIssue, BluetoothIssue: location.state.BluetoothIssue, chargerFaultyState: location.state.chargerFaultyState, AutoRestartIssue: location.state.AutoRestartIssue, EarphonePortIssue: location.state.EarphonePortIssue, graphicsCardState: location.state.graphicsCardState, hardDriveState: location.state.hardDriveState, ramIssueState: location.state.ramIssueState, productCategory: location.state.productCategory, productBrand: location.state.productBrand,
                    productName: location.state.productName, imgurl: location.state.imgurl, processorState: location.state.processorState, laptopStorage: location.state.laptopStorage, laptopRam: location.state.laptopRam, graphicsState: location.state.graphicsState, screenSizeState: location.state.screenSizeState, ConditionDeviceScreen: location.state.ConditionDeviceBody, ConditionDeviceBody: location.state.ConditionDeviceBody, boxClick: location.state.boxClick, powerCableClick: location.state.powerCableClick, adapterClick: location.state.adapterClick, purchasedPlaceClick: location.state.PurchasedPlace, additionalClick: location.state.additionalClick, warrantyPeriod: location.state.WarrantyPeriod, defects: productSummary, HaveBill: location.state.billClick, financeClick: location.state.financeClick, HaveBoxWithIMEI: location.state.HaveBoxWithIMEI, HaveAdapter: location.state.HaveAdapter, HavePowerCable: location.state.HavePowerCable,
                }
            })
        }
    }

    let warrantyPeriod = location.state.WarrantyPeriod;

    const handleSelectBrand = () => {
    navigate(`/selling/${location.state?.productCategory}`, )
    }
    const handleSelectProduct = () => {
        navigate(`/allbrands/${location.state?.productBrand}`, {
            state: {
                productCategory: location.state?.productCategory, productBrand: location.state?.productBrand,
            }
        })
    }
    return (
        <>

            <Navigation />
            <div className="banner">
                <h2>
                    Summary of Your Old {location.state.productCategory}
                </h2>
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="search-input"
                    />
                    {/* <div className="search-icon">
                        <FaSearch />
                    </div> */}
                </div>
                <Breadcrumb>
                    <Breadcrumb.Item ><Link to="/">Home</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>
                    <a  onClick={handleSelectBrand}>  Select Brand</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item onClick={handleSelectProduct}>Sell Product</Breadcrumb.Item>
                </Breadcrumb>

            </div>
            {questionLoading || productLoading ? <Spinner animation="border" variant="success" className='mx-auto d-block' />
                : <Container style={{ marginTop: "1rem" }}>
                    <Row>
                        <Col md={6}>
                            <Card >
                                <Card.Body >
                                    <Row>
                                        <Image fluid style={{ width: "12rem" }} src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${location.state?.imgurl}`} className="mx-auto d-block" />
                                    </Row>
                                    <Row>

                                        <Col style={{ marginLeft: "2%", fontSize: "12px" }} >

                                            <div style={{ fontWeight: "bold", marginTop: "1rem" }}> Storage : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.laptopStorage} </span> </div>
                                            <div style={{ fontWeight: "bold" }}> Ram : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.laptopRam} </span> </div>
                                            <div style={{ fontWeight: "bold" }}> Processor : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.processorState} </span> </div>
                                            <div style={{ fontWeight: "bold" }}> Graphics Card : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.graphicsState} </span> </div>
                                            <div style={{ fontWeight: "bold" }}> Scree Size : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.screenSizeState} </span> </div>

                                            <div style={{ fontWeight: "bold", marginTop: "1rem" }} > 1. Is your device switching on? : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.isDeviceSwitch == "yes" ? "Yes" : "No"} </span> </div>
                                            <div style={{ fontWeight: "bold" }}> 2. Any issue with your device display : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.issueWithDeviceDisplay == "yes" ? "Yes" : "No"} </span> </div>
                                            <div style={{ fontWeight: "bold" }}> 3. Tell us about your gadget’s screen? :  <span style={{ color: "rgb(64, 167, 55)" }}> {location.state.deviceScreen === "original" ? "Original" : location.state.deviceScreen === "change1" ? "Changed(Compatible)" : "Changed(Original Bill)"}</span> </div>
                                            <div style={{ fontWeight: "bold" }}> 4. Is your device ever repaired? : <span style={{ color: "rgb(64, 167, 55)" }}> {location.state.deviceEverRepair === "yes1" ? "Yes (Authorised Service Center)" : location.state.deviceEverRepair === "yes2" ? "Yes (Unauthorised Service Center)" : "No"}</span> </div>
                                            <div style={{ fontWeight: "bold", marginTop: "1rem", marginBottom: "1rem" }}  >  Appropriate defects of your device </div>
                                            {productSummary &&
                                                productSummary.map((item, index) => {
                                                    return (
                                                        <span style={{ color: "rgb(64, 167, 55)", fontWeight: "bold", marginLeft: "1rem" }}>{item} </span>
                                                    )
                                                })
                                            }
                                            <div style={{ fontWeight: "bold", marginTop: "1rem", marginBottom: "1rem" }}  > Physical condition of your device </div>
                                            <div style={{ fontWeight: "bold" }}>  Condition of your device screen : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.ConditionDeviceScreen === "minor" ? "Minor Scratches" : location.state.ConditionDeviceScreen === "major" ? "Major Scratches" : "No Scratches"} </span></div>
                                            <div style={{ fontWeight: "bold" }}>  Condition of your device Body : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.ConditionDeviceBody === "excellent" ? "Excellent Condition" : location.state.ConditionDeviceScreen === "good" ? "Good Condition" : location.state.ConditionDeviceScreen === "average" ? "Average Condition" : "Poor Condition"} </span></div>
                                            <div style={{ fontWeight: "bold", marginTop: "1rem", marginBottom: "1rem" }}  > Original Accessories available with your Device </div>
                                            <div style={{ fontWeight: "bold" }}>
                                                {
                                                    location.state.HaveBoxWithIMEI ? <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}> Box  </span> : ""
                                                }

                                                {
                                                    location.state.HaveDataCable ? <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}> Data Cable  </span> : ""
                                                }

                                                {
                                                    location.state.HaveAdapter ? <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}> Adapter </span> : ""
                                                }
                                                {
                                                    location.state.HaveEarphones ? <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}> Earphones </span> : ""
                                                }
                                                {
                                                    location.state.billClick ? <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}> Original Bill </span> : ""
                                                }
                                                {
                                                    location.state.powerCableClick ? <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}> Power Cable </span> : ""
                                                }
                                                
                                                {warrantyPeriod && (
                                                    <div>
                                                        {warrantyPeriod === "1" && (
                                                            <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}>
                                                                Warranty Period: Below 1 Month
                                                            </span>
                                                        )}
                                                        {warrantyPeriod === "3" && (
                                                            <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}>
                                                                Warranty Period: 1 To 3 Months
                                                            </span>
                                                        )}
                                                        {warrantyPeriod === "6" && (
                                                            <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}>
                                                                Warranty Period: 3 To 6 Months
                                                            </span>
                                                        )}
                                                        {warrantyPeriod === "9" && (
                                                            <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}>
                                                                Warranty Period: 6 To 9 Months
                                                            </span>
                                                        )}
                                                        {warrantyPeriod === "12" && (
                                                            <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}>
                                                                Warranty Period: 9 To 12 Months
                                                            </span>
                                                        )}
                                                        {warrantyPeriod === "default" && (
                                                            <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}>
                                                                Warranty Period: Above 1 Year
                                                            </span>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <div style={{ fontWeight: "bold", marginTop: "1rem" }}>  Purchased place : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.PurchasedPlace} </span></div>
                                            <div style={{ fontWeight: "bold" }}>  Additional Insurance : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.additionalClick} </span> </div>

                                        </Col>
                                    </Row>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6} className="d-flex align-items-stretch">
                            <Card style={{ width: "100%" }}>
                                <Card.Body  >
                                    <Card.Title style={{ fontWeight: "bold" }} > Price Summary </Card.Title>
                                    <Card.Text style={{ marginTop: "4rem", fontWeight: "bold" }} className="sell-price" > Final Selling Price <span className="price-val" style={{ color: "rgb(64, 167, 55)" }}> Rs. {basePrice} </span> </Card.Text>
                                    <Card.Footer style={{ marginTop: "3rem" }} >
                                        <Button variant="warning" type="submit" onClick={handleShow} style={{ borderRadius: '0px', width: 150, color: '#fff', fontWeight: 'bold', fontSize: '20px', backgroundColor: "#40a737", border: "0px", borderRadius: '10px' }}>
                                            Sell Laptop
                                        </Button>
                                    </Card.Footer>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>}
            {/* {showAddress && <AddAddress showAddress={showAddress} setShowAddress={setShowAddress} />} */}

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered size="lg">
                <Modal.Header closeButton className="fw-bold">
                    Mandatory Note
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <ul>
                            <li><strong>If you are located outside of Bangalore, Bhubaneswar, Kolkata, or Mumbai</strong>, please download the form and fill it out with all the necessary details. Attach the required documents and hand them over along with the device.</li>
                            <li><strong>If you are located in Bangalore, Bhubaneswar, Kolkata, or Mumbai</strong>, our executive will bring the form along with them. You can fill it up at the time of handing over the device.</li>
                            <li><strong>The form is mandatory</strong> along with the device.</li>
                        </ul>

                    </p>

                    <Button type="submit" variant="danger" className='mx-auto d-block' onClick={() => handleDownload()}  > Download PDF</Button>

                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="success" className='' onClick={() => { handleSubmit() }}> Sell Laptop</Button>
                </Modal.Footer>
            </Modal>


            <Modal show={show2} onHide={handleClose2} backdrop="static" keyboard={false} centered size="lg">
                <Userlogin isPurchacing={true} handleClose2={handleClose2} />
            </Modal>
        </>
    )
}

export default LaptopPricePage