import React, { useState } from "react";
import { Box } from "@mui/material";
import { Add } from "@mui/icons-material";

const ImageZoom = ({ src, alt, isSmallScreen }) => {
  const [isZoomed, setIsZoomed] = useState(false);
  const [backgroundPosition, setBackgroundPosition] = useState("center");
  const [overlayPosition, setOverlayPosition] = useState({ left: 0, top: 0 });

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    // console.log(width, height);
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;

    setBackgroundPosition(`${x}% ${y}%`);

    const overlayWidth = 200;
    const overlayHeight = 200;
    const offsetX = Math.max(
      -overlayWidth / 2,
      Math.min(
        e.clientX - left - overlayWidth / 2,
        width - overlayWidth + overlayWidth / 2
      )
    );
    const offsetY = Math.max(
      -overlayHeight / 2,
      Math.min(
        e.clientY - top - overlayHeight / 2,
        height - overlayHeight + overlayHeight / 2
      )
    );

    setOverlayPosition({ left: offsetX, top: offsetY });
  };

  const handleMouseEnter = () => {
    setIsZoomed(true);
  };

  const handleMouseLeave = () => {
    setIsZoomed(false);
    setBackgroundPosition("center");
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: isSmallScreen ? "100%" : "65%",
        height: isSmallScreen ? "75%" : "100%",
        overflow: "hidden",
        padding: isSmallScreen ? "0.5rem" : "0.5rem 2rem 0.5rem 0.5rem",
        borderRight: isSmallScreen ? "none" : "1px solid black",
      }}
    >
      <Box
        onMouseMove={handleMouseMove}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{ position: "relative", cursor: `url(${Add}), auto` }}
      >
        <img
          src={src}
          alt={alt}
          style={{
            maxWidth: "100%",
            cursor: "pointer",
            maxHeight: "70vh",
            height: isSmallScreen ? "60vh" : "",
            borderRadius: "1rem",
          }}
        />
        {isZoomed && (
          <Box
            sx={{
              position: "absolute",
              width: "200px",
              height: "200px",
              top: overlayPosition.top,
              left: overlayPosition.left,
              backgroundImage: `url(${src})`,
              backgroundSize: "500%",
              backgroundPosition: backgroundPosition,
              borderRadius: "1rem",
              pointerEvents: "none",
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default ImageZoom;
