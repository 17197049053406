import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Row,
} from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { loginSchema, registerSchema } from "../../schemas/auth";
import { Link } from "react-router-dom";
import sms from "../../Images/login/sms.png";
import otp from "../../Images/login/otp.png";
import Navigation from "../Navigation";
import Footer from "../Footer";
import img1 from "../../Images/Messenger-pana.png";
import img2 from "../../Images/Delivery-amico.png";
import img3 from "../../Images/driving school-cuate.png";
import img4 from "../../Images/In no time-pana.png";
import "../../ComponentsCss/userCss/Userlogin.css";
import Slider from "react-slick";
import { useMutation, useQuery } from "@apollo/client";
import { Navigate, useNavigate } from "react-router-dom";
import {
  MUTATION_SEND_USEROTP,
  MUTATION_USER_OTP_LOGIN,
  MUTATION_VERIFY_OTP,
  MUTATION_RESEND_OTP,
} from "../../graphql/Mutations";
import OtpInput from "react-otp-input";
import swal from "sweetalert";
import { QUERY_GET_ALL_USER } from "../../graphql/Query";
import toast from "react-hot-toast";
import imglog from "../../Images/5856-removebg-preview.png";

export default function Userlogin({ isPurchacing, handleClose2 }) {
  const inputRefs = useRef([]);
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const [formData, setFormData] = useState({
    phone: "",
    name: "",
  });
  const userToken = localStorage.getItem("userToken");

  const { data: userData } = useQuery(QUERY_GET_ALL_USER);

  const [validated, setValidated] = useState(false);
  const [validated2, setValidated2] = useState(false);
  const [gOtp, setGeneratedOtp] = useState("");
  const [contact, setContact] = useState("");
  const [name, setName] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [enteredOtp, setEnteredOtp] = useState("");

  const [userLoginDone, setUserLoginDone] = useState(false);
  const [sendOtp] = useMutation(MUTATION_SEND_USEROTP);
  const [verifyOtp] = useMutation(MUTATION_VERIFY_OTP);
  const [resendOtp] = useMutation(MUTATION_RESEND_OTP);
  const [userOtpLogin, { data: logindata, error }] = useMutation(
    MUTATION_USER_OTP_LOGIN
  );

  // const { data: user_data } = useQuery(GET_USERDATA_BY_USERID,{
  //   variables:{
  //     "userId": `${userId}`
  //   }
  // })
  const [isSignIn, setIsSignIn] = useState(false);
  const [otpSendClick, setOtpSendClick] = useState(false);
  const [otpState, setOtpState] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [otpResend, setOtpResend] = useState(false);
  const [timer, setTimer] = useState(60);
  const [orderId, setOrderId] = useState("");
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  useEffect(() => {
    let intervalId;
    if (otpSent) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [otpSent]);

  const validateField = async (name, value) => {
    try {
      if (isSignIn) {
        await loginSchema.validateAt(name, { [name]: value });
      } else {
        await registerSchema.validateAt(name, { [name]: value });
      }
      setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
    } catch (error) {
      console.log(error);
      setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateField(name, value);
  };

  const sendOTP = async () => {
    try {
      const res = await sendOtp({
        variables: {
          phoneNumber: `91${formData.phone}`,
        },
      });
      // console.log(res);
      setOrderId(res.data.sendOtp.orderId);
      toast.success(`OTP sent to ${formData.phone}`);
      setOtpSent(true);
    } catch (error) {
      // console.log(error.errors);
      console.log(error.message);
      // console.log(error.graphQlErrors);
    }
  };

  async function generateUserOtp(event) {
    event.preventDefault();
    try {
      if (isSignIn) {
        await loginSchema.validate(formData, { abortEarly: false });
      } else {
        await registerSchema.validate(formData, { abortEarly: false });
      }
      sendOTP();
    } catch (error) {
      // console.log(error);
      if (error.name === "ValidationError") {
        const validationErrors = {};
        error.inner.forEach((err) => {
          console.log(err.message);
          validationErrors[err.path] = err.message;
          setTouched((prevTouched) => ({
            ...prevTouched,
            [err.path]: true,
          }));
        });
        // console.log(validationErrors);
        // console.log(touched);
        setErrors(validationErrors);
      }
    }
  }

  function handleChangeClick() {
    setOtpSent(false);
  }

  const login = async () => {
    try {
      userOtpLogin({
        variables: {
          contact: `${formData.phone}`,
          otp: `${otpState}`,
          name: `${formData.name}`,
        },
      }).then((data) => {
        // console.log(data.data);
        localStorage.setItem("userId", data.data.userOtpLogin.userId);
        localStorage.setItem("userToken", data.data.userOtpLogin.userToken);
        localStorage.setItem(
          "userTokenExpiration",
          data.data.userOtpLogin.userTokenExpiration
        );

        if (isPurchacing) {
          handleClose2();
        } else {
          navigate("/");
        }
        return 0;
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    // console.log(orderId);

    try {
      const res = await verifyOtp({
        variables: {
          orderId: orderId,
          otp: otpState,
          phoneNumber: `91${formData.phone}`,
        },
      });
      // console.log(res.data);

      if (res.data.verifyOtp.isOTPVerified) {
        login();
      } else {
        toast.error(res.data.verifyOtp.reason);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleResendOtp = async () => {
    setTimer(60);
    try {
      const res = await resendOtp({
        variables: {
          orderId: orderId,
        },
      });
      // console.log(res.data);
      setOrderId(res.data.resendOtp.orderId);
    } catch (error) {
      console.log(error.message);
    }
  };

  function contactEnterAgain() {
    localStorage.removeItem("userOtp");
    setOtpError(false);
    setContact("");
    setOtpState("");
    setOtpSendClick(false);
  }

  function otpSendAgain() {
    setOtpResend(true);
    setOtpError(false);
    localStorage.removeItem("userOtp");
    setOtpState("");
    generateUserOtp();
  }

  const handlePage = () => {
    setIsSignIn(!isSignIn);
  };

  if (logindata) {
    localStorage.setItem("userId", logindata.userOtpLogin.userId);
    localStorage.setItem("userToken", logindata.userOtpLogin.userToken);
    localStorage.setItem(
      "userTokenExpiration",
      logindata.userOtpLogin.userTokenExpiration
    );
    localStorage.removeItem("userOtp");
  }
  if (userToken) {
    if (isPurchacing) {
      handleClose2();
    } else {
      navigate("/");
    }

    return 0;
  }

  return (
    <>
      {isPurchacing ? "" : <Navigation />}

      <Container className="loginmain  ">
        <div className="bglog"></div>
        <Row>
          <Col lg={1}></Col>
          <Col lg={10}>
            <Card className="logCard ">
              <div className="mobcard">
                {otpSent ? (
                  <>
                    <div>
                      <Image className="otp-send" src={otp}></Image>
                    </div>
                    <div>
                      <h3>OTP</h3>
                    </div>
                    <div className="text-center">
                      <p>Please enter the OTP sent to your mobile number</p>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <Image className="otp-login" src={sms}></Image>
                    </div>
                    <div>
                      <h3 className="getstarted">Let's get Started</h3>
                    </div>
                    <div className="text-center">
                      <p>We need to send OTP to authenticate your number</p>
                    </div>
                  </>
                )}
              </div>

              <div className="input-card ">
                <div className="card-content">
                  <h2 className="txtlogin">
                    {!isSignIn ? "Register" : "Login Now"}
                  </h2>

                  {otpSent ? (
                    <>
                      <form validated={validated2} onSubmit={handleClick}>
                        <div className="otp-form">
                          <div className="plzOtp">
                            <p>
                              Please enter the OTP sent to <br />
                              {formData.phone}. {"  "}{" "}
                              <Link
                                onClick={handleChangeClick}
                                className="change"
                              >
                                Change
                              </Link>
                            </p>
                          </div>
                          <div className="otp-textfield">
                            <OtpInput
                              value={otpState}
                              onChange={(otp) => {
                                const numericOtp = otp.replace(/\D/g, "");
                                setOtpState(numericOtp);
                              }}
                              numInputs={4}
                              inputStyle="inputStyle"
                              renderSeparator={<span></span>}
                              renderInput={(props) => <input {...props} />}
                            />
                          </div>
                          <div className="loginbtn no-align-center">
                            {" "}
                            <Button type="submit" className="btnlogin  ">
                              Login
                            </Button>
                          </div>
                          <div className="not-recieved-otp">
                            <p>
                              Not received your code?
                              {timer > 0 ? (
                                <span className="resend-timer">
                                  {" "}
                                  {`00:${timer}`}
                                </span>
                              ) : (
                                <Link
                                  onClick={handleResendOtp}
                                  className="resend-otp"
                                >
                                  {" "}
                                  Resend OTP
                                </Link>
                              )}
                            </p>
                          </div>
                        </div>
                      </form>
                    </>
                  ) : (
                    <>
                      <Form
                        validated={validated}
                        onSubmit={generateUserOtp}
                        className="loginform"
                      >
                        <Form.Group controlId="validationCustom01">
                          <div className="form-inputs">
                            {!isSignIn && (
                              <TextField
                                id="outlined-basic"
                                label="Enter Name"
                                variant="outlined"
                                onChange={handleInputChange}
                                value={formData.name}
                                size="small"
                                fullWidth
                                name="name"
                                error={touched.name && !!errors.name}
                                helperText={touched.name && errors.name}
                              />
                            )}

                            <TextField
                              id="outlined-basic"
                              label="Enter Phone number"
                              variant="outlined"
                              onChange={handleInputChange}
                              value={formData.phone}
                              size="small"
                              type="number"
                              fullWidth
                              name="phone"
                              error={!!errors.phone}
                              helperText={errors.phone}
                            />
                          </div>

                          <p className="terms">
                            By continuing, you agree to HelloFi Recommerce's{" "}
                            <span className="terms-link">
                              <Link to={"/terms-condition"}>Terms of Use</Link>
                            </span>{" "}
                            and{" "}
                            <span className="terms-link">
                              <Link to={"/privacy-policy"}>
                                Privacy Policy.
                              </Link>
                            </span>
                          </p>
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Button
                          type="submit"
                          className="btnlogin mx-auto d-block"
                        >
                          Request OTP
                        </Button>
                      </Form>
                      {!isSignIn ? (
                        <Button
                          onClick={handlePage}
                          type="submit"
                          className="existing-user "
                        >
                          Existing User? Sign in
                        </Button>
                      ) : (
                        <Button
                          onClick={handlePage}
                          type="submit"
                          className="existing-user "
                        >
                          New User? Create an account
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      {isPurchacing ? "" : <Footer />}
    </>
  );
}
