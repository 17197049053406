import {
  Box,
  Button,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Modal } from "react-bootstrap";
import { ArrowForwardOutlined } from "@mui/icons-material/";
import React, { useState, useEffect, useRef, useContext } from "react";
import { useRecoilValue } from "recoil";
import {
  loadingStateAtom,
  productAtom,
  productDetailsAtom,
} from "../../../../store/atoms/productDetails/productAtom";
import DiscountFunction from "../../../../utils/DiscountFunction";
import ProductFullfilment from "./ProductFullfilment";
import AvailableOffers from "./AvailableOffers";
import PinCodeCheck from "./PinCodeCheck";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import Userlogin from "../../../../Components/User/Userlogin";
import { CartContext } from "../../../../Components/Contex/ProductDetailContex";
import { useNavigate, useParams } from "react-router-dom";
import useGetCartItems from "../../hooks/addToCart/useGetCartItems";

const ProductDescription = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { addToCart } = useContext(CartContext);
  const [inCart, setInCart] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const handleClose2 = () => setShowLogin(false);
  const handleShow2 = () => setShowLogin(true);
  const userId = localStorage.getItem("userId");

  // console.log(inCart);

  const isSmallScreen = useMediaQuery("(max-width:767px)");
  const isTabletScreen = useMediaQuery(
    "(min-width:641px) and (max-width:1024px)"
  );
  const isTabletScreenGenuine = useMediaQuery(
    "(min-width:768px) and (max-width:1024px)"
  );
  const isMobileScreen = useMediaQuery("(max-width:640px)");
  const addToCartRef = useRef(null);
  const fixedDivRef = useRef(null);
  const {
    cartData,
    loading: cartDataLoading,
    error,
    refetch,
  } = useGetCartItems(userId);

  const loading = useRecoilValue(loadingStateAtom);
  const product = useRecoilValue(productDetailsAtom);
  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    window.scrollTo(0, 0);
    cartData?.items.map((item) => {
      if (item.product?.id === id) {
        return setInCart(true);
      }
    });
  }, [cartData]);

  // console.log(cartData);

  const [fixedButtonVisible, setFixedButtonVisible] = useState(false);
  const handleAddToCart = () => {
    if (!userId) {
      handleShow2();
    } else {
      addToCart(product);
    }
  };
  const handleProceedToCart = () => {
    navigate("/buying/cart");
  };
  useEffect(() => {
    const handleScroll = () => {
      const addToCartButton = addToCartRef.current;
      if (isMobileScreen) {
        setFixedButtonVisible(!fixedButtonVisible);
      }
      if (addToCartButton) {
        const rect = addToCartButton.getBoundingClientRect();
        const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
        setFixedButtonVisible(!isVisible);
      }
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          margin: isSmallScreen ? "0" : "0.5rem 0.2rem",
          padding: isSmallScreen
            ? "0.8rem"
            : isTabletScreenGenuine
            ? "0.8rem"
            : "2rem",
          borderRadius: "1rem",
          background: "rgb(238, 241, 250)",
          gap: "1rem",
          "&": {
            fontFamily: '"Poppins", sans-serif',
          },
        }}
      >
        <Box>
          {loading || !product ? (
            <Box display={"flex"} flexDirection={"column"} gap={"0.5rem"}>
              <Skeleton
                variant={"h3"}
                animation="wave"
                sx={{
                  borderRadius: "0.5rem",
                  fontSize: isSmallScreen ? "20px" : "24px",
                }}
              />
              <Skeleton
                variant={"h3"}
                animation="wave"
                sx={{
                  borderRadius: "0.5rem",
                  fontSize: isSmallScreen ? "20px" : "24px",
                }}
              />
            </Box>
          ) : (
            <CustomTypography
              variant="h3"
              sx={{
                fontOpticalSizing: "auto",
                fontWeight: "500",
                fontSize: isSmallScreen ? "20px" : "24px",
              }}
            >
              {product.productName}
            </CustomTypography>
          )}
        </Box>
        <Box>
          {loading || !product ? (
            <Box display={"flex"} flexDirection={"column"} gap={"0.5rem"}>
              <Skeleton
                variant={"subtitle1"}
                animation="wave"
                sx={{
                  borderRadius: "0.5rem",
                }}
              />
            </Box>
          ) : (
            <CustomTypography
              variant="subtitle1"
              sx={{
                color: "rgb(119, 119, 119)",
                fontFamily: '"Poppins", sans-serif',
              }}
            >
              {product.productSubTitle}
            </CustomTypography>
          )}
        </Box>
        <Box gap={"0.5rem"} display={"flex"} alignItems={"center"}>
          {loading || !product ? (
            <>
              <Skeleton
                variant="text"
                animation="wave"
                width={50}
                height={30}
              />
              <Skeleton
                variant="text"
                animation="wave"
                width={100}
                height={30}
              />
              <Skeleton
                variant="text"
                animation="wave"
                width={70}
                height={30}
              />
            </>
          ) : (
            <>
              <CustomTypography
                variant="h6"
                sx={{ fontFamily: '"Poppins", sans-serif' }}
              >
                MRP:
              </CustomTypography>
              <CustomTypography
                variant="subtitle1"
                sx={{
                  color: "rgb(119, 119, 119)",
                  textDecoration: "line-through",
                }}
              >
                ₹{product.mrp}
              </CustomTypography>
              <Box sx={{ alignItems: "flex-start" }}>
                <CustomTypography variant="h6">
                  {DiscountFunction(product.productPrice, product.mrp)}%OFF
                </CustomTypography>
              </Box>
            </>
          )}
        </Box>

        {!isMobileScreen && (
          <Box display={"flex"} justifyContent={"center"}>
            <Box sx={{ width: "80%" }}>
              {loading || !product ? (
                <>
                  <Skeleton
                    variant="rectangular"
                    height={50}
                    style={{ borderRadius: "3rem" }}
                  />
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    size="medium"
                    ref={addToCartRef}
                    onClick={inCart ? handleProceedToCart : handleAddToCart}
                    sx={{
                      backgroundColor: "rgb(6, 155, 170)",
                      width: "100%",
                      borderRadius: "3rem",
                      display: "flex",
                      padding: "0.5rem 1rem",
                      justifyContent: "space-between",
                      "&:hover": {
                        backgroundColor: "white",
                        color: "black",
                        border: "1px solid rgb(6, 155, 170)",
                      },
                    }}
                  >
                    <Box>
                      <CustomTypography
                        variant={isSmallScreen ? "subtitle1" : "subtitle1"}
                        sx={{ fontWeight: "500" }}
                      >
                        ₹{product.productPrice}
                      </CustomTypography>
                    </Box>
                    <Box>
                      <CustomTypography
                        variant={isSmallScreen ? "subtitle1" : "subtitle1"}
                        sx={{ fontWeight: "500" }}
                      >
                        {inCart ? "Go To Cart" : "Add To Cart"}{" "}
                        <ArrowForwardOutlined />{" "}
                      </CustomTypography>
                    </Box>
                  </Button>
                </>
              )}
            </Box>
          </Box>
        )}

        {loading || !product ? (
          <>
            {Array.from(new Array(4)).map((_, index) => (
              <Skeleton
                key={index}
                variant="rectangular"
                animation="wave"
                height={60}
                sx={{
                  borderRadius: "0.5rem",
                }}
              />
            ))}
          </>
        ) : (
          <>
            <ProductFullfilment
              condition={product.condition}
              warranty={product.warranty}
            />
          </>
        )}
        <PinCodeCheck />

        <AvailableOffers />
      </Box>

      {fixedButtonVisible && !loading && product && (
        <Box
          ref={fixedDivRef}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            width: "100%",
            textAlign: "center",
            zIndex: 1000,
          }}
        >
          <Box
            sx={{
              width: isMobileScreen ? "100%" : "80%",
              backgroundColor: "rgb(238, 241, 250)",
              display: "flex",
              flexDirection: isTabletScreen ? "column" : "row",
              gap: "1rem",
              padding: "1rem",
              justifyContent: "space-between",
              alignItems: "center",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              borderTopRightRadius: "1rem",
              borderTopLeftRadius: "1rem",
            }}
          >
            {!isMobileScreen && (
              <Box>
                <CustomTypography variant="h6">
                  {product.productName}
                </CustomTypography>
              </Box>
            )}
            <Box
              display="flex"
              alignItems="center"
              gap="1rem"
              sx={{ width: isMobileScreen ? "100%" : "auto" }}
            >
              {!isMobileScreen && (
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <CustomTypography
                    variant="subtitle1"
                    sx={{
                      color: "rgb(119, 119, 119)",
                      textDecoration: "line-through",
                    }}
                  >
                    ₹{product.mrp}
                  </CustomTypography>
                  <CustomTypography
                    variant="subtitle1"
                    sx={{ fontWeight: "500", alignSelf: "flex-start" }}
                  >
                    {DiscountFunction(product.productPrice, product.mrp)}%OFF
                  </CustomTypography>
                </Box>
              )}
              <Button
                variant="contained"
                size="medium"
                onClick={inCart ? handleProceedToCart : handleAddToCart}
                sx={{
                  backgroundColor: "rgb(6, 155, 170)",
                  borderRadius: "3rem",
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  padding: "0.5rem 1rem",
                  whiteSpace: "nowrap",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid rgb(6, 155, 170)",
                  },
                }}
              >
                <Box display={"flex"} gap={"3rem"}>
                  <CustomTypography
                    variant={isSmallScreen ? "subtitle1" : "subtitle1"}
                    sx={{ fontWeight: "500", marginRight: "0.5rem" }}
                  >
                    ₹{product.productPrice}
                  </CustomTypography>
                  <CustomTypography
                    variant={isSmallScreen ? "subtitle1" : "subtitle1"}
                    sx={{ fontWeight: "500" }}
                  >
                    {inCart ? "Go To Cart" : "Add To Cart"}{" "}
                    <ArrowForwardOutlined />
                  </CustomTypography>
                </Box>
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      <Modal
        show={showLogin}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Userlogin isPurchacing={true} handleClose2={handleClose2} />
      </Modal>
    </>
  );
};

export default ProductDescription;
