import React from "react";
import { Box } from "@mui/material";
import Navigation from "../../../Components/Navigation";
import ResponsiveContainer from "../../../muicomponents/shared/ResponsiveConatiner";
import OrderDetail from "../components/orderDetail/OrderDetail";

const OrderDetailsPage = () => {
  return (
    <Box minHeight={"100vh"}>
      <Navigation />
      <ResponsiveContainer>
        <OrderDetail />
      </ResponsiveContainer>
    </Box>
  );
};

export default OrderDetailsPage;
