import { Box, Slide, Modal } from '@mui/material';
import React from 'react';
import Filter from '../Filter';

const SortAndFilter = ({ setModalOpen, modalOpen }) => {
  const handleModalClose = () => {
    setModalOpen(false);
  };
  return (
    <Modal open={modalOpen} onClose={handleModalClose} closeAfterTransition>
      <Slide direction={'up'} in={modalOpen} mountOnEnter unmountOnExit>
        <Box
          sx={{
            position: 'fixed',
            border: 'none',
            outline: 'none',
            bottom: '0',
            left: '0',
            width: '100%',
            height: 'auto',
            maxHeight: '90%',
            backgroundColor: 'white',
          }}
        >
          <Filter isSmall={true} handleModalClose={handleModalClose} />
        </Box>
      </Slide>
    </Modal>
  );
};

export default SortAndFilter;
