import { Box, useMediaQuery, useTheme, useThemeProps } from '@mui/material';
import React, { useState } from 'react';
import ProductDetailsCard from '../../muiComponents/ProductDetailsCard';
import { DescriptionOutlined, ArrowCircleRight } from '@mui/icons-material';
import CustomTypography from '../../../../muicomponents/shared/CustomTypography';
import WarrantyScript from '../../icons/WarrantyScript';
import { GoPackage } from 'react-icons/go';
import ConditionModal from './ConditionModal';
import {
  loadingStateAtom,
  productDetailsAtom,
} from '../../../../store/atoms/productDetails/productAtom';
import { useRecoilValue } from 'recoil';

const ProductDescriptionList = () => {
  const [modal, setModal] = useState(false);
  const product = useRecoilValue(productDetailsAtom);
  const loading = useRecoilValue(loadingStateAtom);
  const [modalData, setModalData] = useState({
    title: '',
    description: '',
  });
  const theme = useTheme();
  const isSmallScreenTrue = useMediaQuery(theme.breakpoints.down('desktop'));
  const isSmallScreen = useMediaQuery('(max-width:767px)');

  if (loading) {
    return <p>Loading...</p>;
  }

  const cardData = [
    {
      id: 1,
      icon: <DescriptionOutlined />,
      title: 'Product Description',
      modalTitle: 'Product Description',
      modalDescription:
        (product && product.productDescription) || 'No description available.',
    },
    {
      id: 2,
      icon: <WarrantyScript fill='currentColor' />,
      title: 'Warranty Policy',
      modalTitle: 'Warranty Policy',
      modalDescription: product && product.warrantyDescription,
    },
    {
      id: 3,
      icon: <GoPackage style={{ fontSize: '1.5rem' }} />,
      title: 'Packaging Information',
      modalTitle: 'Packaging Information',
      modalDescription: product && product.AdditionalFeatures,
    },
    {
      id: 4,
      icon: <WarrantyScript fill='currentColor' />,
      title: 'Return And Replacement Policy',
      modalTitle: 'Return And Replacement Policy',
      modalDescription: product && product.dimensionsDescrption,
    },
  ];

  const handleClick = (card) => {
    // console.log('Card is:', card);
    setModalData({
      title: card.modalTitle,
      description: card.modalDescription,
    });
    setModal(true);
  };

  return (
    <>
      <Box
        display='flex'
        flexDirection='column'
        padding={isSmallScreen ? '0 0.8rem' : ''}
        sx={{ color: 'rgb(34, 34, 34)' }}
      >
        {cardData.map((card, index) => (
          <ProductDetailsCard
            key={index}
            sx={{
              borderTop: index === 0 ? '0.5px solid #D3D3D3' : '',
              '&:hover': isSmallScreen
                ? {}
                : {
                    cursor: 'pointer',
                    boxShadow:
                      'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px, rgba(14, 30, 37, 0.32) 0px 0px 16px 0px',
                    color: 'rgb(6, 155, 170)',
                  },
            }}
            display='flex'
            gap='0.5rem'
            justifyContent='space-between'
            onClick={() => handleClick(card)}
          >
            <Box display='flex' flexDirection='row' gap='0.5rem'>
              {card.icon}
              <CustomTypography>{card.title}</CustomTypography>
            </Box>
            <Box
              sx={{
                color: 'inherit',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ArrowCircleRight />
            </Box>
          </ProductDetailsCard>
        ))}
      </Box>
      <ConditionModal
        setModalOpen={setModal}
        modalOpen={modal}
        modalData={modalData}
      />
    </>
  );
};

export default ProductDescriptionList;
