import { atom } from 'recoil';

export const categoryState = atom({
  key: 'categoryState',
  default: '',
});

export const productsState = atom({
  key: 'productsState',
  default: [],
});

export const initialFiltersLoading = atom({
  key: 'initialFiltersLoading',
  default: true,
});

export const initialFilters = atom({
  key: 'initialFilters',
  default: {
    brand: [],
    condition: [],
    price: ['0', '250000'],
    storage: [],
    ram: [],
    connectivity: [],
    warranty: [],
  },
});

export const selectedFilterState = atom({
  key: 'filterState',
  default: {
    brand: [],
    condition: [],
    price: ['0', '250000'],
    storage: [],
    ram: [],
    connectivity: [],
    warranty: [],
  },
});

export const sortState = atom({
  key: 'sortState',
  default: { field: '', order: '' },
});
