import React from "react";
import Navigation from "../../../Components/Navigation";
import ResponsiveContainer from "../../../muicomponents/shared/ResponsiveConatiner";
import AddToCart from "../components/addToCart/AddToCart";
import NavbarCartMobile from "../../../layout/NavbarCartMobile";
import NavbarCart from "../../../layout/NavbarCart";
import { Box, useMediaQuery, useTheme } from "@mui/material";

const AddToCartPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  return (
    <Box sx={{ minHeight: "100vh", minWidth: "300px" }}>
      {isSmallScreen ? (
        <Box position={"sticky"} sx={{ top: "0", zIndex: 100 }}>
          <NavbarCartMobile title={"cart"} step={"1"} isBackPage={true} />
        </Box>
      ) : (
        <NavbarCart />
      )}
      <Box
        sx={{
          backgroundColor: isSmallScreen ? "#f4f4f5" : "white",
          marginBottom: "2rem",
          overflow: "hidden",
        }}
      >
        <ResponsiveContainer
          sx={{
            padding: isSmallScreen ? "0" : "0px 1rem",
            margin: "auto",
            maxWidth: "1060px",
            minHeight: "400px",
          }}
        >
          <AddToCart />
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default AddToCartPage;
