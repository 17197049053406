import React, { useEffect, useState } from "react";
import {
  CREATE_COD_ORDER,
  CREATE_ORDER,
  UPDATE_PAYMENT_STATUS,
  UPDATE_RESERVE_STOCK,
  VERIFY_RAZORPAY_PAYMENT,
} from "../../../../graphql/Mutations";
import { useMutation } from "@apollo/client";
import logo from "../../../../Images/homepage/logo.png";

import { useSnackbar } from "notistack";
import {
  selectedAddress,
  allCheckedCartItems,
  paymentInfoModalOpen,
  paymentSuccessAtom,
  orderIdAtom,
} from "../../../../store/atoms/payment/paymentAtom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  cartItems,
  cartPageBackdrop,
} from "../../../../store/atoms/addToCart/addToCart";
import { useNavigate } from "react-router-dom";

const useRazorpay = (totalProductPrice, paymentType, payingNow) => {
  const navigate = useNavigate();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const cartData = useRecoilValue(cartItems);
  const setOrderId = useSetRecoilState(orderIdAtom);
  const [modalOpen, setModalOpen] = useRecoilState(paymentInfoModalOpen);
  const [paymentSuccess, setPaymentSuccess] =
    useRecoilState(paymentSuccessAtom);
  const setBackdropOpen = useSetRecoilState(cartPageBackdrop);
  const [paymentMethod, setPaymentMethod] = useState("Razorpay");
  const [updatePaymentStatus] = useMutation(UPDATE_PAYMENT_STATUS);
  const [updateReserveStock] = useMutation(UPDATE_RESERVE_STOCK);
  useEffect(() => {
    if (paymentType == "cod") {
      setPaymentMethod("cod");
    }
  }, [paymentType]);
  const [verifyRazorPay_Payment] = useMutation(VERIFY_RAZORPAY_PAYMENT);
  const [createCodOrder] = useMutation(CREATE_COD_ORDER);
  const checkoutCartItems = cartData.items
    .filter((cartItem) => cartItem.isCheckedForCheckout)
    .map((cartItem) => ({
      id: cartItem.product.id,
      quantity: cartItem.quantity,
    }));

  const [orderData, setOrderData] = useState(null);
  const [isRazorpayLoaded, setIsRazorpayLoaded] = useState(false);
  const [createOrder] = useMutation(CREATE_ORDER);
  const selectedAddressState = useRecoilValue(selectedAddress);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    script.onload = () => setIsRazorpayLoaded(true);
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handlePayment = async () => {
    if (!isRazorpayLoaded) {
      return;
    }
    setBackdropOpen(true);

    try {
      if (paymentType === "cod") {
        setBackdropOpen(true);
        const { data } = await createCodOrder({
          variables: {
            amount: payingNow,
            addressId: cartData.address,
            selectedCartItems: checkoutCartItems,
            userId: userId,
            paymentType: paymentType,
            totalProductPrice: totalProductPrice,
            paymentMethod: paymentMethod,
          },
        });
        setBackdropOpen(false);
        const message = data.createCodOrder.message;
        const options = {
          variant: "success",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        };
        enqueueSnackbar(message, options);
        navigate(`/order-summary/${data.createCodOrder.orderId}`);
      } else {
        const { data } = await createOrder({
          variables: {
            amount: payingNow,
            addressId: cartData.address,
            selectedCartItems: checkoutCartItems,
            userId: userId,
            paymentType: paymentType,
            totalProductPrice: totalProductPrice,
            paymentMethod: paymentMethod,
          },
        });

        setOrderData(data.createOrder);

        const options = {
          key: "rzp_live_reGGQWV3MjGOwB",
          timeout: 600,
          amount: data.createOrder.amount,
          currency: data.createOrder.currency,
          name: "HelloFi Recommerce",
          description: "Purchase Description",
          order_id: data.createOrder.order_Id,
          image: logo,
          timeout: 600,
          handler: async function (response) {
            setBackdropOpen(true);
            const res = await verifyRazorPay_Payment({
              variables: {
                id: data.createOrder.orderId,
                razorpay: {
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_signature: response.razorpay_signature,
                },
                paymentId: data.createOrder.paymentId,
                isFull: data.createOrder.paymentType === "full" ? true : false,
              },
            });
            setPaymentSuccess(true);
            setModalOpen(true);
            setOrderId(res.data.verifyRazorPay_Payment.orderId);
            setBackdropOpen(false);
          },
          modal: {
            ondismiss: function () {
              setBackdropOpen(false);
              updateReserveStock({
                variables: {
                  orderId: data.createOrder.orderId,
                  isFull:
                    data.createOrder.paymentType === "full" ? true : false,
                },
              });
            },
          },
          prefill: {
            name: `${selectedAddressState.name}`,
            email: `${selectedAddressState.email}`,
            contact: `${selectedAddressState.contact}`,
          },
          retry: {
            enabled: false,
          },

          theme: {
            color: "rgb(6, 155, 170)",
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.on("payment.failed", async function () {
          setBackdropOpen(true);
          const res = await updatePaymentStatus({
            variables: {
              id: data.createOrder.orderId,
              status: "failed",
              paymentId: data.createOrder.paymentId,
            },
          });
          setPaymentSuccess(false);
          setModalOpen(true);
          setOrderId(res.data.updatePaymentStatus.orderId);
          setBackdropOpen(false);
        });
        paymentObject.on("open", () => {
          setBackdropOpen(false);
        });
        paymentObject.open();
      }
    } catch (error) {
      setBackdropOpen(false);
      console.error("Error creating order:", error.message);
    }
  };

  return { isRazorpayLoaded, handlePayment };
};

export default useRazorpay;
