import { Box, Button, Checkbox, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import CustomTextFieldCart from "../../../../../muicomponents/shared/CustomTextFieldCart";
import axios from "axios";
import { addressSchema } from "../../../../../schemas/address";
import useAddAddress from "../../../hooks/addAddress/useAddAddress";
import useUpdateAddress from "../../../hooks/addAddress/useUpdateAddress";
import { useNavigate } from "react-router-dom";

const Form = ({
  isModal = false,
  isMobile = false,
  address,
  isEdit = false,
  handleModalClose,
}) => {
  const userId = localStorage.getItem("userId");

  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const { addNewAddress } = useAddAddress();
  const { updateAddressById, loading, error } = useUpdateAddress();
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    alternateContactNo: "",
    email: "",
    address: "",
    pinCode: "",
    landMark: "",
    cityDistrict: "",
    state: "",
    gst: "",
    addressType: "home",
    isDefault: false,
  });
  useEffect(() => {
    if (isEdit && address) {
      setFormData({
        name: address.name || "",
        contact: address.contact || "",
        alternateContactNo: address.alternateContactNo || "",
        email: address.email || "",
        address: address.address || "",
        pinCode: address.pinCode || "",
        landMark: address.landMark || "",
        cityDistrict: address.cityDistrict || "",
        state: address.state || "",
        gst: address.gst || "",
        addressType: address.addressType || "home",
        isDefault: address.isDefault || false,
      });
    }
  }, [isEdit, address]);
  const [errors, setErrors] = useState({});
  const validateAllFields = useCallback(async () => {
    try {
      await addressSchema.validate(formData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (error) {
      const newErrors = {};
      error.inner.forEach((err) => {
        newErrors[err.path] = err.message;
      });
      setErrors(newErrors);
      return false;
    }
  }, [formData]);

  const handleAddAddress = async () => {
    const isValid = await validateAllFields();
    if (isValid) {
      const input = {
        ...(isEdit && { id: address.id }),
        userId: isEdit ? address.user : userId,
        name: formData.name,
        contact: formData.contact,
        alternateContactNo: formData.alternateContactNo,
        email: formData.email,
        address: formData.address,
        pinCode: formData.pinCode,
        landMark: formData.landMark,
        cityDistrict: formData.cityDistrict,
        state: formData.state,
        gst: formData.gst,
        addressType: formData.addressType,
        isDefault: formData.isDefault,
      };
      if (isEdit) {
        await updateAddressById(input);
        if (isMobile) {
          navigate("/checkout/address/list");
        }
        if (isModal) handleModalClose();
      } else {
        await addNewAddress(input);
        if (isMobile) {
          navigate("/checkout/address");
        }
        if (isModal) handleModalClose();
      }
    } else {
      // console.log("Addresss is not valid");
    }
  };

  const validateField = useCallback(async (name, value) => {
    try {
      await addressSchema.validateAt(name, { [name]: value });
      setErrors((prev) => ({ ...prev, [name]: "" }));
    } catch (error) {
      setErrors((prev) => ({ ...prev, [name]: error.message }));
    }
  }, []);

  const handleAddressTypeChange = (type) => {
    setFormData((prevData) => ({
      ...prevData,
      addressType: type,
    }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  const getAddressTypeStyle = (type) => ({
    padding: "0.2rem 1rem",
    borderRadius: "5rem",
    border: `1px solid ${
      formData.addressType === type ? "rgb(6, 155, 170)" : "black"
    }`,
    color: formData.addressType === type ? "rgb(6, 155, 170)" : "black",
    "&:hover": {
      cursor: "pointer",
    },
  });
  const fetchPincodeDetails = async (pincode) => {
    try {
      const response = await axios.get(
        `https://api.postalpincode.in/pincode/${pincode}`
      );
      if (response.data[0].Status === "Success") {
        const postOffice = response.data[0].PostOffice[0];
        setFormData((prevData) => ({
          ...prevData,
          cityDistrict: postOffice.District,
          state: postOffice.State,
        }));
      }
      if (response.data[0].Status === "Error") {
      }
    } catch (error) {
      console.error("Error fetching pincode details:", error);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handlePincodeKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchPincodeDetails(formData.pinCode);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        paddingTop: isModal || isMobile ? "0" : "1rem",
        borderRight: isModal || isMobile ? "none" : "1px solid #eaeaec",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: isMobile ? "0" : "1rem",
          border: isModal || isMobile ? "none" : "1px solid #eaeaec",
          borderRadius: "0.2rem",
          width: "100%",
          maxWidth: "500px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ padding: isMobile ? "1rem 0 1rem 1rem" : "0 0 1rem 0" }}>
            <CustomTypography
              variant={"subtitle2"}
              sx={{ fontWeight: "600", color: "#535766" }}
            >
              CONTACT DETAILS
            </CustomTypography>
          </Box>
          <Box
            sx={{
              backgroundColor: isMobile ? "white" : "",
              padding: isMobile ? "1rem" : "0",
              gap: "1rem",
              flexDirection: "column",
              display: "flex",
            }}
          >
            <CustomTextFieldCart
              label={"Name*"}
              name={"name"}
              onchange={handleInputChange}
              onBlur={handleBlur}
              value={formData.name}
              error={!!errors.name}
              helperText={errors.name}
            />
            <CustomTextFieldCart
              label={"Mobile No*"}
              name={"contact"}
              onchange={handleInputChange}
              onBlur={handleBlur}
              value={formData.contact}
              error={!!errors.contact}
              helperText={errors.contact}
            />
            <CustomTextFieldCart
              label={"Email*"}
              name={"email"}
              onchange={handleInputChange}
              onBlur={handleBlur}
              value={formData.email}
              error={!!errors.email}
              helperText={errors.email}
            />
          </Box>
          <Box
            sx={{ marginTop: "1rem", padding: isMobile ? "0 0 1rem 1rem" : "" }}
          >
            <CustomTypography
              variant={"subtitle2"}
              sx={{ fontWeight: "600", color: "#535766" }}
            >
              ADDRESS
            </CustomTypography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              padding: isMobile ? "1rem" : "",
              backgroundColor: isMobile ? "white" : "",
              marginTop: isMobile ? "0" : "1rem",
            }}
          >
            <CustomTextFieldCart
              label={"PinCode*"}
              name={"pinCode"}
              onBlur={(event) => {
                handleBlur(event);
                fetchPincodeDetails(formData.pinCode);
              }}
              value={formData.pinCode}
              error={!!errors.pinCode}
              helperText={errors.pinCode}
              onchange={handleInputChange}
              onKeyPress={handlePincodeKeyPress}
            />
            <CustomTextFieldCart
              label={"Address (House No, Building, Street, Area)*"}
              name="address"
              onBlur={handleBlur}
              value={formData.address}
              error={!!errors.address}
              helperText={errors.address}
              onchange={handleInputChange}
            />
            <CustomTextFieldCart
              label={"Landmark"}
              name="landMark"
              value={formData.landMark}
              error={!!errors.landMark}
              helperText={errors.landMark}
              onchange={handleInputChange}
            />
            <Box display={"flex"} gap={"1rem"}>
              <CustomTextFieldCart
                label={"City/District*"}
                name="cityDistrict"
                value={formData.cityDistrict}
                error={!!errors.cityDistrict}
                helperText={errors.cityDistrict}
                disabled={true}
                sx={{
                  backgroundColor: "#EEEBEB",
                  border: "none",
                  color: "black",
                }}
              />
              <CustomTextFieldCart
                label={"State*"}
                name="state"
                value={formData.state}
                error={!!errors.state}
                helperText={errors.state}
                disabled={true}
                sx={{ backgroundColor: "#EEEBEB", border: "none " }}
              />
            </Box>
            <CustomTextFieldCart
              name="alternateContactNo"
              value={formData.alternateContactNo}
              error={!!errors.alternateContactNo}
              helperText={errors.alternateContactNo}
              onchange={handleInputChange}
              label={"Alternate Contact No(optional)"}
            />
            <CustomTextFieldCart
              name="gst"
              value={formData.gst}
              error={!!errors.gst}
              helperText={errors.gst}
              onchange={handleInputChange}
              label={"GST Number(optional)"}
            />
          </Box>

          <Box sx={{ padding: isMobile ? "1rem 0 1rem 1rem" : "1rem 0" }}>
            <CustomTypography
              variant={"subtitle2"}
              sx={{ fontWeight: "600", color: "#535766" }}
            >
              SAVE ADDRESS AS
            </CustomTypography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"row"}
              gap={"1rem"}
              sx={{
                backgroundColor: isMobile ? "white" : "",
                padding: isMobile ? "1rem" : "",
              }}
            >
              <Box
                sx={getAddressTypeStyle("home")}
                onClick={() => handleAddressTypeChange("home")}
              >
                <CustomTypography
                  variant={"subtitle2"}
                  sx={{ fontSize: "0.8rem", fontWeight: "600" }}
                >
                  Home
                </CustomTypography>
              </Box>
              <Box
                sx={getAddressTypeStyle("work")}
                onClick={() => handleAddressTypeChange("work")}
              >
                <CustomTypography
                  variant={"subtitle2"}
                  sx={{ fontSize: "0.8rem", fontWeight: "600" }}
                >
                  Work
                </CustomTypography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: isModal ? "4rem" : isMobile ? "1rem" : "",
                backgroundColor: isMobile ? "white" : "",
                padding: isMobile ? "1rem" : "",
              }}
            >
              <Checkbox
                checked={formData.isDefault}
                onChange={() =>
                  setFormData({ ...formData, isDefault: !formData.isDefault })
                }
                sx={{
                  "&.Mui-checked": {
                    color: "rgb(6, 155, 170)",
                  },
                }}
              />
              <CustomTypography variant={"subtitle2"}>
                Make this my default address
              </CustomTypography>
            </Box>
            <Box
              width={"100%"}
              sx={
                isModal || isMobile
                  ? {
                      position: "fixed",
                      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      backgroundColor: "white",
                      padding: "1rem",
                      zIndex: 1000,
                    }
                  : {}
              }
            >
              <Button
                variant="outlined"
                onClick={handleAddAddress}
                sx={{
                  width: "100%",
                  color: "white",
                  borderRadius: "0.2rem",
                  padding: "0.5rem",
                  backgroundColor: "rgb(6, 155, 170)",
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "rgb(6, 155, 170)",
                  },
                }}
              >
                {isEdit ? "EDIT ADDRESS" : " ADD ADDRESS"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Form;
