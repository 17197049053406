import { Box } from '@mui/material';
import React from 'react';
import CustomTypography from '../../../../../muicomponents/shared/CustomTypography';
import AddressComponentMobile from './AddressComponentMobile';

const NonDefaultAddressMobile = ({ addresses }) => {
  if (addresses.length > 0) {
    return (
      <Box display={'flex'} flexDirection={'column'} sx={{ width: '100%' }}>
        <Box sx={{ padding: '1rem' }}>
          <CustomTypography
            variant={'subtitle2'}
            sx={{ fontWeight: '600', textTransform: 'uppercase' }}
          >
            Other Address
          </CustomTypography>
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={'0.5rem'}>
          {addresses.length > 0 &&
            addresses.map((address) => (
              <AddressComponentMobile defaultAddress={address} />
            ))}
        </Box>
      </Box>
    );
  }
};

export default NonDefaultAddressMobile;
