import {
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import React from 'react';
import DefaultAddressMobile from './DefaultAddressMobile';
import AddressMobile from '../chekoutAddress/AddressMobile';
import { selectedAddressIdState } from '../../../../../store/atoms/addAddress/addAddressAtoms';
import { useRecoilState } from 'recoil';

const AddressComponentMobile = ({ defaultAddress }) => {
  const [selectedAddressId, setSelectedAddressId] = useRecoilState(
    selectedAddressIdState
  );
  const handleCheckedChange = () => {
    setSelectedAddressId(defaultAddress.id);
  };
  return (
    <Box display={'flex'} sx={{ backgroundColor: 'white' }}>
      <Box sx={{ padding: '1rem 0 1rem 1rem' }}>
        <RadioGroup
          aria-labelledby='demo-radio-buttons-group-label'
          defaultValue='female'
          name='radio-buttons-group'
        >
          <FormControlLabel
            checked={defaultAddress.id === selectedAddressId}
            onChange={handleCheckedChange}
            control={<Radio />}
          />
        </RadioGroup>
      </Box>
      <Box flex={1}>
        <AddressMobile selectedAddress={defaultAddress} isList={true} />
      </Box>
    </Box>
  );
};

export default AddressComponentMobile;
