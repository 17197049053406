import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Image,
  InputGroup,
  Col,
  Form,
  Row,
  NavDropdown,
  Nav,
  Navbar,
  Badge,
  Offcanvas,
} from "react-bootstrap";
import "../ComponentsCss/Navigation.css";
import {
  FaBars,
  FaCaretDown,
  FaShoppingCart,
  FaPhoneAlt,
  FaSearch,
  FaUserCircle,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import logo from "../Images/homepage/logo.png";
import location from "../Images/homepage/sidebaricon/location.png";
import home from "../Images/homepage/sidebaricon/home.png";
import buy from "../Images/homepage/sidebaricon/buy.png";
import sell from "../Images/homepage/sidebaricon/sell.png";
import exchange from "../Images/homepage/sidebaricon/exchange.png";
import login from "../Images/homepage/sidebaricon/login.png";
import call from "../Images/homepage/sidebaricon/call.png";
import profile from "../Images/homepage/sidebaricon/profile.png";
import dash from "../Images/homepage/sidebaricon/dashboard.png";
import logout from "../Images/homepage/sidebaricon/logout.png";
import { useTypewriter } from "react-simple-typewriter";
import CategoryModal from "./HomePageModals/Category";
import ConditionModal from "./HomePageModals/phonemodals";
import { CartContext } from "./Contex/ProductDetailContex";
import { useContext } from "react";
import BuyCategoryModal from "./HomePageModals/BuyCategoryModal";
import { GET_SEARCH_BUY_PRODUCT } from "../graphql/Query";
import { useLazyQuery } from "@apollo/client";
import ExchangeModal from "./HomePageModals/ExchangeModal";
import { useMediaQuery, useTheme } from "@mui/material";

export default function Navigation() {
  const token = localStorage.getItem("adminToken");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const [validated, setValidated] = useState(false);
  const [showCategory, setShowCategory] = useState(false);
  const [showCategory2, setShowCategory2] = useState(false);
  const [showExchangeModal, setShowExchangeModal] = useState(false);

  const [searchBuyProduct, { loading, error, data: myValues }] = useLazyQuery(
    GET_SEARCH_BUY_PRODUCT
  );

  const [searchInput, setSearchInput] = useState("");
  const { cartData } = useContext(CartContext);

  const handleExchangeClick = () => {
    setShowExchangeModal(true);
  };
  const navigate = useNavigate();
  const userToken = localStorage.getItem("userToken");
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  function userlogoutHandle() {
    navigate("/");
    localStorage.removeItem("userToken");
    localStorage.removeItem("userId");
    window.location.reload();
  }
  function adminlogoutHandle() {
    localStorage.removeItem("adminId");
    localStorage.removeItem("adminToken");
    window.location.reload();
  }
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  function loginHandle() {
    navigate("/userloginpage");
  }
  const [text] = useTypewriter({
    words: ["Brand", "Models to Sell/Buy", "Sell/Buy", "Exchange"],
    loop: 0,
  });
  const handleCartClick = () => {
    if (isSmallScreen) {
      localStorage.setItem("step1prev", window.location.pathname);
    }

    navigate("/checkout/cart");
  };
  const handleSearch = () => {
    if (searchInput) {
      navigate("/search-result", {
        state: {
          search: true,
          searchInput: searchInput,
        },
      });
    }
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="dark"
        className="BgNavbar"
        sticky="top"
        style={{ minWidth: "300px" }}
      >
        <FaBars
          className="menubar"
          style={{ color: "#000", marginLeft: "15px" }}
          onClick={handleShow}
        />
        <Navbar.Brand className="Header pt-0">
          <Link to="/">
            <Image
              src={logo}
              className={userToken ? "logomainHeader2" : "logomainHeader"}
              style={{ verticalAlign: "unset" }}
            />
          </Link>
        </Navbar.Brand>

        {userToken ? (
          <div style={{ marginRight: "1rem" }} className="d-md-none">
            <FaUserCircle
              size={25}
              style={{ color: "#000", marginRight: 15 }}
              onClick={() => navigate("/userDashboard/yourorder")}
            />
            <span
              onClick={() => handleCartClick()}
              className="position-relative me-2 mr-5"
            >
              <FaShoppingCart className="text-dark fs-4 " />
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger ">
                {cartData === null ? "0" : cartData.items.length}
                <span className="visually-hidden">unread messages</span>
              </span>
            </span>
          </div>
        ) : (
          ""
        )}

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Link to="/" className="titleNavbar">
              Home
            </Link>
            <Link
              to="/"
              onClick={() => setShowCategory2(true)}
              className="titleNavbar"
            >
              Buying
            </Link>

            <Link
              to="/"
              className="titleNavbar"
              onClick={() => setShowCategory(true)}
            >
              Selling
            </Link>

            <Link
              to="/"
              className="titleNavbar"
              onClick={() => handleExchangeClick()}
            >
              Exchange
            </Link>

            {/* <Link to="/" className="titleNavbar">City <Image src={location} style={{ width: 20, height: 20 }} /></Link> */}
            <div className="serchint">
              <input
                className="searchinput"
                type="search"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                placeholder={`Search Models to buy `}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
                style={{ outline: "none", fontSize: 14 }}
              ></input>
              <FaSearch
                className="searchIcon"
                style={{ cursor: "pointer" }}
                onClick={() => handleSearch()}
              />
            </div>
            {userToken ? (
              <Link to="/userDashboard/yourorder" className="titleNavbar">
                Profile
              </Link>
            ) : token ? (
              <Link to="/admindashboard/adminchart" className="titleNavbar">
                Dashboard
              </Link>
            ) : (
              ""
            )}
            {/* {
                                token ? <Link to="/admindashboard/adminchart" className="titleNavbar" style={{ margin: '8px' }}>Dashboard</Link> : ''
                            } */}
            {token ? (
              <Link
                onClick={() => adminlogoutHandle()}
                to="/"
                className="titleNavbar"
              >
                Logout
              </Link>
            ) : userToken ? (
              <Link onClick={() => userlogoutHandle()} className="titleNavbar">
                Logout
              </Link>
            ) : (
              <Link
                to="/userloginpage"
                className="titleNavbar"
                style={{ textDecoration: "none" }}
              >
                Login/Signup
              </Link>
            )}
            {userToken ? (
              <span
                onClick={() => handleCartClick()}
                className="position-relative"
              >
                <FaShoppingCart className="text-dark fs-4" />
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {cartData === null ? "0" : cartData.items.length}
                  <span className="visually-hidden">unread messages</span>
                </span>
              </span>
            ) : (
              ""
            )}

            <Link to="tel:+918150835583" className="titleNavbar">
              <FaPhoneAlt /> Call Now
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {showCategory ? (
        <CategoryModal
          showCategory={showCategory}
          setShowCategory={setShowCategory}
        />
      ) : (
        ""
      )}
      {showCategory2 ? (
        <BuyCategoryModal
          showCategory2={showCategory2}
          setShowCategory2={setShowCategory2}
        />
      ) : (
        ""
      )}
      {showExchangeModal ? (
        <ExchangeModal
          showExchangeModal={showExchangeModal}
          setShowExchangeModal={setShowExchangeModal}
        />
      ) : (
        ""
      )}

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {" "}
            <Image src={logo} className="logomain" />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ width: "100%", padding: 0 }}>
          <div className="bgsidebar">
            {!userToken ? (
              <>
                <div>
                  <h3 style={{ fontSize: 18 }}>Hello</h3>

                  <p style={{ fontSize: 14 }}>Please login.</p>
                </div>

                <Button className="btnside" onClick={() => loginHandle()}>
                  Login
                </Button>
              </>
            ) : (
              <h3 style={{ fontSize: 18 }}>Hello !</h3>
            )}
          </div>
          <div className="sidebarlinks">
            <Link to="/" className="sidebarlin">
              <Image
                src={home}
                style={{ height: "25px", width: "25px", marginRight: 20 }}
              ></Image>{" "}
              Home
            </Link>
            <hr></hr>
            <Link
              to="/"
              className="sidebarlin"
              onClick={() => setShowCategory2(true)}
            >
              <Image
                src={buy}
                style={{ height: "25px", width: "25px", marginRight: 20 }}
              ></Image>{" "}
              Buy
            </Link>
            <hr></hr>
            <Link
              to=""
              closeButton
              className="sidebarlin"
              onClick={() => setShowCategory(true)}
            >
              {" "}
              <Image
                src={sell}
                style={{ height: "25px", width: "25px", marginRight: 20 }}
              ></Image>{" "}
              Sell
            </Link>
            <hr></hr>
            <Link
              to="/"
              className="sidebarlin"
              onClick={() => handleExchangeClick()}
            >
              <Image
                src={exchange}
                style={{ height: "25px", width: "25px", marginRight: 20 }}
              ></Image>{" "}
              Exchange
            </Link>
            <hr></hr>
            {userToken ? (
              <>
                <Link to="/userDashboard" className="sidebarlin">
                  <Image
                    src={profile}
                    style={{ height: "25px", width: "25px", marginRight: 20 }}
                  ></Image>{" "}
                  Profile
                </Link>
                <hr></hr>
              </>
            ) : (
              ""
            )}
            {token ? (
              <>
                <Link to="/admindashboard/adminchart" className="sidebarlin">
                  <Image
                    src={dash}
                    style={{ height: "25px", width: "25px", marginRight: 20 }}
                  ></Image>{" "}
                  Dashboard
                </Link>
                <hr></hr>
              </>
            ) : (
              ""
            )}
            {token ? (
              <>
                <Link
                  onClick={() => adminlogoutHandle()}
                  to="/"
                  className="sidebarlin"
                >
                  <Image
                    src={logout}
                    style={{ height: "25px", width: "25px", marginRight: 20 }}
                  ></Image>{" "}
                  Logout
                </Link>
                <hr></hr>
              </>
            ) : userToken ? (
              <>
                <Link onClick={() => userlogoutHandle()} className="sidebarlin">
                  <Image
                    src={logout}
                    style={{ height: "25px", width: "25px", marginRight: 20 }}
                  ></Image>{" "}
                  Logout
                </Link>
                <hr></hr>
              </>
            ) : (
              <>
                <Link to="/userloginpage" className="sidebarlin">
                  <Image
                    src={login}
                    style={{ height: "25px", width: "25px", marginRight: 20 }}
                  ></Image>{" "}
                  Login/Signup
                </Link>
                <hr></hr>
              </>
            )}
            <Link to="tel:+918150835583" className="sidebarlin">
              <Image
                src={call}
                style={{ height: "25px", width: "25px", marginRight: 20 }}
              ></Image>{" "}
              Call Now
            </Link>
            <hr></hr>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
