import React from 'react';
import { useSetRecoilState } from 'recoil';
import {
  allAddressAtom,
  allAddressPageBackdrop,
} from '../../../../store/atoms/addAddress/addAddressAtoms';
import { useMutation } from '@apollo/client';
import { MUTATION_UPDATE_ADDRESS } from '../../../../graphql/Mutations';

const useUpdateAddress = () => {
  const setBackdropOpen = useSetRecoilState(allAddressPageBackdrop);
  const setAddress = useSetRecoilState(allAddressAtom);
  const [updateAddress, { loading, error }] = useMutation(
    MUTATION_UPDATE_ADDRESS
  );

  const updateAddressById = async (input) => {
    try {
      setBackdropOpen(true);
      const { data } = await updateAddress({
        variables: { input },
      });
      if (data.updateAddress.success) {
        const updatedAddress = data.updateAddress.address;
        const newDefaultAddressId = data.updateAddress.newDefaultAddressId;
        setAddress((prevAddresses) => {
          const updatedAddresses = prevAddresses.map((address) => {
            if (address.id === updatedAddress.id) {
              return updatedAddress;
            }
            if (updatedAddress.isDefault) {
              return { ...address, isDefault: false };
            }
            if (newDefaultAddressId) {
              if (address.id === newDefaultAddressId) {
                return { ...address, isDefault: true };
              }
            }

            return address;
          });
          return updatedAddresses;
        });
      }
      setBackdropOpen(false);
    } catch (err) {
      console.error('Error updating address:', err);
      setBackdropOpen(false);
      throw err;
    }
  };

  return { updateAddressById, loading, error };
};

export default useUpdateAddress;
