import React, { useEffect } from "react";
import ResponsiveContainer from "../../../muicomponents/shared/ResponsiveConatiner";
import ProductDetails from "../components/productDetails/ProductDetails";
import Navigation from "../../../Components/Navigation";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";

const ProductDetailsPage = () => {
  const { id } = useParams();

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Navigation />
      <ResponsiveContainer>
        <ProductDetails productId={id} />
      </ResponsiveContainer>
    </Box>
  );
};

export default ProductDetailsPage;
