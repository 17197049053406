import React from "react";
import { ADD_ADDRESSID } from "../../../../graphql/Mutations";
import { useMutation } from "@apollo/client";
import {
  allAddressPageBackdrop,
  selectedAddressIdState,
} from "../../../../store/atoms/addAddress/addAddressAtoms";
import { useRecoilValue, useSetRecoilState } from "recoil";

const useAddAddressId = () => {
  const [addAddressId, { loading, error }] = useMutation(ADD_ADDRESSID);
  const selectedAddress = useRecoilValue(selectedAddressIdState);

  const userId = localStorage.getItem("userId");
  const setBackdropOpen = useSetRecoilState(allAddressPageBackdrop);
  const addAddressIdToCart = async () => {
    try {
      setBackdropOpen(true);
      const { data } = await addAddressId({
        variables: { addressId: selectedAddress, userId },
      });
      setBackdropOpen(false);
    } catch (error) {
      setBackdropOpen(false);
      throw error;
    }
  };
  return addAddressIdToCart;
};

export default useAddAddressId;
