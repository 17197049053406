import { Box } from '@mui/material';
import React from 'react';
import CustomTypography from '../../../../../muicomponents/shared/CustomTypography';
import AddressComponent from './AddressComponent';
import { useRecoilState } from 'recoil';
import { selectedAddressIdState } from '../../../../../store/atoms/addAddress/addAddressAtoms';

const NonDefaultAddress = ({ allAddress }) => {
  const [selectedAddressId, setSelectedAddressId] = useRecoilState(
    selectedAddressIdState
  );
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box>
        <CustomTypography
          variant='subtitle2'
          sx={{
            fontWeight: '600',
            color: '#535766',
            textTransform: 'uppercase',
          }}
        >
          Other Address
        </CustomTypography>
      </Box>
      {allAddress.map((address) => (
        <Box>
          <AddressComponent
            address={address}
            isSelected={selectedAddressId === address.id}
            onSelect={() => setSelectedAddressId(address.id)}
          />
        </Box>
      ))}
    </Box>
  );
};

export default NonDefaultAddress;
