import React from 'react';

const DiscountFunction = (price, mrp) => {
  const finalPrice = parseInt(price);
  const finalMrp = parseInt(mrp);
  const discount = Math.round(((finalMrp - finalPrice) / finalMrp) * 100);

  return discount;
};

export default DiscountFunction;
