import React from 'react';
import FlexBetween from '../../muiComponents/FlexBetween';
import { BsCash } from 'react-icons/bs';
import { Box, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import {
  productAtom,
  productDetailsAtom,
} from '../../../../store/atoms/productDetails/productAtom';
import { AccessTime } from '@mui/icons-material';
import CustomCard from '../../muiComponents/CustomCard';
import CustomTypography from '../../../../muicomponents/shared/CustomTypography';

const COD = () => {
  const product = useRecoilValue(productDetailsAtom);
  return (
    <>
      <CustomCard>
        <FlexBetween>
          <Box
            display={'flex'}
            gap={'0.5rem'}
            sx={{ color: 'rgb(119, 119, 119)' }}
          >
            <BsCash style={{ fontSize: '1.6rem' }} />

            <Box display={'flex'} gap={'0.5rem'}>
              <CustomTypography variant='subtitle1' sx={{ fontWeight: '500' }}>
                Cash On Delivery |{' '}
                <span style={{ fontWeight: '700', color: '#36454F' }}>
                  {parseInt(product.productPrice) <= 20000
                    ? 'Available'
                    : 'Not Available '}
                </span>
              </CustomTypography>
            </Box>
          </Box>
        </FlexBetween>
      </CustomCard>
      <CustomCard sx={{ color: 'rgb(119, 119, 119)' }}>
        <FlexBetween>
          <Box display={'flex'} gap={'0.5rem'}>
            <AccessTime />
            <CustomTypography sx={{ fontWeight: '500' }}>
              Easy Replacement Policy
            </CustomTypography>
          </Box>
        </FlexBetween>
      </CustomCard>
    </>
  );
};

export default COD;
