import React from "react";
import CustomCard from "../../muiComponents/CustomCard";
import FlexBetween from "../../muiComponents/FlexBetween";
import FlexCenter from "../../muiComponents/FlexCenter";
import { ThumbUpOutlined, Receipt } from "@mui/icons-material";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";

const ProductWarranty = ({ warranty }) => {
  return (
    <>
      <CustomCard>
        <FlexBetween>
          <FlexCenter gap={"0.5rem"}>
            <Receipt style={{ color: "rgb(119, 119, 119)" }} />
            <CustomTypography
              variant="subtitle1"
              sx={{ fontWeight: "500", color: "rgb(119, 119, 119)" }}
            >
              Warranty:
            </CustomTypography>
            <CustomTypography
              variant="subtitle1"
              sx={{ color: "#36454F", fontWeight: "700" }}
            >
              {warranty}
            </CustomTypography>
          </FlexCenter>
        </FlexBetween>
      </CustomCard>
    </>
  );
};

export default ProductWarranty;
