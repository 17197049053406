import React from "react";
import Footer from "../Components/Footer";
import { Outlet } from "react-router-dom";
import DeviceHandoverContent from "../Components/buyinstruction";
import { Box } from "@mui/material";

const BuyOutlet = () => {
  return (
    <Box sx={{ minWidth: "300px" }}>
      <Outlet />
      <DeviceHandoverContent />
      <Footer />
    </Box>
  );
};

export default BuyOutlet;
