import { Modal, useMediaQuery, useTheme, Box } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import AppliedCoupon from "./components/AppliedCoupon";
import { Close } from "@mui/icons-material";
import { useRecoilValue } from "recoil";
import { cartItems } from "../../../../../store/atoms/addToCart/addToCart";

const AppliedCouponModal = ({ modalOpen, setModalOpen }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const cartData = useRecoilValue(cartItems);

  const handleModalClose = () => {
    setModalOpen(false);
  };
  const style = {
    position: "fixed",
    top: isSmallScreen ? "" : "50%",
    left: isSmallScreen ? "0" : "50%",
    bottom: isSmallScreen ? "0" : "",
    minWidth: isSmallScreen ? "100%" : "400px",
    transform: isSmallScreen ? "" : "translate(-50%, -50%)",
    bgcolor: "white",
    border: "none",
    borderRadius: "0.2rem",
    boxShadow: 24,
    gap: "1rem",
  };
  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            backgroundColor: "white",
            borderBottom: "1px solid #eaeaec",
            padding: "1rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <CustomTypography variant={"subtitle1"} sx={{ fontWeight: "600" }}>
            Applied Coupons
          </CustomTypography>
          <Close
            onClick={handleModalClose}
            sx={{
              color: "#535766",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          />
        </Box>
        <Box sx={{ padding: "1rem" }}>
          <AppliedCoupon
            code={cartData.coupon.code}
            discount={cartData.couponDiscount}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default AppliedCouponModal;
