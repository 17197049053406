import { Box } from '@mui/material';
import React, { useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import CustomTypography from '../../../../../muicomponents/shared/CustomTypography';

const PaymentOptionCard = ({
  title,
  subTitle,
  img,
  dropdown,
  additionalText,
  setPaymentType,
  isSelected,
  setIsSelected,
  name,
  paymentType,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = (event) => {
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const handleClick = () => {
    if (name !== isSelected) {
      setPaymentType(paymentType);
      setIsSelected(name);
    }
  };

  return (
    <Box
      display={'flex'}
      flexDirection='column'
      sx={{
        backgroundColor: 'white',
        border: name === isSelected ? '2px solid rgb(6, 155, 170)' : 'none',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        borderRadius: '1rem',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      onClick={handleClick}
    >
      <Box
        display='flex'
        sx={{ justifyContent: dropdown ? 'space-between' : '' }}
      >
        <Box display={'flex'}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0.5rem',
            }}
          >
            <img
              src={img}
              style={{ width: '40px', height: '40px' }}
              alt={title}
            />
          </Box>
          <Box sx={{ padding: '0.5rem' }}>
            <CustomTypography
              variant={'subtitle1'}
              sx={{ fontWeight: '600', fontSize: '0.85rem' }}
            >
              {title}
            </CustomTypography>
            <CustomTypography
              variant={'subtitle2'}
              sx={{
                padding: '0.2rem 0.4rem',
                borderRadius: '0.4rem',
                backgroundColor: 'rgb(206, 237, 240)',
                fontSize: '0.75rem',
              }}
            >
              {subTitle}
            </CustomTypography>
          </Box>
        </Box>

        {dropdown && (
          <Box
            sx={{ display: 'flex', padding: '0.5rem', alignItems: 'center' }}
          >
            {isExpanded ? (
              <KeyboardArrowUp
                onClick={handleToggle}
                sx={{
                  cursor: 'pointer',
                }}
              />
            ) : (
              <KeyboardArrowDown
                onClick={handleToggle}
                sx={{
                  cursor: 'pointer',
                }}
              />
            )}
          </Box>
        )}
      </Box>
      {isExpanded && additionalText && (
        <Box
          sx={{
            padding: '0.5rem',
            backgroundColor: '#f5f5f5',
            borderBottomLeftRadius: '1rem',
            borderBottomRightRadius: '1rem',
          }}
        >
          <CustomTypography
            variant='subtitle2'
            sx={{ fontWeight: 500, fontSize: '0.75rem' }}
          >
            {additionalText}
          </CustomTypography>
        </Box>
      )}
    </Box>
  );
};

export default PaymentOptionCard;
