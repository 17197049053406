import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { selectedFilterState } from "../../../../../store/atoms/productListing/productListing";
import { Accordion, AccordionDetails, Box, Slider } from "@mui/material";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";

const Price = React.memo(({ sliderPrice, setTempFilters }) => {
  const [localPrice, setLocalPrice] = useState(sliderPrice.map(Number));
  const [inputValues, setInputValues] = useState(sliderPrice);

  useEffect(() => {
    setLocalPrice(sliderPrice.map(Number));
    setInputValues(sliderPrice);
  }, [sliderPrice]);

  const handleChange = (event, newValue) => {
    setInputValues(newValue.map(String));
    setLocalPrice(newValue);
  };

  const handleChangeCommitted = (event, newValue) => {
    setLocalPrice(newValue);
    setInputValues(newValue.map(String));
    setTempFilters((prev) => ({
      ...prev,
      price: newValue.map(String),
    }));
  };

  const handleInputChange = (index) => (event) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = event.target.value;
    setInputValues(newInputValues);
  };

  const handleInputKeyPress = (index) => (event) => {
    if (event.key === "Enter") {
      const newValue = Number(event.target.value);
      // console.log(`Input ${index} changed:`, newValue);
      const newLocalPrice = [...localPrice];
      newLocalPrice[index] = newValue;
      setLocalPrice(newLocalPrice);
      setInputValues(newLocalPrice.map(String));
      setTempFilters((prev) => ({
        ...prev,
        price: newLocalPrice.map(String),
      }));
    }
  };

  return (
    <Box
      width={"100%"}
      sx={{
        padding: "1rem 0",
        minHeight: "auto",
        borderBottom: "1px solid #D3D3D3",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{
          padding: "0 16px",
          cursor: "default",
        }}
      >
        <CustomTypography sx={{ fontWeight: "600", flexGrow: 1 }}>
          Price Range
        </CustomTypography>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"1rem"}
        padding={"0px 30px 16px"}
      >
        <Box>
          <Slider
            getAriaLabel={() => "Price range"}
            value={localPrice}
            onChange={handleChange}
            onChangeCommitted={handleChangeCommitted}
            valueLabelDisplay="off"
            min={0}
            max={250000}
            sx={{ color: "rgb(6, 155, 170)" }}
          />
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          sx={{ width: "100%", gap: "2rem" }}
        >
          <Box>
            <input
              type="number"
              value={inputValues[0]}
              onChange={handleInputChange(0)}
              onKeyDown={handleInputKeyPress(0)}
              style={{
                maxWidth: "80px",
                padding: "0.2rem",
                border: "1px solid grey",
                borderRadius: "0.3rem",
              }}
              min={0}
              max={250000}
            />
          </Box>
          <Box>
            <input
              type="number"
              value={inputValues[1]}
              onChange={handleInputChange(1)}
              onKeyDown={handleInputKeyPress(1)}
              style={{
                maxWidth: "80px",
                padding: "0.2rem",
                border: "1px solid grey",
                borderRadius: "0.3rem",
              }}
              min={0}
              max={250000}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

export default Price;
