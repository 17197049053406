import { TextField } from '@mui/material';
import React from 'react';

const CustomTextFieldCart = ({
  label,
  name,
  onchange,
  onBlur,
  error,
  helperText,
  sx = {},
  disabled = false,
  ...props
}) => {
  return (
    <TextField
      id='outlined-basic'
      label={label}
      name={name}
      onChange={onchange}
      onBlur={onBlur}
      variant='outlined'
      size='small'
      disabled={disabled}
      error={error}
      helperText={helperText}
      sx={{
        width: '100%',
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: disabled ? 'transparent' : '#d4d5d9',
          },
          '&:hover fieldset': {
            borderColor: disabled ? 'transparent' : '#d4d5d9',
          },
          '&.Mui-focused fieldset': {
            borderColor: disabled ? 'transparent' : '#282c3f',
            border: disabled ? 'none' : '1px solid #282c3f',
          },
          '&.Mui-disabled': {
            backgroundColor: '#EEEBEB',
            '& fieldset': {
              border: 'none',
            },
          },
        },
        '& .MuiInputLabel-root': {
          color: disabled ? '#666' : '#91a3b0',
          fontSize: '0.8rem',
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: disabled ? '#666' : '#282c3f',
          fontWeight: '100',
          fontSize: '1rem',
        },
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: '#000',
          color: '#000',
        },
        ...sx,
      }}
      {...props}
    />
  );
};

export default CustomTextFieldCart;
