import * as yup from 'yup';

export const registerSchema = yup.object().shape({
  name: yup.string().required('required'),
  phone: yup
    .string()
    .length(10, 'Phone number must be exactly 10 digits')
    .required('Please enter your phone number'),
});

export const loginSchema = yup.object().shape({
  phone: yup
    .string()
    .length(10, 'Phone number must be exactly 10 digits')
    .required('Please enter your phone number'),
});
