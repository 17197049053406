import { UPDATE_CART_ITEM_CHECKOUT } from "../../../../graphql/Mutations";
import { useMutation } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useSetRecoilState } from "recoil";
import {
  cartItems,
  cartPageBackdrop,
} from "../../../../store/atoms/addToCart/addToCart";

const useUpdateCartItemCheckout = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [updateCartItemCheckout, { loading, error }] = useMutation(
    UPDATE_CART_ITEM_CHECKOUT
  );
  const userId = localStorage.getItem("userId");
  const setCartItems = useSetRecoilState(cartItems);
  const setBackdropOpen = useSetRecoilState(cartPageBackdrop);

  const toggleCartItemCheckout = async (id) => {
    try {
      setBackdropOpen(true);
      const { data } = await updateCartItemCheckout({
        variables: { id: id, userId: userId },
      });

      if (data.updateCartItemCheckout) {
        const {
          totalAmount,
          totalMRP,
          discountOnMRP,
          count,
          updatedCartItem,
          message,
        } = data.updateCartItemCheckout;

        setCartItems((prevState) => {
          const updatedItems = prevState.items.map((item) =>
            item.id === updatedCartItem.id ? updatedCartItem : item
          );

          const sortedItems = updatedItems.sort((a, b) =>
            a.isCheckedForCheckout === b.isCheckedForCheckout
              ? 0
              : a.isCheckedForCheckout
              ? -1
              : 1
          );

          return {
            ...prevState,
            items: sortedItems,
            totalAmount,
            totalMRP,
            discountOnMRP,
            count,
          };
        });

        enqueueSnackbar(message, {
          variant: "success",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        });

        setBackdropOpen(false);
      }
    } catch (err) {
      console.error("Error updating cart item checkout:", err);
      setBackdropOpen(false);
      enqueueSnackbar("Failed to update cart item checkout", {
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }
  };

  return { toggleCartItemCheckout };
};

export default useUpdateCartItemCheckout;
