import { Box } from '@mui/material';
import React from 'react';
import CustomTypography from '../../../../../../muicomponents/shared/CustomTypography';

const ProductPrice = ({ price, mrp }) => {
  return (
    <Box display={'flex'} alignItems={'center'}>
      <CustomTypography
        variant='subtitle1'
        sx={{
          fontFamily: '"Poppins", sans-serif',
          fontWeight: '700',
          padding: '0.2rem 0.5rem',
          borderTopRightRadius: '0.5rem',
          borderBottomRightRadius: '0.5rem',
          backgroundColor: 'rgb(206, 237, 240)',
        }}
      >
        ₹{price}
        <span
          style={{
            color: 'rgb(119, 119, 119)',
            textDecoration: 'line-through',
            marginLeft: '0.5rem',
          }}
        >
          ₹{mrp}
        </span>
      </CustomTypography>
    </Box>
  );
};

export default ProductPrice;
