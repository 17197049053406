import React from "react";
import { Outlet } from "react-router-dom";
import DeviceHandoverContent from "../Components/buyinstruction";
import Footer from "../Components/Footer";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import NavbarCart from "./NavbarCart";
import NavbarCartMobile from "./NavbarCartMobile";
const CartRelatedOutlet = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  return (
    <Box
      sx={{
        backgroundColor: isSmallScreen ? "#f4f4f5" : "white",
        width: "100%",
        minWidth: "300px",
      }}
    >
      <Outlet />
      <Footer />
    </Box>
  );
};

export default CartRelatedOutlet;
