import React, { useEffect } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import MuiBackdrop from "../../../../muicomponents/shared/MuiBackdrop";
import PriceDetails from "../addAddress/priceDetails/PriceDetails";
import useGetCartItems from "../../hooks/addToCart/useGetCartItems";
import useGetAddressById from "../../hooks/payment/useGetAddressById";
import PaymentComponent from "./paymentComponents/PaymentComponent";
import { useNavigate } from "react-router-dom";

const Payment = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const userId = localStorage.getItem("userId");
  const {
    cartData,
    loading: cartLoading,
    error: cartError,
    refetch: refetchCartItems,
  } = useGetCartItems(userId);

  const addressId = cartData?.address;
  const {
    selectedAddressState,
    loading: addressLoading,
    error: addressError,
    refetch: refetchAddress,
  } = useGetAddressById(addressId);

  useEffect(() => {
    refetchCartItems();
    if (cartData && cartData.items.length === 0) {
      navigate("/checkout/cart");
    }
    if (addressId) {
      refetchAddress();
    }
  }, [refetchCartItems, refetchAddress, addressId, cartData]);

  if (cartLoading || addressLoading) {
    return <>Loading...</>;
  }
  if (cartError || addressError) {
    return <>Error...</>;
  }

  return (
    <>
      <MuiBackdrop />
      <Box>
        <Box display={"flex"} flexDirection={isSmallScreen ? "column" : "row"}>
          <Box
            flexBasis={isSmallScreen ? "100%" : "65%"}
            sx={{ width: "100%" }}
          >
            {cartData && cartData.totalAmount && <PaymentComponent />}
          </Box>
          {!isSmallScreen && (
            <Box flexBasis={"35%"} sx={{ width: "100%" }}>
              <PriceDetails isPaymentPage={true} />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Payment;
