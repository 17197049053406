import { Box, CircularProgress, useTheme } from '@mui/material';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { useParams } from 'react-router-dom';
import Product from './Product';
import {
  categoryState,
  selectedFilterState,
  sortState,
} from '../../../../../../store/atoms/productListing/productListing';
import { productsSelector } from '../../../../../../store/selectors/productListing/productListing';
import CustomTypography from '../../../../../../muicomponents/shared/CustomTypography';

const ProductCards = () => {
  let { category } = useParams();
  const theme = useTheme();
  const filters = useRecoilValue(selectedFilterState);
  const [allProducts, setAllProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const sortCriteria = useRecoilValue(sortState);
  const categoryAtom = useRecoilValue(categoryState);
  if (category === 'Desktop-Imac') {
    category = 'Desktop/Imac';
  }

  const productsLoadable = useRecoilValueLoadable(
    productsSelector({ category, filters, page, limit, sortCriteria })
  );

  const observer = useRef();
  const lastProductElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    setPage(1);
    setHasMore(true);
  }, [filters, category]);

  useEffect(() => {
    if (productsLoadable.state === 'hasValue') {
      const newProducts = productsLoadable.contents.products;
      if (page == 1) {
        setAllProducts([...newProducts]);
      } else {
        setAllProducts((prev) => [...prev, ...newProducts]);
      }

      setLoading(false);
      if (newProducts.length < limit) {
        setHasMore(false);
      }
    } else if (productsLoadable.state === 'loading') {
      setLoading(true);
    }
  }, [productsLoadable, limit]);

  if (productsLoadable.state === 'loading' && page === 1) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        flexWrap={'wrap'}
        sx={{
          alignItems: 'flex-start',
          overflow: 'hidden',
          gap: '1rem',
          padding: '0.5rem 0.5rem',
        }}
      >
        {allProducts.map((product, index) => (
          <Product
            ref={
              allProducts.length === index + 1 ? lastProductElementRef : null
            }
            key={`${product.id}-${index}`}
            product={product}
          />
        ))}
      </Box>
      {!loading && allProducts.length == 0 && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          width='100%'
          padding='1rem'
        >
          <CustomTypography variant={'h6'}>No Products Found</CustomTypography>
        </Box>
      )}
      {loading && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          width='100%'
          padding='1rem'
        >
          <CircularProgress />
        </Box>
      )}
      {!hasMore && allProducts.length > 0 && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          width='100%'
          padding='1rem'
        >
          <span>No more products</span>
        </Box>
      )}
    </Box>
  );
};

export default ProductCards;
