import { Box, useMediaQuery } from '@mui/material';
import React from 'react';
import CustomTypography from '../../../../../../muicomponents/shared/CustomTypography';
import { useTheme } from '@emotion/react';

const ProductTitle = ({ title, isSmall = false }) => {

  return (
    <Box sx={{ padding: isSmall ? '0' : '0 1rem' }}>
      <CustomTypography
        variant={'subtitle2'}
        sx={{
          fontWeight: '600',
        
          display: '-webkit-box',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
        }}
      >
        {title}
      </CustomTypography>
    </Box>
  );
};

export default ProductTitle;
