import { useState, useEffect } from 'react';
import Navigation from '../../Navigation';
import {
  Form,
  Modal,
  Button,
  Image,
  Card,
  Row,
  Col,
  Container,
  Dropdown,
} from 'react-bootstrap';
import '../../../ComponentsCss/QuestionnariesCss/step4.css';
import { FaArrowRight, FaDotCircle, FaArrowLeft } from 'react-icons/fa';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Userlogin from '../../User/Userlogin';

const SmartWatchStep4 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const { productdetail } = useParams();
  const [financeClick, setFinanceClick] = useState('');

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const userId = localStorage.getItem('userId');

  const [boxClick, setBoxClick] = useState(false);
  const [powerCableClick, setPowerCableClick] = useState(false);
  const [strapClick, setStrapClick] = useState(false);
  const [purchasedPlaceClick, setPurchasedPlace] = useState(false);
  const [billClick, setBillClick] = useState(false);
  const [additionalClick, setAdditionalClick] = useState(false);
  const [warrantyPeriod, setwarrantyPeriod] = useState(false);
  const [belowOneClick, setBelowOneClick] = useState(false);
  const [oneToThreeClick, setOneToThreeClick] = useState(false);
  const [threeToSixClick, setThreeToSiXClick] = useState(false);
  const [sixToNineClick, setSixToNineClick] = useState(false);
  const [nineToTwelveClick, setNineToTwelveClick] = useState(false);
  const [aboveOneClick, setAboveOneClick] = useState(false);

  const location = useLocation();
  const handleContinue = () => {
    if (!userId) {
      handleShow2();
    } else {
      navigate('/smartwatch-price', {
        state: {
          id: location.state.id,
          ram: location.state.ram,
          storage: location.state.storage,
          isDeviceSwitch: location.state.isDeviceSwitch,
          ableToMakeReceiveCall: location.state.ableToMakeReceiveCall,
          issueWithDeviceDisplay: location.state.issueWithDeviceDisplay,
          deviceScreen: location.state.deviceScreen,
          deviceEverRepair: location.state.deviceEverRepair,
          SpeakerIssue: location.state.SpeakerIssue,
          MicIssue: location.state.MicIssue,
          SensorIssue: location.state.SensorIssue,
          BatteryIssue: location.state.BatteryIssue,
          ButtonIssue: location.state.ButtonIssue,
          WifiIssue: location.state.WifiIssue,
          BluetoothIssue: location.state.BluetoothIssue,
          AutoRestartIssue: location.state.AutoRestartIssue,
          GpsIssue: location.state.GpsIssue,
          productCategory: location.state.productCategory,
          magneticCharging: location.state.magneticCharging,
          digitalCrownState: location.state.digitalCrownState,
          productBrand: location.state.productBrand,
          cellularState: location.state.cellularState,
          productName: location.state.productName,
          imgurl: location.state.imgurl,
          ConditionDeviceScreen: location.state.ConditionDeviceScreen,
          ConditionDeviceBody: location.state.ConditionDeviceBody,
          HaveBoxWithIMEI: boxClick,
          powerCableClick: powerCableClick,
          strapClick: strapClick,
          billClick: billClick,
          PurchasedPlace: purchasedPlaceClick,
          AdditionalInsurance: additionalClick,
          financeClick: financeClick,
          warrantyPeriod: warrantyPeriod,
          fingerPrintIssueState: location.state.fingerPrintIssueState,
          faceSensorIssueState: location.state.faceSensorIssueState,
        },
      });
    }
  };

  return (
    <>
      <Navigation />
      <div className='banner'>
        <h3>
          <span onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
            <FaArrowLeft />
          </span>
          <span
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              marginLeft: '5%',
            }}
          >
            Steps - 4/4
          </span>
        </h3>
      </div>

      <Container fluid>
        <Row>
          <h3
            style={{
              marginTop: '2rem',
              fontSize: 20,
              color: '#34495e',
              fontWeight: 'bold',
            }}
          >
            <FaDotCircle style={{ marginTop: '-1px', color: '#2ecc71' }} />{' '}
            Select the Original Accessories available with your Device
          </h3>
          <Col md={4} style={{ marginTop: '10px' }}>
            <div
              className={boxClick ? 'step4textBoxClicked' : 'step4textBox'}
              onClick={() =>
                boxClick ? setBoxClick(false) : setBoxClick(true)
              }
            >
              <h1
                style={{
                  fontSize: 20,
                  color: '#000',
                  marginTop: '5px',
                  fontWeight: 'bold',
                }}
              >
                Box
              </h1>
              <h1 style={{ fontSize: 12, color: '#000', marginTop: '5px' }}>
                Box with same IMEI/Serial number
              </h1>
            </div>
          </Col>
          <Col md={4} style={{ marginTop: '10px' }}>
            <div
              className={
                powerCableClick ? 'step4textBoxClicked' : 'step4textBox'
              }
              onClick={() =>
                powerCableClick
                  ? setPowerCableClick(false)
                  : setPowerCableClick(true)
              }
            >
              <h1
                style={{
                  fontSize: 20,
                  color: '#000',
                  marginTop: '5px',
                  fontWeight: 'bold',
                }}
              >
                Power cable
              </h1>
              <h1 style={{ fontSize: 12, color: '#000', marginTop: '5px' }}>
                Power cable
              </h1>
            </div>
          </Col>
          <Col md={4} style={{ marginTop: '10px' }}>
            <div
              className={strapClick ? 'step4textBoxClicked' : 'step4textBox'}
              onClick={() =>
                strapClick ? setStrapClick(false) : setStrapClick(true)
              }
            >
              <h1
                style={{
                  fontSize: 20,
                  color: '#000',
                  marginTop: '5px',
                  fontWeight: 'bold',
                }}
              >
                Strap
              </h1>
              <h1 style={{ fontSize: 12, color: '#000', marginTop: '5px' }}>
                Strap
              </h1>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4} style={{ marginTop: '10px' }}>
            <div
              className={billClick ? 'step4textBoxClicked' : 'step4textBox'}
              onClick={() =>
                billClick ? setBillClick(false) : setBillClick(true)
              }
            >
              <h1
                style={{
                  fontSize: 20,
                  color: '#000',
                  marginTop: '5px',
                  fontWeight: 'bold',
                }}
              >
                Bill
              </h1>
              <h1 style={{ fontSize: 12, color: '#000', marginTop: '5px' }}>
                Valid GST bill/Same IMEI/Serial number
              </h1>
            </div>
          </Col>
          <Col md={4} style={{ marginTop: '10px' }}>
            <div className={'step4textBox'}>
              <Dropdown>
                <Dropdown.Toggle
                  variant='success'
                  id='dropdown-basic'
                  style={{
                    backgroundColor: 'transparent',
                    color: '#000',
                    borderColor: 'transparent',
                    fontWeight: 'bold',
                    fontSize: 20,
                    marginLeft: '-10px',
                  }}
                >
                  Purchased Place
                </Dropdown.Toggle>
                {<span> {purchasedPlaceClick}</span>}
                <Dropdown.Menu>
                  <Dropdown.Item
                    style={{ fontWeight: 'bold' }}
                    onClick={() => setPurchasedPlace('India')}
                  >
                    India
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ fontWeight: 'bold' }}
                    onClick={() => setPurchasedPlace('Abroad')}
                  >
                    Abroad
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* <h1 style={{ fontSize: 12, color: '#000', marginTop: '5px' }}>Valid GST bill/Same IMEI/Serial number</h1> */}
            </div>
          </Col>

          <Col md={4} style={{ marginTop: '10px' }}>
            <div className='step4textBox'>
              <Dropdown>
                <Dropdown.Toggle
                  variant='success'
                  id='dropdown-basic'
                  style={{
                    backgroundColor: 'transparent',
                    color: '#000',
                    borderColor: 'transparent',
                    fontWeight: 'bold',
                    fontSize: 20,
                    marginLeft: '-10px',
                  }}
                >
                  Additional Insurance
                </Dropdown.Toggle>
                {<span> {additionalClick}</span>}

                <Dropdown.Menu>
                  <Dropdown.Item
                    style={{ fontWeight: 'bold' }}
                    onClick={() => setAdditionalClick('Apple Care+')}
                  >
                    Apple Care+
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ fontWeight: 'bold' }}
                    onClick={() => setAdditionalClick('Samsung Care+')}
                  >
                    Samsung Care+
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ fontWeight: 'bold' }}
                    onClick={() => setAdditionalClick('3rd Party')}
                  >
                    3rd Party Insurance
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ fontWeight: 'bold' }}
                    onClick={() => setAdditionalClick('Not applicable')}
                  >
                    Not applicable
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <h1 style={{ fontSize: 12, color: '#000', marginTop: '5px' }}>
                Valid Bill Required.
              </h1>
            </div>
          </Col>

          <Col md={4} style={{ marginTop: '10px' }}>
            <div className='step8textBox'>
              <Dropdown>
                <Dropdown.Toggle
                  variant='success'
                  id='dropdown-basic'
                  style={{
                    backgroundColor: 'transparent',
                    color: '#000',
                    borderColor: 'transparent',
                    fontWeight: 'bold',
                    fontSize: 20,
                    marginLeft: '-10px',
                  }}
                >
                  Is this Device under Finance
                </Dropdown.Toggle>
                {/* {<span> {financeClick}</span>} */}
                <h1 style={{ fontSize: 12, color: '#000', marginTop: '5px' }}>
                  {' '}
                  {financeClick}
                </h1>

                <Dropdown.Menu>
                  <Dropdown.Item
                    style={{ fontWeight: 'bold' }}
                    onClick={() => setFinanceClick('Samsung Finance')}
                  >
                    Samsung Finance
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ fontWeight: 'bold' }}
                    onClick={() => setFinanceClick('Bajaj Finance')}
                  >
                    Bajaj Finance
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ fontWeight: 'bold' }}
                    onClick={() => setFinanceClick('HDFC Finance')}
                  >
                    HDFC Finance
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ fontWeight: 'bold' }}
                    onClick={() => setFinanceClick('TVS Finance')}
                  >
                    TVS Finance
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ fontWeight: 'bold' }}
                    onClick={() => setFinanceClick('Not under Finance')}
                  >
                    Not under Finance
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
        </Row>
        {billClick ? (
          <Row>
            <h3
              style={{
                marginTop: '2rem',
                fontSize: 20,
                color: '#34495e',
                fontWeight: 'bold',
              }}
            >
              <FaDotCircle style={{ marginTop: '-1px', color: '#2ecc71' }} />{' '}
              Select the exact warranty period according to the bill
            </h3>
            <Col md={2} style={{ marginTop: 10 }}>
              <div
                className={
                  belowOneClick ? 'step4textBoxClicked' : 'step4textBox'
                }
                onClick={() =>
                  belowOneClick
                    ? setBelowOneClick(false)
                    : [
                        setBelowOneClick(true),
                        setOneToThreeClick(false),
                        setThreeToSiXClick(false),
                        setSixToNineClick(false),
                        setNineToTwelveClick(false),
                        setAboveOneClick(false),
                        setwarrantyPeriod('1'),
                      ]
                }
              >
                <h1
                  style={{
                    fontSize: 15,
                    color: '#000',
                    marginTop: '5px',
                    fontWeight: 'bold',
                  }}
                >
                  Below 1 Month
                </h1>
              </div>
            </Col>
            <Col md={2} style={{ marginTop: 10 }}>
              <div
                className={
                  oneToThreeClick ? 'step4textBoxClicked' : 'step4textBox'
                }
                onClick={() =>
                  oneToThreeClick
                    ? setOneToThreeClick(false)
                    : [
                        setBelowOneClick(false),
                        setOneToThreeClick(true),
                        setThreeToSiXClick(false),
                        setSixToNineClick(false),
                        setNineToTwelveClick(false),
                        setAboveOneClick(false),
                        setwarrantyPeriod('3'),
                      ]
                }
              >
                <h1
                  style={{
                    fontSize: 15,
                    color: '#000',
                    marginTop: '5px',
                    fontWeight: 'bold',
                  }}
                >
                  1 To 3 Months
                </h1>
              </div>
            </Col>
            <Col md={2} style={{ marginTop: 10 }}>
              <div
                className={
                  threeToSixClick ? 'step4textBoxClicked' : 'step4textBox'
                }
                onClick={() =>
                  threeToSixClick
                    ? setThreeToSiXClick(false)
                    : [
                        setBelowOneClick(false),
                        setOneToThreeClick(false),
                        setThreeToSiXClick(true),
                        setSixToNineClick(false),
                        setNineToTwelveClick(false),
                        setAboveOneClick(false),
                        setwarrantyPeriod('6'),
                      ]
                }
              >
                <h1
                  style={{
                    fontSize: 15,
                    color: '#000',
                    marginTop: '5px',
                    fontWeight: 'bold',
                  }}
                >
                  3 To 6 Months
                </h1>
              </div>
            </Col>
            <Col md={2} style={{ marginTop: 10 }}>
              <div
                className={
                  sixToNineClick ? 'step4textBoxClicked' : 'step4textBox'
                }
                onClick={() =>
                  sixToNineClick
                    ? setSixToNineClick(false)
                    : [
                        setBelowOneClick(false),
                        setOneToThreeClick(false),
                        setThreeToSiXClick(false),
                        setSixToNineClick(true),
                        setNineToTwelveClick(false),
                        setAboveOneClick(false),
                        setwarrantyPeriod('9'),
                      ]
                }
              >
                <h1
                  style={{
                    fontSize: 15,
                    color: '#000',
                    marginTop: '5px',
                    fontWeight: 'bold',
                  }}
                >
                  6 To 9 Months
                </h1>
              </div>
            </Col>
            <Col md={2} style={{ marginTop: 10 }}>
              <div
                className={
                  nineToTwelveClick ? 'step4textBoxClicked' : 'step4textBox'
                }
                onClick={() =>
                  nineToTwelveClick
                    ? setNineToTwelveClick(false)
                    : [
                        setBelowOneClick(false),
                        setOneToThreeClick(false),
                        setThreeToSiXClick(false),
                        setSixToNineClick(false),
                        setNineToTwelveClick(true),
                        setAboveOneClick(false),
                        setwarrantyPeriod('12'),
                      ]
                }
              >
                <h1
                  style={{
                    fontSize: 15,
                    color: '#000',
                    marginTop: '5px',
                    fontWeight: 'bold',
                  }}
                >
                  9 To 12 Months
                </h1>
              </div>
            </Col>
            <Col md={2} style={{ marginTop: 10 }}>
              <div
                className={
                  aboveOneClick ? 'step4textBoxClicked' : 'step4textBox'
                }
                onClick={() =>
                  aboveOneClick
                    ? setAboveOneClick(false)
                    : [
                        setBelowOneClick(false),
                        setOneToThreeClick(false),
                        setThreeToSiXClick(false),
                        setSixToNineClick(false),
                        setNineToTwelveClick(false),
                        setAboveOneClick(true),
                        setwarrantyPeriod('default'),
                      ]
                }
              >
                <h1
                  style={{
                    fontSize: 15,
                    color: '#000',
                    marginTop: '5px',
                    fontWeight: 'bold',
                  }}
                >
                  Above 1 year
                </h1>
              </div>
            </Col>
          </Row>
        ) : (
          ''
        )}

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '1rem',
            marginTop: '3rem',
          }}
        >
          <Button
            disabled={purchasedPlaceClick && additionalClick ? false : true}
            variant='warning'
            onClick={() => handleContinue()}
            style={{
              borderRadius: '0px',
              width: 150,
              color: '#fff',
              fontWeight: 'bold',
              fontSize: '20px',
              backgroundColor: '#40a737',
              border: '0px',
              borderRadius: '10px',
            }}
          >
            Next <FaArrowRight />
          </Button>
        </div>
      </Container>
      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop='static'
        keyboard={false}
        centered
        size='lg'
      >
        <Userlogin isPurchacing={true} handleClose2={handleClose2} />
      </Modal>
    </>
  );
};
export default SmartWatchStep4;
