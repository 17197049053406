import React from 'react';
import Camera from '../Images/newoffers/Sell Camera.png';
import Drones from '../Images/newoffers/Sell Drones.png';
import Earbuds from '../Images/newoffers/Sell Earbuds.png';
import Imac from '../Images/newoffers/Sell iMac.png';
import Laptops from '../Images/newoffers/Sell Laptop.png';
import MacMini from '../Images/newoffers/Sell Mac Mini.png';
import Phones from '../Images/newoffers/Sell Phone.png';
import PlayStation from '../Images/newoffers/Sell PlayStation.png';
import SmartWatch from '../Images/newoffers/Sell SmartWatch.png';
import Speakers from '../Images/newoffers/Sell Speaker.png';
import Tablets from '../Images/newoffers/Sell Tablet.png';
import { Container } from 'react-bootstrap';
import ImageSwiper from './ImageSwiper';

import CustomTypewriter from './CustomTypewriter';

export default function Newoffers() {
  const images = [
    { url: Phones, link: '/selling/Phones' },
    { url: Laptops, link: '/selling/Laptops' },
    { url: Tablets, link: '/selling/Tablets' },
    { url: SmartWatch, link: '/selling/Smart Watches' },
    { url: MacMini, link: '/otheraccessories' },
    { url: Imac, link: '/selling/Desktop-Imac' },
    { url: Earbuds, link: '/otheraccessories' },
    { url: Camera, link: '/otheraccessories' },
    { url: Drones, link: '/otheraccessories' },
    { url: PlayStation, link: '/otheraccessories' },
    { url: Speakers, link: '/otheraccessories' },
  ];
  const words = ['Ready to Upgrade?', 'Sell Your Old Device Today'];

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '50px' }}>
        <div
          style={{
            height: 40,
            width: 5,
            backgroundColor: '#44CE30',
            marginTop: 0,
          }}
        ></div>

        <h3
          className='typing-container'
          style={{
            marginTop: 10,
            marginLeft: 10,
            fontWeight: 500,
            marginBottom: 10,
          }}
        >
          <CustomTypewriter words={words} cursor={true} loop={false} />
        </h3>
      </div>

      <ImageSwiper images={images} />
    </>
  );
}
