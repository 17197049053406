import { useQuery } from "@apollo/client";
import { GET_QUERY_ALL_COUPON } from "../../../../graphql/Query";
import React from "react";

const useGetAllCoupons = () => {
  const { data, loading, error, refetch } = useQuery(GET_QUERY_ALL_COUPON);

  const coupons = data?.getAllCoupon || [];
  return { coupons, loading, error, refetch };
};

export default useGetAllCoupons;
