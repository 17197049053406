import { Box } from '@mui/material';
import React, { useState } from 'react';
import AddAddressModal from '../modals/AddAddressModal';

const AddNewAddressComponent = () => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Box
        onClick={() => setModalOpen(true)}
        sx={{
          border: '1px dashed #d4d5d9',
          borderRadius: '0.2rem',
          padding: '1.5rem',
          color: 'rgb(6, 155, 170)',
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        + ADD NEW ADDRESS
      </Box>
      <AddAddressModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </>
  );
};

export default AddNewAddressComponent;
