import { Box } from "@mui/material";
import React from "react";
import OrderedProduct from "./OrderedProduct";
import { orderSummaryAtom } from "../../../../../store/atoms/orderSummary/orderSummary";
import { useRecoilValue } from "recoil";

const AllProducts = () => {
  const orderSummary = useRecoilValue(orderSummaryAtom);
  return (
    <>
      {orderSummary && orderSummary.items && (
        <Box>
          {orderSummary.items.map((item) => (
            <OrderedProduct key={item.id} item={item} />
          ))}
        </Box>
      )}
    </>
  );
};

export default AllProducts;
