import { Box } from '@mui/material';
import React from 'react';
import ProductExperience from './ProductExperience';

const ProductOverview = () => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
      <ProductExperience />
    </Box>
  );
};

export default ProductOverview;
