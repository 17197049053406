import '../ComponentsCss/TrendingGadgets.css';
import React from 'react';
import { Card, Container, Image } from 'react-bootstrap';
import phone from '../Images/homepage/phone.png';
import Slider from 'react-slick';
import star from '../Images/homepage/star.png';
import { QUERY_GET_TRENDING_PRODUCTS } from '../graphql/Query';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import TrendingGadCard from './TrendingGadCard';

export default function TrendingGadgets({ products, isBuy }) {
  const options = {
    loop: true,
    default: false,
    center: false,
    items: 4,
    nav: true,
    dots: true,
    autoplay: true,
    autoplayTimeout: 4000,
    smartSpeed: 450,

    responsive: {
      0: {
        items: 2,
        nav: true,
        dots: true,
      },
      487: {
        items: 2,
        nav: true,
        dots: true,
      },
      787: {
        items: 3,
        nav: true,
        dots: true,
      },
      1207: {
        items: 4,
        nav: true,
        dots: true,
      },
    },
  };

  return (
    <>
      <Container className='trndmain'>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!isBuy && (
            <>
              <div
                style={{
                  height: 40,
                  width: 5,
                  backgroundColor: '#44CE30',
                  marginTop: 0,
                }}
              ></div>

              <h3 style={{ marginTop: 10, marginLeft: 10, fontWeight: 500 }}>
                Trending Gadgets
              </h3>
            </>
          )}
        </div>

        <OwlCarousel
          id='trending-gadgets'
          className='owl-carousel owl-theme'
          {...options}
        >
          {products.map((product) => (
            <div key={product.id}>
              <TrendingGadCard product={product} isBuy={isBuy} />
            </div>
          ))}
        </OwlCarousel>
      </Container>
    </>
  );
}
