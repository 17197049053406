import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { FilterAlt, SwapVert } from '@mui/icons-material';
import CustomTypography from '../../../../../muicomponents/shared/CustomTypography';
import SortAndFilter from '../modals/SortAndFilter';
import { useSetRecoilState } from 'recoil';
import { selectedFilterState } from '../../../../../store/atoms/productListing/productListing';
import { useParams } from 'react-router-dom';
import SortModal from '../modals/SortModal';

const SortAndFilterComponent = () => {
  let { category } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const handleClick = () => {
    setModalOpen(true);
  };

  const [sortModal, setSortModal] = useState(false);
  const handleSortModal = () => {
    setSortModal(true);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          bottom: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          width: '100%',
          textAlign: 'center',
          zIndex: 1000,
        }}
      >
        <Box
          sx={{
            width: '100%',
            backgroundColor: 'rgb(238, 241, 250)',
            display: 'flex',
            flexDirection: 'row',
            padding: '1rem',
            alignItems: 'center',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            borderTopRightRadius: '1rem',
            borderTopLeftRadius: '1rem',
          }}
        >
          <Box
            sx={{ width: '100%', borderRight: '1px solid grey' }}
            onClick={handleClick}
          >
            <CustomTypography variant={'subtitile1'} sx={{ fontWeight: '500' }}>
              Filters <FilterAlt />
            </CustomTypography>
          </Box>
          <Box sx={{ width: '100%' }} onClick={handleSortModal}>
            <CustomTypography variant={'subtitile1'} sx={{ fontWeight: '500' }}>
              Sort <SwapVert />
            </CustomTypography>
          </Box>
        </Box>
      </Box>
      <SortAndFilter modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <SortModal sortModalOpen={sortModal} setSortModalOpen={setSortModal} />
    </>
  );
};

export default SortAndFilterComponent;
