import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import Coupon from "./Coupon";
import PriceDetails from "./PriceDetails";

const PriceComponent = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={"0.5rem"}
      sx={{ backgroundColor: "white", padding: isSmallScreen ? "1rem" : "0" }}
    >
      <Coupon />
      <PriceDetails />
    </Box>
  );
};

export default PriceComponent;
