import { Modal, Card, Button, Col, Row, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import MobileCategory from '../../Images/mobileC.png';
import lap from '../../Images/laptopC.png';
import tab from '../../Images/Downloads/tab.png';
import watch from '../../Images/Downloads/watch.png';
// import desk from '../../Images/Downloads/desk.png'
import desk from '../../Images/Downloads/3.png';
// import other from '../../Images/Downloads/other.png'
import other from '../../Images/Downloads/other.png';
import SuperbWatch from '../../Images/Downloads/SmartWatch_superb.png';
import UnboxedWatch from '../../Images/Downloads/SmartWatch_unboxing.png';
import GoodWatch from '../../Images/Downloads/SmartWatch_good.png';
import FairWatch from '../../Images/Downloads/SmartWatch_fair.png';
import PartiallyWatch from '../../Images/Downloads/SmartWatch_partially.png';

import SuperbDesktop from '../../Images/Downloads/Desktop_superb.png';
import UnboxedDesktop from '../../Images/Downloads/Desktop_unboxing.png';
import GoodDesktop from '../../Images/Downloads/Desktop_good.png';
import FairDesktop from '../../Images/Downloads/Desktop_fair.png';
import PartiallyDesktop from '../../Images/Downloads/Desktop_partially.png';

import '../../ComponentsCss/SellModal.css';
import { useState } from 'react';
import ConditionModal from './phonemodals';

const BuyCategoryModal = ({ showCategory2, setShowCategory2 }) => {
  const navigate = useNavigate();
  const [categoryState, setCategoryState] = useState('');
  const [showCondition, setShowCondition] = useState(false);
  const [showCondition2, setShowCondition2] = useState(false);

  const handleSelectCategory = (cat) => {
    if (cat === 'Phones') {
      navigate(`/buying-products/${cat}`, {
        state: {
          productCategory: cat,
        },
      });

      setShowCategory2(false);
    } else if (cat === 'Laptops') {
      navigate(`/buying-products/${cat}`, {
        state: {
          productCategory: cat,
        },
      });

      setShowCategory2(false);
    } else if (cat === 'Tablets') {
      navigate(`/buying-products/${cat}`, {
        state: {
          productCategory: cat,
        },
      });

      setShowCategory2(false);
    } else if (cat === 'Other Accessories') {
      navigate(`/buying-products/${cat}`, {
        state: {
          productCategory: cat,
        },
      });

      setShowCategory2(false);
    } else if (cat == 'Smart Watches') {
      navigate(`/buying-products/${cat}`, {
        state: {
          productCategory: cat,
        },
      });

      setShowCategory2(false);
    } else if (cat == 'Desktop/Imac') {
      navigate(`/buying-products/Desktop-Imac`, {
        state: {
          productCategory: cat,
        },
      });

      setShowCategory2(false);
    }
  };

  return (
    <>
      <Modal
        show={showCategory2}
        onHide={() => setShowCategory2(false)}
        aria-labelledby='example-modal-sizes-title-lg'
        centered
      >
        <Modal.Header closeButton style={{ marginTop: '-20px' }}>
          <Modal.Title id='example-modal-sizes-title-lg'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  height: 30,
                  width: 5,
                  backgroundColor: '#44CE30',
                  marginTop: 30,
                }}
              ></div>
              <Card.Text
                style={{ marginTop: 30, marginLeft: 10, fontWeight: 500 }}
              >
                Choose your preference
              </Card.Text>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ marginTop: '-30px', marginBottom: '15px' }}>
          <Row>
            <Col xs={4} md={4}>
              <Card
                className='cardShadow1 categoryModel'
                style={{ height: '90%' }}
              >
                <Card.Body onClick={() => handleSelectCategory('Phones')}>
                  <Image
                    src={MobileCategory}
                    className=' mx-auto d-block'
                    fluid
                  />
                  <Card.Text
                    style={{
                      textAlign: 'center',
                      fontSize: 10,
                      fontWeight: 'bold',
                    }}
                  >
                    Buy Phone
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={4} md={4} onClick={() => handleSelectCategory('Laptops')}>
              <Card
                className='cardShadow2 categoryModel'
                style={{ height: '90%' }}
              >
                <Card.Body>
                  <Image
                    src={lap}
                    className='modelImgCard mx-auto d-block'
                    fluid
                  />
                  <Card.Text
                    style={{
                      textAlign: 'center',
                      fontSize: 10,
                      fontWeight: 'bold',
                    }}
                  >
                    Buy Laptop
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={4} md={4} onClick={() => handleSelectCategory('Tablets')}>
              <Card
                className='cardShadow3 categoryModel'
                style={{ height: '90%' }}
              >
                <Card.Body>
                  <Image src={tab} className='modelImgCard mx-auto d-block' />
                  <Card.Text
                    style={{
                      textAlign: 'center',
                      fontSize: 10,
                      fontWeight: 'bold',
                    }}
                  >
                    Buy Tablet
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col
              xs={4}
              md={4}
              onClick={() => handleSelectCategory('Smart Watches')}
            >
              <Card
                className='cardShadow4 categoryModel'
                style={{ height: '90%' }}
              >
                <Card.Body>
                  <Image src={watch} className='modelImgCard mx-auto d-block' />
                  <Card.Text
                    style={{
                      textAlign: 'center',
                      fontSize: 10,
                      fontWeight: 'bold',
                    }}
                  >
                    Buy Smart Watch
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col
              xs={4}
              md={4}
              onClick={() => handleSelectCategory('Desktop/Imac')}
            >
              <Card
                className='cardShadow5 categoryModel'
                style={{ height: '90%' }}
              >
                <Card.Body>
                  <Image src={desk} className='modelImgCard mx-auto d-block' />
                  <Card.Text
                    style={{
                      textAlign: 'center',
                      fontSize: 10,
                      fontWeight: 'bold',
                    }}
                  >
                    Buy Desktop/iMac
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col
              xs={4}
              md={4}
              onClick={() => handleSelectCategory('Other Accessories')}
            >
              <Card
                className='cardShadow6 categoryModel'
                style={{ height: '90%' }}
              >
                <Card.Body>
                  <Image src={other} className='modelImgCard mx-auto d-block' />
                  <Card.Text
                    style={{
                      textAlign: 'center',
                      fontSize: 10,
                      fontWeight: 'bold',
                    }}
                  >
                    Buy Other Accessories
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {showCondition ? (
        <ConditionModal
          showCondition={showCondition}
          setShowCondition={setShowCondition}
          productCategory={categoryState}
          image1={UnboxedWatch}
          image2={SuperbWatch}
          image3={GoodWatch}
          image4={FairWatch}
          image5={PartiallyWatch}
        />
      ) : (
        ''
      )}
      {showCondition2 ? (
        <ConditionModal
          showCondition={showCondition2}
          setShowCondition={setShowCondition2}
          productCategory={categoryState}
          image1={UnboxedDesktop}
          image2={SuperbDesktop}
          image3={GoodDesktop}
          image4={FairDesktop}
          image5={PartiallyDesktop}
        />
      ) : (
        ''
      )}
    </>
  );
};
export default BuyCategoryModal;
