import { createTheme } from '@mui/material';

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 321,
      tablet: 481,
      laptop: 769,
      desktop: 1025,
      extraLarge: 1201,
    },
  },
});

export default theme;
