import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";

const OrderedProduct = ({ item }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  return (
    <Box
      display={"flex"}
      gap={"0.5rem"}
      sx={{
        padding: "0.5rem 0",
        borderBottom: "1px solid #A2A2A2",
      }}
    >
      <Box>
        <img
          src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${item.product.image[0]}`}
          alt="this is"
          style={{ borderRadius: "1rem", minHeight: "120px" }}
          width={"120px"}
        />
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"0.1rem"}
        sx={{ flexGrow: 1 }}
      >
        <Box>
          <CustomTypography
            variant={"subtitle2"}
            sx={{
              fontWeight: "600",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          >
            {item.product.productName}
          </CustomTypography>
        </Box>
        <Box>
          <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "600" }}>
            Condition:{" "}
            <span style={{ color: "rgb(6, 155, 170)" }}>
              {item.product.condition}
            </span>
          </CustomTypography>
        </Box>
        <Box>
          <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "600" }}>
            Warranty:{" "}
            <span style={{ color: "rgb(6, 155, 170)" }}>
              {item.product.warranty}
            </span>
          </CustomTypography>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box
            sx={{
              backgroundColor: "#E5E2E2",
              padding: "0.1rem 0.8rem",
              borderRadius: "0.5rem",
            }}
          >
            X {item.quantity}
          </Box>
          <Box>
            <CustomTypography variant={"subtitle1"} sx={{ fontWeight: "600" }}>
              ₹ {item.product.productPrice}
            </CustomTypography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderedProduct;
