import { Box } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";

const AddressDetailComponent = ({ name, value }) => {
  return (
    <Box>
      <CustomTypography variant={"subtitle2"} sx={{ color: "#777474" }}>
        {name}:{" "}
        <span
          style={{
            color: "black",
            fontWeight: 600,
          }}
        >
          {value}
        </span>
      </CustomTypography>
    </Box>
  );
};

export default AddressDetailComponent;
