import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Spinner,
  Table,
  Dropdown,
  Card,
} from 'react-bootstrap';
import { GET_ALL_BUY_ORDERS } from '../../graphql/Query';
import { QUERY_GET_ALL_USER } from '../../graphql/Query';
import { MUTATION_GET_USER } from '../../graphql/Mutations';
import { MUTATION_UPDATE_BUY_ORDERS_BY_ID } from '../../graphql/Mutations';
import { useMutation, useQuery } from '@apollo/client';

import { FaEye, FaPen } from 'react-icons/fa';
import React, { useState } from 'react';

const AllBuyingOrders = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow(false);
  const handleClose2 = () => setShow2(false);
  const [selectedOrder, setSelectedOrder] = useState();

  const [orderStatus, setOrderStatus] = useState('');

  const [updateBuyOrderStatusById] = useMutation(
    MUTATION_UPDATE_BUY_ORDERS_BY_ID,
    {
      refetchQueries: [GET_ALL_BUY_ORDERS],
    }
  );

  let count = 1;
  const { data: getBuyorders, loading: OrderLoading } =
    useQuery(GET_ALL_BUY_ORDERS);

  const { data: AllUserData, loading: Alluserloading } =
    useQuery(QUERY_GET_ALL_USER);
  const handleStatusUpdate = (id, data) => {
    setSelectedOrder(data);
    setOrderStatus(data.status);
    setShow(true);
  };
  const handleSubmit = () => {
    updateBuyOrderStatusById({
      variables: {
        orderId: selectedOrder.id,
        status: `${orderStatus}`,
      },
    });
    handleClose();
  };
  const handleOrderDetail = (order) => {
    setSelectedOrder(order);
    setShow2(true);
  };
  let totalWithoutDiscount = 0;
  return (
    <>
      <h1
        style={{
          fontSize: 20,
          textAlign: 'center',
          fontWeight: 'bold',
          marginTop: 20,
        }}
      >
        Buying Orders
      </h1>
      <Table striped bordered style={{ marginTop: 10 }}>
        <thead className='table-head'>
          <tr>
            <th>s.no.</th>
            <th>Order No</th>
            <th>Payment Method</th>
            <th>Payment Status</th>
            <th>User Name </th>
            <th>contact / alternative contact </th>
            <th>Address</th>
            <th>Status</th>
            <th>Order Details</th>
            {/* <th>User Details</th> */}
          </tr>
        </thead>
        <tbody>
          {OrderLoading ? (
            <Spinner
              animation='border'
              variant='success'
              className='mx-auto d-block'
            />
          ) : (
            getBuyorders &&
            getBuyorders.getBuyorders
              .slice(0)
              .reverse()
              .map((data) => (
                <tr className='table-data'>
                  <td>{count++}</td>
                  <td> {data.orderNo} </td>
                  <td> {data?.paymentMethod} </td>
                  <td>
                    {' '}
                    {data.paymentMethod == 'COD'
                      ? 'COD'
                      : data?.paymentStatus == 'Successfully' ||
                        data?.paymentStatus == 'success'
                      ? 'Done'
                      : 'Failed'}{' '}
                  </td>
                  <td> {data.fName}</td>
                  <td>
                    {' '}
                    {data.contact}/
                    {data?.alternateContactNo
                      ? data.alternateContactNo
                      : 'not available'}
                  </td>
                  <td>
                    {' '}
                    {data.address} {data.city} {data?.pincode} {data?.landmark}{' '}
                    {data?.state} {data?.landmark} {data.email}
                  </td>
                  <td>
                    {' '}
                    {data.status}{' '}
                    <span
                      style={{ marginLeft: '5px', cursor: 'pointer' }}
                      onClick={() => handleStatusUpdate(data.id, data)}
                    >
                      {' '}
                      <FaPen />{' '}
                    </span>
                  </td>
                  <td
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleOrderDetail(data)}
                  >
                    {' '}
                    <FaEye />{' '}
                  </td>
                  {/*
                                    <td className='mx-auto d-block' style={{ cursor: "pointer" }} onClick={() => handleUserDetails(data.userId)} >  <FaEye /> </td> */}
                </tr>
              ))
          )}
        </tbody>
      </Table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Product Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Dropdown>
            <Dropdown.Toggle variant='success' id='dropdown-basic'>
              Product Status
            </Dropdown.Toggle>
            <div style={{ fontWeight: 'bold' }}>{orderStatus}</div>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setOrderStatus('Pending')}>
                Pending
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setOrderStatus('Ready to Ship')}>
                Ready to Ship
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setOrderStatus('Shipped')}>
                Shipped
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setOrderStatus('Delivered')}>
                Delivered
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive striped bordered hover>
            <thead className='table-head'>
              <th>Product Name </th>
              <th>Product Category </th>
              <th>Product Condition </th>
              <th>Product price </th>
              <th>Product quantity </th>
              <th>Product Image </th>
            </thead>
            <tbody>
              {selectedOrder?.productDetails?.map((product) => {
                totalWithoutDiscount =
                  totalWithoutDiscount + parseInt(product.productPrice);
                return (
                  <>
                    <tr>
                      <td> {product.productName} </td>
                      <td> {product.category} </td>
                      <td> {product.condition} </td>
                      <td> {product.productPrice} </td>
                      <td> {product.quantity} </td>
                      <td>
                        <Image
                          fluid
                          src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${product.productImage}`}
                          style={{ height: '4rem', margin: '3px' }}
                        ></Image>{' '}
                      </td>
                    </tr>
                  </>
                );
              })}
              <tr className='fw-bold'>
                <td>Total</td>

                <td>Rs. {totalWithoutDiscount}</td>
              </tr>
              <tr className='fw-bold'>
                <td>Coupon Discount</td>

                <td>
                  Rs.{' '}
                  {parseInt(totalWithoutDiscount) -
                    parseInt(selectedOrder?.totalprice)}
                </td>
              </tr>
              <tr className='fw-bold'>
                <td> Paid Amount </td>
                <td> Rs. {selectedOrder?.totalprice} </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose2}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AllBuyingOrders;
