import { Box, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import Navigation from '../../../Components/Navigation';
import ResponsiveContainer from '../../../muicomponents/shared/ResponsiveConatiner';
import AddAddress from '../components/addAddress/AddAddress';
import NavbarCartMobile from '../../../layout/NavbarCartMobile';
import NavbarCart from '../../../layout/NavbarCart';

const AddAddressPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('laptop'));
  return (
    <Box sx={{ overflow: 'hidden' }}>
      <NavbarCart />
      <Box
        sx={{
          backgroundColor: isSmallScreen ? '#f4f4f5' : 'white',
          marginBottom: '2rem',
        }}
      >
        <ResponsiveContainer
          sx={{
            padding: '0px 1rem',
            margin: 'auto',
            maxWidth: '1060px',
            minHeight: '400px',
          }}
        >
          <AddAddress />
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default AddAddressPage;
