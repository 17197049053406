import { atom, graphQLSelectorFamily } from 'recoil';

export const productIdAtom = atom({
  key: 'productIdAtom',
  default: '',
});

export const productAtom = atom({
  key: 'productAtom',
  default: {},
});

export const productDetailsAtom = atom({
  key: 'productDetailsAtom',
  default: null,
});

export const loadingStateAtom = atom({
  key: 'loadingStateAtom',
  default: true,
});

// export const productIdState = atom({
//   key: 'productIdState',
//   default: 0,
// });

// export const userQuery = selector({
//   key: 'userQuery',
//   get: async ({ get }) => {
//     const userId = get(userIdState);

//     try {
//       const { data } = await client.query({
//         query: gql`
//           query GetUser($id: ID!) {
//             user(id: $id) {
//               id
//               name
//               email
//             }
//           }
//         `,
//         variables: { id: userId },
//       });

//       return data.user;
//     } catch (error) {
//       throw error;
//     }
//   },
// });
