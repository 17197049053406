import React, { useRef } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import '../ComponentsCss/Slider.css';

// Import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';

export default function ImageSwiper({ images }) {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const navigate = useNavigate();

  return (
    <>
      <Swiper
        spaceBetween={10}
        centeredSlides={false}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        id='mySwiper'
        breakpoints={{
          // when window width is >= 320px
          250: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          // when window width is >= 900px
          900: {
            slidesPerView: 5,
            spaceBetween: 10,
          },
          // when window width is >= 1120px
          1120: {
            slidesPerView: 6,
            spaceBetween: 10,
          },
        }}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <div className='image-container'>
              <img
                onClick={() => navigate(`${image.link}`)}
                src={image.url}
                alt={`Slide ${index}`}
                style={{ borderRadius: '10%' }}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
