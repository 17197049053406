import React, { useEffect } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import Filter from './Filter';
import ProductsContainer from './ProductsContainer';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { categoryState } from '../../../../store/atoms/productListing/productListing';
import SortComponent from './sortComponents/SortComponent';

const ProductListing = () => {
  let { category } = useParams();
  const setCategory = useSetRecoilState(categoryState);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('laptop'));
  useEffect(() => {
    setCategory(category);
  }, []);

  return (
    <Box>
      {!isSmallScreen && (
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-end'}
          sx={{ padding: '0.5rem 1rem' }}
        >
          <SortComponent />
        </Box>
      )}
      <Box
        display={'flex'}
        flexDirection={'row'}
        gap={'1rem'}
        sx={{
          width: '100%',
          position: 'relative',
        }}
      >
        {!isSmallScreen && (
          <Box display={'flex'} flexBasis={'25%'} sx={{ padding: '0.5rem' }}>
            <Filter isSmall={false} />
          </Box>
        )}
        <Box display={'flex'} flexBasis={isSmallScreen ? '100%' : '75%'}>
          <ProductsContainer />
        </Box>
      </Box>
    </Box>
  );
};

export default ProductListing;
