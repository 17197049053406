import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import { QUERY_GET_FILTERED_BUY_PRODUCT } from "../../../../graphql/Query";
import { useQuery } from "@apollo/client";
import { useRecoilValue } from "recoil";
import { productDetailsAtom } from "../../../../store/atoms/productDetails/productAtom";
import RelatedProductContainer from "./RelatedProductContainer";

const RelatedProduct = ({ product }) => {
  const isSmallScreen = useMediaQuery("(max-width:767px)");

  const { data, loading, error } = useQuery(QUERY_GET_FILTERED_BUY_PRODUCT, {
    variables: {
      filterFileld: "productCategory",
      filterVal: product.productCategory,
    },
  });

  // console.log(data);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box>
        <CustomTypography
          variant="h4"
          sx={{
            fontOpticalSizing: "auto",
            fontWeight: "600",
            fontSize: isSmallScreen ? "20px" : "24px",
          }}
        >
          You may also like
        </CustomTypography>
      </Box>
      <RelatedProductContainer
        products={data.getFilterBuyProduct}
        length={data.getFilterBuyProduct.length}
      />
    </Box>
  );
};

export default RelatedProduct;
