import Navigation from "../Navigation";
import { Form, Button, Dropdown, Container } from "react-bootstrap";
import "../../ComponentsCss/OtherAccessories/otherAcess.css";
import { MUTATION_CREATE_QUOTE_REQ } from "../../graphql/Mutations";
import { QUERY_GET_ALL_QUOTE_REQ } from "../../graphql/Query";
import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import swal from "sweetalert";
import { useMediaQuery, useTheme } from "@mui/material";
import Banner from "../../Images/sellingBanner/Selling.jpg";
import { useEffect } from "react";

const OtherAccesso = () => {
  const theme = useTheme();
  const isVerySmallScreen = useMediaQuery(theme.breakpoints.down(481));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(992));
  const isIpadMin = useMediaQuery(theme.breakpoints.between(768, 822));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down(768));
  const userId = localStorage.getItem("userId");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [productType, setProductType] = useState("");
  const [productModel, setProductModel] = useState("");

  const [productBrand, setProductBrand] = useState("");
  const [productAge, setProductAge] = useState("Select Age");
  const [productCondition, setProductCondition] = useState("Select Condition");
  const [productAccessories, setProductAccessories] = useState();
  const [productInvoice, setProductInvoice] = useState("Select");
  const [productDefects, setProductDefects] = useState("");

  const [createQuoteReq] = useMutation(MUTATION_CREATE_QUOTE_REQ, {
    refetchQueries: [QUERY_GET_ALL_QUOTE_REQ],
  });
  const calculateTimeRemaining = (targetDate) => {
    const now = new Date();
    const difference = targetDate - now;

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const seconds = Math.floor((difference / 1000) % 60);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));

    return { days, hours, minutes, seconds };
  };
  const targetDate = new Date("2024-11-15T00:00:00");

  const [timeRemaining, setTimeRemaining] = useState(
    calculateTimeRemaining(targetDate)
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(targetDate));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [targetDate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate phone number
    const isValidPhoneNumber = /^\d{10}$/.test(phone);
    if (!isValidPhoneNumber) {
      swal({
        title: "Invalid Phone Number",
        text: "Please enter a 10-digit phone number.",
        icon: "error",
      });
      return;
    }
    createQuoteReq({
      variables: {
        quoteRequestInput: {
          userId: `${userId}`,
          productType: `${productType}`,
          productBrand: `${productBrand}`,
          ageOfProduct: `${productAge}`,
          physicalCondition: `${productCondition}`,
          accessoriesIncluded: `${productAccessories}`,
          originalInvoice: `${productInvoice}`,
          deviceDefect: `${productDefects}`,
          name: `${name}`, // Add new field
          email: `${email}`, // Add new field
          phone: `${phone}`, // Add new field
          productModel: `${productModel}`,
        },
      },
    })
      .then(() => {
        swal({
          title: "Successfull!!!",
          text: "Request Successfully Send",
          icon: "success",
        });
        setProductType("");
        setProductBrand("");
        setProductAge("Select Age");
        setProductCondition("Select Condition");
        setProductAccessories("");
        setProductInvoice("Select");
        setProductDefects("");
        setEmail("");
        setName("");
        setPhone("");
        setProductModel("");
      })
      .catch((e) => console.log(e));
  };
  return (
    <>
      <Navigation />
      <div
        style={{
          width: "100%",
          height: isSmallScreen ? "calc(100vh - 45px)" : "calc(100vh - 81px)",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img
          src={Banner}
          alt="Banner"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            zIndex: 0,
          }}
        />
        <div
          style={{
            position: "absolute",
            width: "100%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "white",
            textAlign: "center",
            zIndex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1
            style={{
              fontSize: isMobileScreen ? "1.5rem" : "3rem",
              fontWeight: 600,
            }}
          >
            We are Coming soon.
          </h1>
          <p
            style={{
              fontSize: isMobileScreen ? "1rem" : "1.5rem",
              width: isMobileScreen ? "90%" : "80%",
              fontWeight: "400",
            }}
          >
            We're working on a fresh new look to enhance your experience. Stay
            tuned for our upcoming redesign!
          </p>
          <div
            style={{
              fontSize: isMobileScreen ? "1.7rem" : "4rem",
              fontWeight: "500",
            }}
          >
            <span>
              {String(timeRemaining.days).padStart(2, "0")}{" "}
              <span style={{ fontSize: "2rem" }}>Days</span>{" "}
            </span>
            <span>
              {String(timeRemaining.hours).padStart(2, "0")}{" "}
              <span style={{ fontSize: "2rem" }}>Hours</span>{" "}
            </span>
            {isVerySmallScreen && <br />}
            <span>
              {String(timeRemaining.minutes).padStart(2, "0")}{" "}
              <span style={{ fontSize: "2rem" }}>Minutes</span>{" "}
            </span>
            {isIpadMin || (isVerySmallScreen && <br />)}
            <span>
              {String(timeRemaining.seconds).padStart(2, "0")}{" "}
              <span style={{ fontSize: "2rem" }}>Seconds</span>{" "}
            </span>
          </div>
          <p
            style={{
              width: isMobileScreen ? "90%" : "80%",
              fontSize: isMobileScreen ? "1rem" : "1.3rem",
            }}
          >
            Don't Worry! You can still sell your device with us.{" "}
          </p>
          <p
            style={{
              width: isMobileScreen ? "90%" : "80%",
              fontSize: isMobileScreen ? "1rem" : "1.3rem",
            }}
          >
            Call/WhatsApp us on 8150835583/8618394673 to know the price of your
            device instantly or fill the form below.
          </p>
        </div>
      </div>
      <div className="banner">
        <h3 style={{ textAlign: "center" }} className=" py-4 mb-3">
          {" "}
          Sell Other Accessories{" "}
        </h3>
      </div>
      <h3 style={{ textAlign: "center" }} className=" py-4 mb-3">
        Fill up and <span className="text-success"> Get a Quote </span> within
        24 hours.
      </h3>

      <Container style={{ padding: "0px" }}>
        <Form
          style={{
            width: "90%",
            padding: "1rem",
            boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px",
          }}
          className="mx-auto d-block "
          onSubmit={handleSubmit}
        >
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="other-form-lable">Name</Form.Label>{" "}
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="other-form-lable">Email</Form.Label>{" "}
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="email"
              placeholder="Your Email Id"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="other-form-lable">Phone</Form.Label>{" "}
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="tel"
              placeholder="Your Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="other-form-lable">Product Type</Form.Label>{" "}
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="Eg: AirPods, Speakers, Camera"
              value={productType}
              onChange={(e) => setProductType(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="other-form-lable">
              Brand Of Your Product
            </Form.Label>{" "}
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="Eg: Apple, Bose, Canon"
              value={productBrand}
              onChange={(e) => setProductBrand(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="other-form-lable">Model</Form.Label>{" "}
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="Paste exact online link of your product"
              value={productModel}
              onChange={(e) => setProductModel(e.target.value)}
            />
            <div
              style={{ fontSize: "12px", fontWeight: "bold" }}
              className=" mt-1 text-success"
            >
              {" "}
              Eg: www.flipkart.com/apple-iphone-13{" "}
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="other-form-lable">
              Age of your Product
            </Form.Label>{" "}
            <span style={{ color: "red" }}>*</span>
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                className="other-dropdown"
                id="dropdown-basic"
              >
                {productAge}
              </Dropdown.Toggle>

              <Dropdown.Menu
                popperConfig={{
                  modifiers: [
                    {
                      name: "flip",
                      enabled: false, // Disable flipping to prevent opening in top direction
                    },
                    {
                      name: "offset",
                      options: {
                        offset: [0, 10], // Adjust the offset as needed
                      },
                    },
                  ],
                }}
              >
                <Dropdown.Item
                  onClick={(e) => setProductAge("Less than 3 Months")}
                >
                  Less than 3 months
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => setProductAge("3-6 Months")}>
                  3-6 Months
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => setProductAge("6-12 Months")}>
                  6-12 Months
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={(e) => setProductAge("More than 1 Year")}
                >
                  More than 1 Year
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="other-form-lable">
              Physical Condition
            </Form.Label>{" "}
            <span style={{ color: "red" }}>*</span>
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="other-dropdown"
              >
                {productCondition}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setProductCondition("Excellent")}>
                  Excellent
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setProductCondition("Good")}>
                  Good
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setProductCondition("Fair")}>
                  Fair
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setProductCondition("Poor")}>
                  Poor
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="other-form-lable">
              Accessories Included
            </Form.Label>{" "}
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="text"
              placeholder="Eg: Box, Charger, Cable etc."
              value={productAccessories}
              onChange={(e) => setProductAccessories(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label className="other-form-lable">
              Original Invoice
            </Form.Label>{" "}
            <span style={{ color: "red" }}>*</span>
            <Dropdown>
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                className="other-dropdown"
              >
                {productInvoice}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setProductInvoice("Available")}>
                  Available
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setProductInvoice("Not available")}
                >
                  Not available
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className="other-form-lable">
              Tell us about the device(Additional incase of any defect/problem
              in it)
            </Form.Label>{" "}
            <span style={{ color: "red" }}>*</span>
            <Form.Control
              type="textarea"
              style={{ height: "100px" }}
              onChange={(e) => setProductDefects(e.target.value)}
              value={productDefects}
            />
          </Form.Group>
          <Button
            variant="success"
            type="submit"
            className="mx-auto d-block"
            disabled={
              productType &&
              productBrand &&
              productAge != "Select Age" &&
              productCondition != "Select Condition" &&
              productAccessories &&
              productInvoice != "Select" &&
              productDefects &&
              name &&
              email &&
              phone &&
              productModel
                ? false
                : true
            }
          >
            REQUEST A QUOTE
          </Button>
        </Form>
      </Container>
    </>
  );
};
export default OtherAccesso;
