import React from 'react';
import { useTypewriter, Cursor } from 'react-simple-typewriter';

const CustomTypewriter = ({ words, cursor, loop }) => {
  const [text] = useTypewriter({
    words,
    loop,
  });

  return (
    <span>
      {text.split(' ').map((word, index) => (
        <span
          key={index}
          style={{
            color:
              word.toLowerCase() === 'upgrade?' || word.toLowerCase() === 'sell'
                ? '#44CE30'
                : 'inherit',
          }}
        >
          {word}{' '}
        </span>
      ))}
      {cursor && <Cursor />}
    </span>
  );
};

export default CustomTypewriter;
