import React, { useState } from 'react';
import { Card } from 'react-bootstrap';

const YReviewCard = ({ youtube }) => {
  const [playClicked, setPlayCicked] = useState(false);

  const handleClick = () => {
    setPlayCicked(true);
  };
  return (
    <Card className='brand-card'>
      <div className='card-wrapper'>
        {!playClicked ? (
          <>
            <Card.Img
              variant='top'
              className='card-img'
              src={youtube.thumbnail}
            />
            <button
              id='playButton'
              aria-label='Play: Video'
              onClick={handleClick}
            ></button>
          </>
        ) : (
          <iframe
            width='100%'
            height='315'
            src={`https://www.youtube.com/embed/${youtube.youtube_ID}?si=apC8B8cZ1t0urIbR&autoplay=1`}
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            referrerPolicy='strict-origin-when-cross-origin'
            allowFullScreen={'true'}
          ></iframe>
        )}
      </div>

      <Card.Body>
        <Card.Title className='trending-gad-title'>{youtube.title}</Card.Title>
      </Card.Body>
    </Card>
  );
};

export default YReviewCard;
