import { Box, useMediaQuery, useTheme, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { LocalOfferOutlined } from "@mui/icons-material";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import useGetAllCoupons from "../../../hooks/addToCart/useGetAllCoupons";
import AddCouponModal from "../modals/AddCouponModal";
import useAddCoupon from "../../../hooks/addToCart/useAddCoupon";

const Coupon = () => {
  const theme = useTheme();
  const {
    addCoupon,
    loading: addCouponLoading,
    error: addCouponError,
  } = useAddCoupon();
  const { coupons, loading, error, refetch } = useGetAllCoupons();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const [modalOpen, setModalOpen] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponError, setCouponError] = useState("");

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleCouponChange = (event) => {
    const upperCaseValue = event.target.value.toUpperCase();
    setCouponCode(upperCaseValue);
    if (couponError) {
      setCouponError("");
    }
  };

  const handleApplyCoupon = async () => {
    if (!couponCode.trim()) {
      setCouponError("Please enter a coupon code");
    } else {
      setCouponError("");
      const addCouponError = await addCoupon(couponCode);
      setCouponCode("");
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.6rem",
        }}
      >
        <Box>
          <CustomTypography
            variant={"subtitle2"}
            sx={{ fontWeight: "600", color: "#535766" }}
          >
            COUPONS
          </CustomTypography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{ borderBottom: "1px solid #f5f5f6", padding: "0 0 0.5rem 0" }}
        >
          <Box display={"flex"} flexDirection={"row"}>
            <LocalOfferOutlined />
            <CustomTypography
              variant={"subtitle1"}
              sx={{
                fontWeight: "500",
                padding: "0 0 0 1rem",
                fontSize: "0.95rem",
              }}
            >
              Apply Coupons
            </CustomTypography>
          </Box>
          {/* {coupons.length > 0 && (
            <Box>
              <Button
                onClick={handleModalOpen}
                variant="outlined"
                sx={{
                  padding: 0,
                  border: "0.5px solid rgb(6, 155, 170)",
                  color: "rgb(6, 155, 170)",
                }}
              >
                APPLY
              </Button>
            </Box>
          )} */}
        </Box>
        {/* {coupons.length > 0 ? (
          <Box>Coupons Available</Box>
        ) : (
          <Box>
            <CustomTypography variant={"subtitle2"}>
              No coupons available at this time.Please check the final payment
              page for discounts and offers.
            </CustomTypography>
          </Box>
        )} */}
        <Box display={"flex"} sx={{ width: "100%", gap: "0.5rem" }}>
          <Box flexBasis={"80%"}>
            <TextField
              variant="outlined"
              size="small"
              label="Enter Coupon Code"
              value={couponCode}
              onChange={handleCouponChange}
              error={!!couponError}
              helperText={couponError}
              sx={{ width: "100%" }}
            />
          </Box>

          <Box flexBasis={"20%"}>
            <Button
              onClick={handleApplyCoupon}
              sx={{
                width: "100%",
                borderRadius: "0.2rem",
                color: "white",
                padding: "0.5rem",
                backgroundColor: "rgb(6, 155, 170)",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: "rgb(6, 155, 170)",
                },
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Box>
      <AddCouponModal openModal={modalOpen} setModalOpen={setModalOpen} />
    </>
  );
};

export default Coupon;
