import { Box } from '@mui/material';
import React from 'react';
import CustomTypography from '../../../../../muicomponents/shared/CustomTypography';
import AddressComponent from './AddressComponent';

const DefaultAddressComponent = ({ address, isSelected, onSelect }) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      sx={{ color: '#535766', textTransform: 'uppercase' }}
    >
      <Box>
        <CustomTypography variant='subtitle2' sx={{ fontWeight: '600' }}>
          Default Address
        </CustomTypography>
      </Box>
      <Box>
        <AddressComponent
          address={address}
          isSelected={isSelected}
          onSelect={onSelect}
        />
      </Box>
    </Box>
  );
};

export default DefaultAddressComponent;
