import { useMutation } from "@apollo/client";
import { MUTATION_DELETE_CART_ITEM } from "../../../../graphql/Mutations";
import { useSetRecoilState } from "recoil";
import {
  cartItems,
  cartPageBackdrop,
} from "../../../../store/atoms/addToCart/addToCart";
import useSnackbarNotifier from "../../../shared/hooks/useSnackbarNotifier";

const useDeleteCartItem = () => {
  const userId = localStorage.getItem("userId");
  const showSnackbar = useSnackbarNotifier();
  const setCartItemsState = useSetRecoilState(cartItems);
  const setBackdropOpen = useSetRecoilState(cartPageBackdrop);
  const [deleteCartItem, { loading, error }] = useMutation(
    MUTATION_DELETE_CART_ITEM
  );

  const deleteItemFromCart = async (itemId) => {
    try {
      setBackdropOpen(true);
      const { data } = await deleteCartItem({
        variables: {
          itemId,
          userId,
        },
      });
      if (data.deleteCartItem) {
        const {
          totalAmount,
          totalMRP,
          discountOnMRP,
          count,
          deletedCartItem,
          message,
        } = data.deleteCartItem;

        setCartItemsState((prevState) => {
          const updatedItems = prevState.items.filter(
            (item) => item.id !== deletedCartItem.id
          );
          return {
            ...prevState,
            items: updatedItems,
            totalAmount,
            totalMRP,
            discountOnMRP,
            count,
          };
        });
        showSnackbar(message, "success");
      }
      setBackdropOpen(false);
    } catch (err) {
      console.error("Error updating cart item checkout:", err);
      setBackdropOpen(false);
      throw err;
    }
  };
  return { deleteItemFromCart };
};

export default useDeleteCartItem;
