import { selector, selectorFamily } from 'recoil';
import client from '../../../client';
import {
  GET_PRODUCTS_BY_CATEGORY,
  QUERY_GET_EXPLORE_BRAND,
  GET_INITIAL_FILTERS,
} from '../../../graphql/Query';

export const productsSelector = selectorFamily({
  key: 'productsSelector',
  get:
    ({ category, filters, page, limit, sortCriteria }) =>
    async ({ get }) => {
      if (!category) return { products: [] };
      try {
        const productsData = await client.query({
          query: GET_PRODUCTS_BY_CATEGORY,
          variables: { category, filters, page, limit, sortCriteria },
        });

        return {
          products: productsData.data.getProductsByCat,
        };
      } catch (error) {
        throw error;
      }
    },
});

export const availableFiltersSelector = selectorFamily({
  key: 'availableFiltersSelector',
  get: (category) => async () => {
    if (!category) return {};
    try {
      const filters = await client.query({
        query: GET_INITIAL_FILTERS,
        variables: { category },
      });
      return filters.data.getFiltersByCat;
    } catch (error) {
      console.log('Error fetching filters', error);
      return {};
    }
  },
});
