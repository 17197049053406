import React, { useEffect } from "react";
import Footer from "../Footer";
import Navigation from "../Navigation";
const AboutUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Navigation />
            <div className="term-container">
                <div className="about-us-content" >
                    <h1>About Us - HelloFi Recommerce</h1>
                    <br/>
                    <h6>Join the Tech Revolution with HelloFi Recommerce: Buy and Sell Laptops and Phones</h6>
                    <p>In 2023, as global warming dominates headlines, Pratish and Sourav were inspired to make a difference. Amid the buzz of the Apple iPhone 15 launch, with many abandoning their iPhone 14s and others dreaming of their first phone, they founded <strong>HelloFi Recommerce </strong>an eco-friendly platform for affordable tech.</p>
                    <br/>
                    <h6>Sell Your Used Laptops and Phones for the Best Prices</h6>
                    <p>At HelloFi, we make it easy to sell your used laptops and phones and make room for the latest tech. Get the best prices for your old gadgets and explore a wide range of quality-checked products at unbeatable rates. Every item undergoes rigorous testing to ensure it’s perfect for you.</p>
                    <br/>
                    <h6>Upgrade and Help the Planet</h6>
                    <p>Staying up-to-date with technology shouldn’t mean compromising the environment. Selling your old devices funds your new ones and helps reduce e-waste, which comprises 70% of our toxic waste, yet only 12.5% is recycled. The rest ends up in landfills, polluting soil and groundwater, or is burned, releasing harmful toxins into the air.</p>
                    <br/>
                    <h6>Why Recycle with HelloFi?</h6>
                    <br/>
                    <ul>
                        <li>
                            <p><strong>Save Energy:</strong> Proper disposal prevents harmful toxins from contaminating our ecosystem.</p>
                        </li>
                        <li>
                            <p><strong>Protect the Environment: </strong> Recycling electronics saves energy compared to producing new ones.</p>
                        </li>
                        <li>
                            <p><strong>Save Money: </strong> Earn money from your old gadgets to fund your new tech purchases.</p>
                        </li>
                        <li>
                            <p><strong>Promote Health: </strong> Reduce exposure to toxins that can cause life-threatening diseases.</p>
                        </li>
                    </ul>
                    <br/>
                    <h6>Our Mission: A Sustainable Future</h6>
                    <p>Recycling your electronics with HelloFi is an investment in the present and future. It’s a step towards a healthier planet and a healthier you. Plus, by giving your old tech a new home, you’re helping others access affordable technology.</p>
                    <br/>
                    <h6>Join HelloFi Recommerce Today—For Affordable Tech and a Greener Planet!</h6>
                    <p>Ready to make a change? Visit <a href="https://hellofi.in">HelloFi Recommerce</a> to sell your used laptops and phones and shop for quality, affordable tech. Upgrade your tech, protect the environment, and save money all at once. Let’s walk hand in hand with technology and nature.</p>
                    <h2 className="highlighted-tagline">HelloFi - A cycle where everyone benefits.</h2>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default AboutUs;
