import { Box } from '@mui/material';
import React from 'react';
import {
  initialFilters,
  selectedFilterState,
} from '../../../../../store/atoms/productListing/productListing';
import { useRecoilState, useRecoilValue } from 'recoil';
import AccordionComponent from '../AccordionComponent';

const Storage = React.memo(({ storage, checkedStorage, setTempFilters }) => {
  const handleCheckBoxStorage = (event, storageName) => {
    if (event.target.checked) {
      setTempFilters((prevSelected) => ({
        ...prevSelected,
        storage: [...prevSelected.storage, storageName],
      }));
    } else {
      setTempFilters((prevSelected) => ({
        ...prevSelected,
        storage: prevSelected.storage.filter((name) => name !== storageName),
      }));
    }
  };

  const handleClear = (event) => {
    setTempFilters((prevSelected) => ({
      ...prevSelected,
      storage: [],
    }));
  };
  return (
    <>
      {storage.length > 0 && (
        <AccordionComponent
          title={'Storage'}
          checkBoxes={storage}
          checkedFilterState={checkedStorage}
          handleCheckboxChange={handleCheckBoxStorage}
          handleClear={handleClear}
        />
      )}
    </>
  );
});

export default Storage;
