import React, { useState, useEffect } from 'react';
import { Carousel, Container, Row, Col } from 'react-bootstrap';
import { QUERY_GET_YOUTUBE_REVIEWS } from '../graphql/Query';
import { useQuery } from '@apollo/client';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'axios';
import YReviewCard from './YReviewCard';
import '../ComponentsCss/Youtube.css';

export default function Youtube() {
  const { data: getAllYoutube, loading: Loading } = useQuery(
    QUERY_GET_YOUTUBE_REVIEWS
  );
  const [videoInfo, setVideoInfo] = useState(null);
  const [videoId, setVideoId] = useState('u609viWHxvM');

  const videoIds = [
    ['u609viWHxvM', 'VIDEO_ID_2', 'VIDEO_ID_3'],
    ['VIDEO_ID_4', 'VIDEO_ID_5', 'VIDEO_ID_6'],
  ];
  useEffect(() => {
    const fetchVideoInfo = async () => {
      try {
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=AIzaSyAiACg9HJUtdJIYZ_s87ioC8MgQYl2Rkv0&part=snippet`
        );

        const videoData = response.data.items[0].snippet;
        //youtu.be/Eza-Z_nAQOI?si=-4KgcXaEJ8Na13wv
        setVideoInfo(videoData);
      } catch (error) {
        console.error('Error fetching video info:', error);
      }
    };

    fetchVideoInfo();
  }, []);

  const options = {
    loop: true,
    default: false,
    center: false,
    items: 4,

    autoplay: false,
    autoplayTimeout: 4000,
    smartSpeed: 450,

    responsive: {
      0: {
        items: 1,
        nav: true,
        dots: true,
      },
      487: {
        items: 2,
        nav: true,
        dots: true,
      },
      787: {
        items: 3,
        nav: true,
        dots: true,
      },
      1207: {
        items: 3,
        nav: true,
        dots: true,
      },
    },
  };

  return (
    <Container className='trndmain'>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            height: 40,
            width: 5,
            backgroundColor: '#44CE30',
            marginTop: 0,
          }}
        ></div>
        <h3 style={{ marginTop: 10, marginLeft: 10, fontWeight: 500 }}>
          Valuable Impressions
        </h3>
      </div>
      {!Loading && getAllYoutube && getAllYoutube.getAllYoutube.length > 0 && (
        <OwlCarousel
          id='youtube-reviews'
          className='owl-carousel owl-theme'
          {...options}
        >
          {getAllYoutube.getAllYoutube.map((youtube,index) => {
            return <YReviewCard key={index} id={youtube.id} youtube={youtube} />;
          })}
        </OwlCarousel>
      )}
    </Container>
  );
}
