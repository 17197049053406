import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
  Spinner,
  Image,
  Dropdown,
  Modal,
} from "react-bootstrap";
import Moment from "react-moment";
import useSnackbarNotifier from "../../features/shared/hooks/useSnackbarNotifier";
import { useMutation, useQuery } from "@apollo/client";
import { GET_QUERY_ALL_COUPON } from "../../graphql/Query";
import { MUTATION_CREATE_COUPON } from "../../graphql/Mutations";
import { MUTATION_DELETE_COUPON } from "../../graphql/Mutations";
import couponSchema from "../../schemas/admin/couponSchema";
import swal from "sweetalert";

const AddCoupon = () => {
  let count = 1;
  const [couponState, setCouponState] = useState({
    code: "",
    description: "",
    discountType: "percent",
    maxDiscount: 0,
    minOrder: 0,
    discountValue: 0,
    startingDate: "",
    endingDate: "",
  });
  const showSnackbar = useSnackbarNotifier();
  const [validated, setValidated] = useState("");

  const { data: getAllCoupon, loading: CouponLoading } =
    useQuery(GET_QUERY_ALL_COUPON);

  // console.log(getAllCoupon);

  const [createCoupon, { loading: createLoading }] = useMutation(
    MUTATION_CREATE_COUPON,
    {
      refetchQueries: [GET_QUERY_ALL_COUPON],
    }
  );
  const [deleteCoupon, { loading: dltLoading }] = useMutation(
    MUTATION_DELETE_COUPON,
    {
      refetchQueries: [GET_QUERY_ALL_COUPON],
    }
  );

  const handleDelete = (id) => {
    deleteCoupon({
      variables: {
        couponId: id,
      },
    })
      .then(() => {
        swal({
          title: "Success",
          text: "deleted successfully",
          icon: "success",
        });
      })
      .catch((e) => alert(e));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCouponState({
      ...couponState,
      [name]:
        name === "discountValue" ||
        name === "minOrder" ||
        name === "maxDiscount"
          ? Number(value)
          : value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await couponSchema.validate(couponState, { abortEarly: false });
      createCoupon({
        variables: {
          couponInput: {
            code: couponState.code,
            maxDiscount: couponState.maxDiscount,
            description: couponState.description,
            discountValue: couponState.discountValue,
            discountType: couponState.discountType,
            endingDate: couponState.endingDate,
            minOrder: couponState.minOrder,
            startingDate: couponState.startingDate,
          },
        },
      })
        .then(() => {
          swal({
            title: "Success",
            text: "coupon added successfully",
            icon: "success",
          });
          setCouponState({
            code: "",
            description: "",
            maxDiscount: 0,
            discountType: "percent",
            minOrder: 0,
            discountValue: 0,
            startingDate: "",
            endingDate: "",
          });
        })
        .catch((e) => alert(e));
    } catch (validationError) {
      showSnackbar("Please check your inputs and try again.", "error");
    }
  };
  return (
    <>
      <Container style={{ padding: 20 }} fluid>
        <Row>
          <Col md={4}>
            <div style={{ padding: "1rem" }} className="box-shadow">
              <h3 style={{ textAlign: "center" }}>Add Coupon Here</h3>
              <Col>
                {/* <Form.Group as={Col} md="12" controlId="validationCustom02">

                                    <Dropdown style={{ marginTop: "1rem" }} >
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" className='w-100' >
                                            Category
                                        </Dropdown.Toggle>
                                        <span> {couponCategory} </span>
                                        <Dropdown.Menu       popperConfig={{
                                            modifiers: [
                                                {
                                                    name: 'flip',
                                                    enabled: false // Disable flipping to prevent opening in top direction
                                                },
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, 10] // Adjust the offset as needed
                                                    }
                                                }
                                            ]
                                        }}className='w-100'>
                                            <Dropdown.Item onClick={(e) => setCouponCategory("Phones")}>Phones </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => setCouponCategory("Laptops")}>Laptops </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => setCouponCategory("Tablets")}>Tablets </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => setCouponCategory("Smart Watches")}> Smart Watches </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => setCouponCategory("Desktop/Imac")}> Desktop/Imac </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => setCouponCategory("Other Accessories")}> Other Accessories </Dropdown.Item>

                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group> */}
              </Col>
              <Col>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row className="">
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                      <Form.Control
                        required
                        type="text"
                        className="txtinpt"
                        name="code"
                        placeholder="coupon code"
                        onChange={handleChange}
                        value={couponState.code}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="validationCustom01"
                      style={{ marginTop: "0.5rem" }}
                    >
                      <label>Coupon Description</label>
                      <textarea
                        rows={5}
                        name="description"
                        onChange={handleChange}
                        style={{
                          width: "100%",
                          marginTop: "0.5rem",
                          padding: "0.5rem",
                        }}
                      />
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="validationCustom01"
                      style={{ marginTop: "1rem" }}
                    >
                      <label style={{ marginBottom: "0.5rem" }}>
                        discount in (% or ₹)
                      </label>{" "}
                      <br />
                      <select
                        aria-label="Default select example"
                        name="discountType"
                        value={couponState.discountType}
                        onChange={handleChange}
                        style={{ width: "100%", padding: "0.5rem" }}
                      >
                        <option value="percent">percent(%)</option>
                        <option value="ruppee">ruppee(₹)</option>
                      </select>
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Control
                      required
                      type="number"
                      className="txtinpt"
                      name="discountValue"
                      placeholder={
                        couponState.discountType === "percent"
                          ? "Enter percentage"
                          : "Enter amount in ₹"
                      }
                      style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
                      onChange={handleChange}
                      value={couponState.discountValue || ""}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                      <Form.Control
                        required
                        type="number"
                        className="txtinpt"
                        name="minOrder"
                        placeholder={"Minimum Order Value"}
                        style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
                        onChange={handleChange}
                        value={couponState.minOrder || ""}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                      <Form.Control
                        required
                        type="number"
                        className="txtinpt"
                        name="maxDiscount"
                        placeholder={"Maximum Discount in rs"}
                        style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}
                        onChange={handleChange}
                        value={couponState.maxDiscount || ""}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                      <label>Starting Date</label>
                      <input
                        type="date"
                        name="startingDate"
                        value={couponState.startingDate}
                        onChange={handleChange}
                        placeholder="Staring date"
                        style={{
                          width: "100%",
                          padding: "0.4rem",
                          marginTop: "0.5rem",
                        }}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md="12"
                      controlId="validationCustom01"
                      style={{ marginTop: "0.5rem" }}
                    >
                      <label>Ending Date</label>
                      <input
                        type="date"
                        name="endingDate"
                        value={couponState.endingDate}
                        onChange={handleChange}
                        placeholder="Staring date"
                        style={{
                          width: "100%",
                          padding: "0.4rem",
                          marginTop: "0.5rem",
                        }}
                      />
                      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>

                    <Button type="submit" variant="dark" className="mt-5">
                      Add Coupon
                    </Button>
                  </Row>
                </Form>
              </Col>
            </div>
          </Col>
          <Col>
            <Table responsive striped bordered hover>
              <thead className="table-head">
                <tr>
                  <th>S.no.</th>
                  <th>Coupon Code</th>
                  <th>Coupon Description</th>
                  <th>Discount Type</th>
                  <th>Discount</th>
                  <th>Starting Date</th>
                  <th>Ending Date</th>
                  <th>Min. Order</th>
                  <th>Max. Discount</th>
                  <th>Status</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {getAllCoupon &&
                  getAllCoupon?.getAllCoupon?.map((coupon) => {
                    return (
                      <tr>
                        <td> {count++} </td>
                        <td>{coupon?.code}</td>
                        <td>{coupon?.description}</td>
                        <td>{coupon?.discountType}</td>
                        <td> {coupon?.discountValue} </td>
                        <td>
                          {coupon?.startingDate ? (
                            <Moment format="DD/MM/YYYY">
                              {new Date(parseInt(coupon.startingDate))}
                            </Moment>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td>
                          {coupon?.startingDate ? (
                            <Moment format="DD/MM/YYYY">
                              {new Date(parseInt(coupon?.endingDate))}
                            </Moment>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td>{coupon?.minOrder}</td>
                        <td>{coupon?.maxDiscount}</td>
                        <td>{coupon?.status}</td>
                        <td>
                          <Button
                            variant="danger"
                            onClick={() => handleDelete(coupon.id)}
                          >
                            Delete
                          </Button>{" "}
                        </td>
                      </tr>
                    );
                  })}
                <td> </td>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddCoupon;
