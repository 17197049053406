import { Box, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AvailableOffersIcon from '../../../icons/AvailableOffersIcon';
import CustomTypography from '../../../../../muicomponents/shared/CustomTypography';
import useGetAllOffers from '../../../hooks/addToCart/useGetAllOffers';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

const AvailableOffersCart = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('laptop'));
  const { offers, loading, error, refetch } = useGetAllOffers();
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    refetch();
  }, []);

  if (loading) return <Box>Loading...</Box>;
  if (error) return <Box>Error...</Box>;

  const toggleShowAll = () => setShowAll(!showAll);

  const displayedOffers = showAll ? offers : offers.slice(0, 1);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        border: isSmallScreen ? 'none' : '1px solid #eaeaec',
        padding: '0.8rem 1rem',
        borderRadius: '0.3rem',
        backgroundColor: 'white',
      }}
    >
      <Box display={'flex'} flexDirection={'row'} gap={'0.5rem'}>
        <AvailableOffersIcon />
        <CustomTypography variant={'subtitle2'} sx={{ fontWeight: '600' }}>
          Available Offers
        </CustomTypography>
      </Box>
      <ul style={{ paddingLeft: '1.5rem' }}>
        {displayedOffers.map((offer, index) => (
          <li key={index} style={{ listStyleType: 'disc' }}>
            <CustomTypography
              variant={'subtitle2'}
              component='span'
              sx={{
                fontWeight: '300',
                fontSize: '0.8rem',
                lineHeight: 1.2,
                display: 'block',
              }}
            >
              {offer.offerDesc}
            </CustomTypography>
          </li>
        ))}
      </ul>
      {offers.length > 1 && (
        <Box onClick={toggleShowAll}>
          <CustomTypography
            variant={'subtitle2'}
            sx={{
              fontWeight: '600',
              paddingLeft: '1.5rem',
              fontSize: '0.8rem',
              color: 'rgb(6, 155, 170)',
              '&:hover': {
                cursor: 'pointer',
              },
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {showAll ? 'Show Less' : 'Show More'}
            {showAll ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </CustomTypography>
        </Box>
      )}
    </Box>
  );
};

export default AvailableOffersCart;
