import React, { useEffect } from 'react';
import NavbarCartMobile from '../../../../../layout/NavbarCartMobile';
import AddressList from './AddressList';
import useGetAllAddress from '../../../hooks/addAddress/useGetAllAddress';
import { Box } from '@mui/material';

const AddressListPageMobile = () => {
  const userId = localStorage.getItem('userId');
  const { allAddress, loading, error, refetch } = useGetAllAddress(userId);
  useEffect(() => {
    refetch();
  }, []);
  if (loading) {
    return <>Loading....</>;
  }
  if (error) {
    return <>Error:{error.message}</>;
  }
  return (
    <Box>
      <NavbarCartMobile
        title={'Select Address'}
        isAddressMain={false}
        isAddAddressPage={true}
        navigateReturn={'/checkout/address'}
      />
      {allAddress && allAddress.length > 0 ? (
        <AddressList addresses={allAddress} />
      ) : (
        <>No Address Found</>
      )}
    </Box>
  );
};

export default AddressListPageMobile;
