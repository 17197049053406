import React from "react";
import Bag from "../../../../../Images/orderDetail/Shopping bag.png";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import AllProducts from "./AllProducts";
import {
  latestPaymentAtom,
  orderSummaryAtom,
} from "../../../../../store/atoms/orderSummary/orderSummary";
import { useRecoilValue } from "recoil";

const OrderSummary = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const orderSummary = useRecoilValue(orderSummaryAtom);
  const latestPayment = useRecoilValue(latestPaymentAtom);

  // console.log("Latest Payment is:", latestPayment);

  return (
    <>
      {orderSummary && (
        <Box display={"flex"} flexDirection={"column"}>
          <Box display={"flex"} alignItems={"center"} gap={"1rem"}>
            <Box>
              <img
                src={Bag}
                alt={"this is"}
                style={{ width: "50px", height: "50px" }}
              />
            </Box>
            <Box>
              <CustomTypography variant={"h5"} sx={{ fontWeight: "600" }}>
                Your Order
              </CustomTypography>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"column"}>
            <AllProducts />
          </Box>
          {orderSummary.totalAmount > 0 &&
            latestPayment.paymentStatus === "success" &&
            (latestPayment.paymentType === "advance" ||
              latestPayment.paymentType === "convenience") && (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box>
                  <CustomTypography
                    variant={"subtitle1"}
                    sx={{ fontWeight: "600" }}
                  >
                    Remaining Amount (To be paid)
                  </CustomTypography>
                </Box>
                <Box>
                  <CustomTypography varinat={"h6"} sx={{ fontWeight: "600" }}>
                    ₹ {orderSummary.remainingAmount}
                  </CustomTypography>
                </Box>
              </Box>
            )}
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box>
              <CustomTypography variant={"h6"} sx={{ fontWeight: "600" }}>
                Total
              </CustomTypography>
            </Box>
            <Box>
              <CustomTypography varinat={"h6"} sx={{ fontWeight: "600" }}>
                ₹ {orderSummary.totalAmount}
              </CustomTypography>
            </Box>
          </Box>

          {latestPayment &&
            latestPayment.paymentType !== "full" &&
            latestPayment.paymentType !== "remaining" && (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Box>
                  <CustomTypography
                    variant={"subtitle1"}
                    sx={{ fontWeight: "500" }}
                  >
                    {" "}
                    {latestPayment &&
                    latestPayment.paymentType &&
                    latestPayment.paymentType === "convenience"
                      ? "Remaining Amount to be collected at the time of delivery"
                      : latestPayment.paymentType === "advance"
                      ? "Remaining Amount to be collected at the store"
                      : latestPayment.paymentType === "cod"
                      ? "Amount to be collected at the time of delivery"
                      : ""}
                  </CustomTypography>
                </Box>
              </Box>
            )}

          {latestPayment &&
            (latestPayment.paymentType === "convenience" ||
              latestPayment.paymentType === "advance") && (
              <Box
                sx={{
                  backgroundColor: "rgb(6, 155, 170)",
                  padding: "0.5rem",
                  borderRadius: "0.5rem",
                }}
              >
                <CustomTypography
                  variant={"subtitle1"}
                  sx={{ color: "white", textAlign: "center" }}
                >
                  {latestPayment && latestPayment.paymentType === "convenience"
                    ? "A ₹300/- convenience fee has been charged and collected for this order."
                    : latestPayment.paymentType === "advance"
                    ? "A ₹500/- advance fee has been charged and collected for this order."
                    : ""}
                </CustomTypography>
              </Box>
            )}

          <Box sx={{ paddingTop: "0.5rem" }}>
            <CustomTypography varinat={"subtitle1"} sx={{ fontWeight: "600" }}>
              Refer to others and Earn Cash directly to your Bank Account
            </CustomTypography>
            <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "600" }}>
              To Know More
              <span style={{ marginLeft: "1rem", color: "rgb(6, 155, 170)" }}>
                Contact us
              </span>
            </CustomTypography>
            <CustomTypography varinat={"subtitle1"} sx={{ fontWeight: "600" }}>
              Beware of fraudulent calls & messages!
            </CustomTypography>
            <CustomTypography
              variant={"subtitle2"}
              sx={{ color: "#807B7B", fontWeight: "600" }}
            >
              We dont ask for bank OTP/Pin for offers or demand money.
            </CustomTypography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default OrderSummary;
