import React from 'react';
import AccordionComponent from '../AccordionComponent';

const Condition = React.memo(
  ({ conditions, checkedConditions, setTempFilters }) => {
    const handleCheckBoxConditions = (event, conditionName) => {
      if (event.target.checked) {
        setTempFilters((prevSelected) => ({
          ...prevSelected,
          condition: [...prevSelected.condition, conditionName],
        }));
      } else {
        setTempFilters((prevSelected) => ({
          ...prevSelected,
          condition: prevSelected.condition.filter(
            (name) => name !== conditionName
          ),
        }));
      }
    };

    const handleClear = (event) => {
      setTempFilters((prevSelected) => ({
        ...prevSelected,
        condition: [],
      }));
    };
    return (
      <>
        {conditions.length > 0 && (
          <AccordionComponent
            title={'Condition'}
            checkBoxes={conditions}
            checkedFilterState={checkedConditions}
            handleCheckboxChange={handleCheckBoxConditions}
            handleClear={handleClear}
          />
        )}
      </>
    );
  }
);

export default Condition;
