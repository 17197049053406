import React from 'react';
import { useQuery } from '@apollo/client';
import { QUERY_GET_OFFER_BY_CAT } from '../../../graphql/Query';

const useGetAllOffers = () => {
  const { data, loading, error } = useQuery(QUERY_GET_OFFER_BY_CAT);
  return {
    offers: data ? data.getAllOfferByCategory : null,
    loading,
    error,
  };
};

export default useGetAllOffers;
