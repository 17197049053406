import React, { useRef, useEffect } from 'react';
import { Box, Button, Skeleton } from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { useRecoilValue } from 'recoil';
import {
  loadingStateAtom,
  productDetailsAtom,
} from '../../../../store/atoms/productDetails/productAtom';

const ProductScrollableImageList = ({
  setActiveImageIndex,
  activeImageIndex,
}) => {
  const itemRefs = useRef([]);
  const loading = useRecoilValue(loadingStateAtom);
  const product = useRecoilValue(productDetailsAtom);
  useEffect(() => {
    if (
      !loading &&
      product &&
      product.image &&
      activeImageIndex < product.image.length - 2
    ) {
      if (itemRefs.current[activeImageIndex]) {
        itemRefs.current[activeImageIndex].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    } else {
      if (itemRefs.current[activeImageIndex]) {
        itemRefs.current[activeImageIndex].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest',
        });
      }
    }
  }, [activeImageIndex, product]);

  const handleImageClick = (index) => {
    setActiveImageIndex(index);
  };

  const handleTopButtonClick = (event) => {
    event.stopPropagation();
    if (activeImageIndex !== 0) {
      setActiveImageIndex(activeImageIndex - 1);
    }
  };

  const handleBottomButtonClick = (event) => {
    event.stopPropagation();
    if (
      !loading &&
      product &&
      product.image &&
      activeImageIndex !== product.image.length - 1
    ) {
      setActiveImageIndex(activeImageIndex + 1);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: 'fit-content',
          position: 'relative',
        }}
      >
        {activeImageIndex === 0 ? null : (
          <Button
            onClick={handleTopButtonClick}
            variant='outlined'
            sx={{
              position: 'absolute',
              top: 0,
              minWidth: '100%',
              backgroundColor: 'white',
              '&:hover': { backgroundColor: 'white' },
            }}
          >
            <KeyboardArrowUp />
          </Button>
        )}
        <Box
          sx={{
            overflowY: 'auto',
            maxHeight: '23rem',
            display: 'flex',
            gap: '0.5rem',
            flexDirection: 'column',
            width: 'auto',
            '&::-webkit-scrollbar': { display: 'none' },
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none',
          }}
        >
          {loading || !product || !product.image
            ? Array.from(new Array(4)).map((_, index) => (
                <Skeleton
                  key={index}
                  variant='rectangular'
                  animation='wave'
                  width={64}
                  height={72}
                  sx={{
                    borderRadius: '0.5rem',
                  }}
                />
              ))
            : product.image.map((url, index) => (
                <Box
                  key={url}
                  sx={{
                    border:
                      activeImageIndex === index
                        ? '2px solid blue'
                        : '1.5px solid #D3D3D3',
                    borderRadius: '0.5rem',
                    cursor: 'pointer',
                    '&:hover': { border: '2px solid blue' },
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  ref={(el) => (itemRefs.current[index] = el)}
                  onClick={() => handleImageClick(index)}
                >
                  <img
                    src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${url}`}
                    alt={url}
                    loading='lazy'
                    style={{
                      width: '4rem',
                      height: '4.5rem',
                      padding: '0.625rem',
                      borderRadius: '0.5rem',
                      flexShrink: '0',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
              ))}
        </Box>
        {loading || !product || !product.image ? (
          <Skeleton
            key={activeImageIndex}
            variant='rectangular'
            animation='wave'
            width={64}
            height={25}
            sx={{
              marginTop: '0.5rem',
              borderRadius: '0.5rem',
            }}
          />
        ) : (
          product.image.length - 1 !== activeImageIndex && (
            <Button
              variant='outlined'
              onClick={handleBottomButtonClick}
              sx={{
                position: 'absolute',
                bottom: 0,
                minWidth: '100%',
                backgroundColor: 'white',
                '&:hover': { backgroundColor: 'white' },
              }}
            >
              <KeyboardArrowDown />
            </Button>
          )
        )}
      </Box>
    </>
  );
};

export default ProductScrollableImageList;
