import {
  Box,
  TextField,
  InputAdornment,
  Typography,
  Skeleton,
  useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import CustomTypography from '../../../../muicomponents/shared/CustomTypography';
import { LocationOn, CheckCircle } from '@mui/icons-material';
import swal from 'sweetalert';
import { useRecoilValue } from 'recoil';
import { loadingStateAtom } from '../../../../store/atoms/productDetails/productAtom';

const PinCodeCheck = () => {
  const [pincode, setPinCode] = useState('');
  const [isValid, setIsValid] = useState(false);
  const isMobileScreen = useMediaQuery('(max-width:640px)');

  const loading = useRecoilValue(loadingStateAtom);

  const handleChange = (event) => {
    const value = event.target.value;
    setPinCode(value);
    if (value.length === 6) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const handleClick = () => {
    if (pincode.length === 6) {
      swal({
        title: 'Success',
        text: 'We deliver to your pincode.',
        icon: 'success',
      });
    } else {
      swal({
        title: 'Sorry!',
        text: 'Please provide a valid pincode!',
        icon: 'error',
      });
    }
    setPinCode('');
    setIsValid(false);
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      gap={'2rem'}
      sx={{ width: '100%' }}
    >
      <Box>
        {loading ? (
          <>
            <Skeleton
              variant='subtitle1'
              width={100}
              height={30}
              sx={{ borderRadius: '0.5rem' }}
            />
          </>
        ) : (
          <>
            <CustomTypography variant={'h6'} sx={{ fontWeight: '500' }}>
              Delivery
            </CustomTypography>
          </>
        )}
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        {loading ? (
          <>
            <Skeleton
              variant='rectangular'
              width={isMobileScreen ? '100%' : 300}
              height={40}
              sx={{ borderRadius: '0.5rem' }}
            />
          </>
        ) : (
          <>
            <TextField
              size='small'
              variant='standard'
              placeholder='Enter Delivery Pincode'
              value={pincode}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <LocationOn />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <CustomTypography
                      onClick={handleClick}
                      sx={{
                        color: '#0866ff',
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                    >
                      check
                    </CustomTypography>{' '}
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default PinCodeCheck;
