import * as yup from 'yup';

export const addressSchema = yup.object().shape({
  name: yup.string().required('required'),
  contact: yup
    .string()
    .length(10, 'Phone number must be exactly 10 digits')
    .required('Please enter your phone number'),
  alternateContactNo: yup.string().optional(),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is required'),
  address: yup.string().required('required'),
  pinCode: yup
    .string()
    .length(6, 'Enter 6 digit pin code')
    .required('required'),
  landMark: yup.string().optional(),
  cityDistrict: yup.string().required('required'),
  state: yup.string().required('required'),
  gst: yup.string().optional(),
  addressType: yup.string().required('required'),
  isDefault: yup.boolean().optional(),
});
