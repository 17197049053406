import Navigation from '../Navigation';
import Slider from 'react-slick';
import MobileCategory from '../../Images/mobileC.png';
import Price1 from '../../Images/pricecircle/mobile/under1000.png';
import Price2 from '../../Images/pricecircle/mobile/10001to20000.png';
import Price3 from '../../Images/pricecircle/mobile/20001to30000.png';
import Price4 from '../../Images/pricecircle/mobile/300001to50000.png';
import Price5 from '../../Images/pricecircle/mobile/above50000.png';
import Ram4gb from '../../Images/ram1.jpg';
import { Row, Image, Modal, Button, ListGroup, Card } from 'react-bootstrap';
import '../../ComponentsCss/Buying/buyMainPage.css';
import Footer from '../Footer';
import { useEffect, useState } from 'react';
import ConditionModal from '../HomePageModals/phonemodals';
import Unboxed from '../../Images/BrandIcon/mobile/MobileUnboxed.png';
import superb from '../../Images/BrandIcon/mobile/MobileSuper.png';
import good from '../../Images/BrandIcon/mobile/MobileGood.png';
import fair from '../../Images/BrandIcon/mobile/MobileFair.png';
import partially from '../../Images/BrandIcon/mobile/partially.png';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import {
  QUERY_GET_TRENDING_PRODUCTS,
  QUERY_GET_TRENDING_PRODUCTS_BUY_CATEGORY,
} from '../../graphql/Query';
import { QUERY_GET_EXPLORE_BRAND } from '../../graphql/Query';
import './BuyMainPage.css';
import COUPONBANNER from '../../Images/banner/COUPONCODE.JPG';
import PHONEBANNER from '../../Images/banner/phoneBanner.JPG';
import HelmetSection from '../HelmetSection';
import TrendingGadgets from '../TrendingGadgets';
import TrendingGadetsContainer from '../TrendingGadetsContainer';

const BuyMainPage = () => {
  const { category } = useParams();
  const navigate = useNavigate();
  const [showCondition, setShowCondition] = useState();
  const [productCategory, setProductCat] = useState();
  const [smShow, setSmShow] = useState(false);
  const [selectedDesc, setSelectedDesc] = useState(false);
  const [selectedCondition, setSelectedCondition] = useState('');
  const [selectedAcce, setSelectedAcc] = useState('');
  const [selectedWarnty, setSelectedWarnty] = useState('');

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data: getTrendingBuyProduct, loading: Loading } = useQuery(
    QUERY_GET_TRENDING_PRODUCTS
  );
  const { data: getExploreByBrands, loading: ExploreLoading } = useQuery(
    QUERY_GET_EXPLORE_BRAND,
    {
      variables: {
        category: category,
      },
    }
  );

  const { data: getTrendingBuyProductBuyCategory, loading: trendingLoading } =
    useQuery(QUERY_GET_TRENDING_PRODUCTS_BUY_CATEGORY, {
      variables: {
        category: category,
      },
    });


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const [settingsLocal, setSettingsLocal] = useState({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSettingsLocal({
          ...settingsLocal,
          slidesToShow: 3,
        });
      } else {
        setSettingsLocal({
          ...settingsLocal,
          slidesToShow: 3,
        });
      }
    };

    // Set initial settings
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Only run this effect on component mount

  const handleCategoryClick = (condition) => {
    setSelectedCondition(condition);
    setSmShow(true);
    if (condition === 'Unboxed') {
      setSelectedDesc('No Visible Scratches or marks. <br> Next to Brand New.');
      setSelectedAcc('All Original Accessories as mentioned in the Box.');
      setSelectedWarnty('Under Brand Warranty.');
    } else if (condition === 'Superb') {
      setSelectedDesc(
        'Barely visible signs of usage if any. <br> No Dents/Cracks/Chips.'
      );
      setSelectedAcc('Original Accessories may/may not be included.');
      setSelectedWarnty(
        'May/May not be under Brand Warranty. If not under Brand Warranty HelloFi Warranty will be covered'
      );
    } else if (condition === 'Good') {
      setSelectedDesc(
        'Few Visible Scratches.Barely visible dents if any. <br> No Cracks/Chips/Functional defects.'
      );
      setSelectedAcc('Original Accessories may/may not be included.');
      setSelectedWarnty('3 Months Service Warranty from HelloFi.');
    } else if (condition === 'Fair') {
      setSelectedDesc(
        'More visible scratches. <br> Minor Dents/Cracks may be visible. <br>  No Functional defects'
      );
      setSelectedAcc('Original Accessories may/may not be included.');
      setSelectedWarnty(' No Warranty.');
    } else if (condition === 'Partially-Fair') {
      setSelectedDesc(
        'Scratches/Dents/Cracks/ Chips. <br> Some Functional defects.'
      );
      setSelectedAcc('Original Accessories may/may not be included.');
      setSelectedWarnty(' No Warranty.');
    }
    // if (condition) {

    //     navigate('/buying-product-phones', {
    //         state: {
    //             productCondition: condition,
    //             productCategory: category
    //         }
    //     })
    //     setShowCondition(false)
    // }
  };
  const handleContinue = () => {
    navigate(`/buying-products/${category}/${selectedCondition}`, {
      state: {
        productCondition: selectedCondition,
        productCategory: category,
      },
    });
    setSmShow(false);
  };

  const handleChooseByPrice = (price) => {
    navigate(`/buying-product-filter/1/${category}/${price}`, {
      state: {
        section: 1,
        priceFilter: price,
        productCategory: category,
      },
    });
  };
  const handleExploreByBrand = (brand) => {
    navigate(`/buying-product-filter/2/${category}/${brand}`, {
      state: {
        section: 2,
        brandFilter: brand,
        productCategory: category,
      },
    });
  };
  const handleExploreByRam = (ram) => {
    navigate(`/buying-product-filter/3/${category}/${ram}`, {
      state: {
        section: 3,
        ramFilter: ram,
        productCategory: category,
      },
    });
  };
  const handleProductClick = (product) => {
    navigate(`/buying-product-details/${product.id}`, {
      state: {
        product: product,
      },
    });
  };

  return (
    <>
      <HelmetSection
        title={'Explore the Latest Phones: Your Next Upgrade Awaits'}
      />

      <Navigation />
      <div className='Main_page'>
        <div>
          <Image src={PHONEBANNER} style={{ width: '100%' }} />
        </div>
        <div className='p-5 phonebanner-2 bg-white'>
          <Image src={COUPONBANNER} style={{ width: '100%' }} />
        </div>
        <h2 class='choosePrice divider line glow ' contenteditable>
          {' '}
          Choose By Condition{' '}
        </h2>
        <div className='buy-cat-slider'>
          {/* <h1 > Select Category </h1> */}
          <Row>
            <Slider {...settings2}>
              <div
                className='slider-box'
                onClick={() => handleCategoryClick('Unboxed')}
              >
                <Image
                  src={Unboxed}
                  className=' mx-auto d-block BuycategoryImage'
                  fluid
                />
                <h3 className='Sub-heading-buy'>Unboxed</h3>
              </div>
              <div
                className='slider-box'
                onClick={() => handleCategoryClick('Superb')}
              >
                <Image
                  src={superb}
                  className=' mx-auto d-block BuycategoryImage'
                  fluid
                />
                <h3 className='Sub-heading-buy'>Superb</h3>
              </div>

              <div
                className='slider-box'
                onClick={() => handleCategoryClick('Good')}
              >
                <Image
                  src={good}
                  className=' mx-auto d-block BuycategoryImage'
                  fluid
                />
                <h3 className='Sub-heading-buy'>Good</h3>
              </div>
              <div
                className='slider-box'
                onClick={() => handleCategoryClick('Fair')}
              >
                <Image
                  src={fair}
                  className=' mx-auto d-block BuycategoryImage'
                  fluid
                />
                <h3 className='Sub-heading-buy'>Fair</h3>
              </div>
              <div
                className='slider-box'
                onClick={() => handleCategoryClick('Partially-Fair')}
              >
                <Image
                  src={partially}
                  className=' mx-auto d-block BuycategoryImage'
                  fluid
                />
                <h3 className='Sub-heading-buy'>Partially Fair and Usable</h3>
              </div>
            </Slider>
          </Row>

          <h2 class='choosePrice divider line glow ' contenteditable>
            {' '}
            Choose By Price{' '}
          </h2>
          <div className='Choose_price d-flex justify-content-between'>
            <div
              className='Price_card'
              onClick={() => handleChooseByPrice('0-10000')}
            >
              <div className='text-center shadow-sm p-3 mb-5 bg-body-tertiary price-circle'>
                <h3>UNDER</h3>

                <h4>₹ 10000</h4>
              </div>
            </div>
            <div
              className='Price_card'
              onClick={() => handleChooseByPrice('10000-20000')}
            >
              <div className='text-center shadow-sm p-3 mb-5 bg-body-tertiary price-circle'>
                <h4>₹ 10000</h4>
                <h5>to</h5>
                <h4>₹ 20000</h4>
              </div>
            </div>
            <div
              className='Price_card'
              onClick={() => handleChooseByPrice('20000-30000')}
            >
              <div className='text-center shadow-sm p-3 mb-5 bg-body-tertiary price-circle'>
                <h4>₹ 20000</h4>
                <h5>to</h5>
                <h4>₹ 30000</h4>
              </div>
            </div>
            <div
              className='Price_card'
              onClick={() => handleChooseByPrice('30000-50000')}
            >
              <div className='text-center shadow-sm p-3 mb-5 bg-body-tertiary price-circle'>
                <h4>₹ 30000</h4>
                <h5>to</h5>
                <h4>₹ 50000</h4>
              </div>
            </div>
            <div
              className='Price_card'
              onClick={() => handleChooseByPrice('50000-30000000')}
            >
              <div className='text-center shadow-sm p-3 mb-5 bg-body-tertiary price-circle'>
                <h3>ABOVE</h3>

                <h4>₹ 50000</h4>
              </div>
            </div>
          </div>
        </div>
        <h2 class='divider gradient' contenteditable>
          Explore By Brand
        </h2>
        <div className='buy-cat-slider py-5'>
          <Slider {...settings}>
            {getExploreByBrands &&
              getExploreByBrands?.getExploreByBrands?.map((product) => {
                return (
                  <div
                    className='slider-box'
                    onClick={() => handleExploreByBrand(product?.brandName)}
                  >
                    <Image
                      variant='top'
                      src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${product?.brandImage}`}
                      style={{ width: '90%' }}
                    />
                    {/* <h3 className="Sub-heading-buy" > {product?.brandName}</h3> */}
                  </div>
                );
              })}
          </Slider>
        </div>
        <div>
          <div className='exploreram'>
            <h2 class='divider gradient exploreram' contenteditable>
              Explore by RAM
            </h2>
          </div>
          <div className='Choose_price-2 d-flex justify-content-between'>
            <div
              className='Price_card'
              onClick={() => handleExploreByRam('4GB')}
            >
              <div className='text-center shadow-sm p-3 mb-5 bg-body-tertiary price-circle'>
                <h2>4GB</h2>
              </div>
            </div>
            <div
              className='Price_card'
              onClick={() => handleExploreByRam('6GB')}
            >
              <div className='text-center shadow-sm p-3 mb-5 bg-body-tertiary price-circle'>
                <h2>6GB</h2>
              </div>
            </div>
            <div
              className='Price_card'
              onClick={() => handleExploreByRam('8GB')}
            >
              <div className='text-center shadow-sm p-3 mb-5 bg-body-tertiary price-circle'>
                <h2>8GB</h2>
              </div>
            </div>
            <div
              className='Price_card'
              onClick={() => handleExploreByRam('12GB')}
            >
              <div className='text-center shadow-sm p-3 mb-5 bg-body-tertiary price-circle'>
                <h2>12GB</h2>
              </div>
            </div>
            <div
              className='Price_card'
              onClick={() => handleExploreByRam('16GB')}
            >
              <div className='text-center shadow-sm p-3 mb-5 bg-body-tertiary price-circle'>
                <h2>16GB</h2>
              </div>
            </div>
            <div
              className='Price_card'
              onClick={() => handleExploreByRam('32GB')}
            >
              <div className='text-center shadow-sm p-3 mb-5 bg-body-tertiary price-circle'>
                <h2>32GB</h2>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Image
            src='https://assets.tatacliq.com/medias/sys_master/images/33168720756766.jpg'
            style={{ width: '100%', marginTop: '1rem' }}
          />
        </div>

        <h2 class='divider donotcross' contenteditable>
          Trending Products{' '}
        </h2>
        <div>
          {!trendingLoading &&
            getTrendingBuyProductBuyCategory &&
            getTrendingBuyProductBuyCategory.getTrendingBuyProductBuyCategory
              .length > 0 && (
              <>
                <TrendingGadgets
                  products={
                    getTrendingBuyProductBuyCategory.getTrendingBuyProductBuyCategory
                  }
                  isBuy={true}
                />
              </>
            )}
        </div>
        {showCondition ? (
          <ConditionModal
            showCondition={showCondition}
            setShowCondition={setShowCondition}
            productCategory={productCategory}
          />
        ) : (
          ''
        )}
      </div>
      <Modal
        size='lg'
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby='example-modal-sizes-title-sm'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='example-modal-sizes-title-sm'>
            Product Description
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='my-3 d-flex '>
            <div className='fw-bold pmodel'>Condition : </div>
            <div
              className='pmodel-2'
              dangerouslySetInnerHTML={{ __html: selectedDesc }}
            />
          </div>
          <div className='my-3 d-flex '>
            <div className='fw-bold pmodel'> Accessories : </div>
            <div
              className='pmodel-2'
              dangerouslySetInnerHTML={{ __html: selectedAcce }}
            />
          </div>
          <div className='my-3 d-flex'>
            <div className='fw-bold pmodel'> Warranty : </div>
            <div
              className='pmodel-2'
              dangerouslySetInnerHTML={{ __html: selectedWarnty }}
            />
          </div>
          <div className='text-danger fw-bold'>
            {' '}
            Please read Product description and Warranty related terms on
            the Product Page.{' '}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleContinue()} variant='success'>
            {' '}
            Continue{' '}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BuyMainPage;
