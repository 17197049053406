import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Modal,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import { Close } from "@mui/icons-material";
import CouponItem from "./components/CouponItem";

const AddCouponModal = ({ openModal, setModalOpen }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const style = {
    position: "fixed",
    top: isSmallScreen ? "" : "50%",
    left: isSmallScreen ? "0" : "50%",
    bottom: isSmallScreen ? "0" : "",
    height: "450px",
    minWidth: isSmallScreen ? "100%" : "500px",
    transform: isSmallScreen ? "" : "translate(-50%, -50%)",
    backgroundColor: "#f4f4f5",
    border: "none",
    borderRadius: "0.2rem",
    boxShadow: 24,
    p: isSmallScreen ? 2 : 4,
    gap: "1rem",
  };
  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box style={style}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              borderTopRightRadius: "0.2rem",
              borderTopLeftRadius: "0.2rem",
              backgroundColor: "white",
              minHeight: "50px",
              padding: "0 1rem",
            }}
          >
            <Box>
              <CustomTypography
                variant={"subtitle1"}
                sx={{ fontWeight: "600", color: "#535766" }}
              >
                Apply Coupon
              </CustomTypography>
            </Box>
            <Box>
              <Close
                onClick={handleModalClose}
                sx={{
                  color: "#535766",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              overflowY: "auto",
              maxHeight: "350px",
              borderTop: "1px solid #f4f4f5",
            }}
          >
            <CouponItem />
            <CouponItem />
            <CouponItem />
            <CouponItem />
            <CouponItem />
          </Box>

          <Box
            sx={{
              display: "flex",
              position: "fixed",
              backgroundColor: "white",
              width: "100%",
              minHeight: "50px",
              bottom: 0,
              left: 0,
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              padding: "0.5rem",
            }}
          >
            <Box
              flexBasis={"35%"}
              display={"flex"}
              flexDirection={"column"}
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <CustomTypography
                variant={"subtitle1"}
                sx={{ fontSize: "0.85rem", fontWeight: "600" }}
              >
                Maximum Savings
              </CustomTypography>
              <CustomTypography
                variant={"subtitle1"}
                sx={{ fontSize: "0.85rem", fontWeight: "600" }}
              >
                ₹127
              </CustomTypography>
            </Box>
            <Box
              flexBasis={"65%"}
              display={"flex"}
              sx={{ alignItems: "center" }}
            >
              <Button
                sx={{
                  backgroundColor: "rgb(6, 155, 170)",
                  color: "white",
                  width: "100%",
                }}
              >
                Apply
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddCouponModal;
