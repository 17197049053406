import { Box, styled } from '@mui/material';

const ChooseUsBox = styled(Box)({
  background:
    ' radial-gradient(circle at 50% 50%, rgba(63, 61, 61, 1) 0%, rgba(0, 0, 0, 0.93) 50%, rgba(63, 61, 61, 1) 100%)',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0.5rem',
  borderRadius: '0.3rem',
  color: 'white',
});

export default ChooseUsBox;
