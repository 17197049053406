import React, { useEffect } from 'react';
import AccordionComponent from '../AccordionComponent';

const Brand = React.memo(({ brands, checkedBrands, setTempFilters }) => {
  const handleCheckBoxBrands = (event, brandName) => {
    if (event.target.checked) {
      setTempFilters((prevSelected) => ({
        ...prevSelected,
        brand: [...prevSelected.brand, brandName],
      }));
    } else {
      setTempFilters((prevSelected) => ({
        ...prevSelected,
        brand: prevSelected.brand.filter((name) => name !== brandName),
      }));
    }
  };

  const handleClear = (event) => {
    setTempFilters((prevSelected) => ({ ...prevSelected, brand: [] }));
  };
  return (
    <>
      {brands.length > 0 && (
        <AccordionComponent
          title={'Brand'}
          checkBoxes={brands}
          checkedFilterState={checkedBrands}
          handleCheckboxChange={handleCheckBoxBrands}
          handleClear={handleClear}
        />
      )}
    </>
  );
});

export default Brand;
