import React from 'react';
import { useSetRecoilState } from 'recoil';
import { useMutation } from '@apollo/client';
import { MUTATION_DELETE_ADDRESS } from '../../../../graphql/Mutations';
import {
  allAddressAtom,
  allAddressPageBackdrop,
} from '../../../../store/atoms/addAddress/addAddressAtoms';

const useDeleteAddress = () => {
  const setBackdropOpen = useSetRecoilState(allAddressPageBackdrop);
  const setAllAddressAtom = useSetRecoilState(allAddressAtom);
  const [deleteAddress, { loading, error }] = useMutation(
    MUTATION_DELETE_ADDRESS
  );
  const deleteAddressById = async (id, userId) => {
    try {
      setBackdropOpen(true);
      const response = await deleteAddress({
        variables: { id, userId },
      });
      // console.log(response.data.deleteAddress);
      if (response.data.deleteAddress.success) {
        const newDefaultAddressId =
          response.data.deleteAddress.newDefaultAddressId;
        setAllAddressAtom((prevAddresses) => {
          const updatedAddresses = prevAddresses.filter(
            (item) => item.id !== id
          );
          if (newDefaultAddressId) {
            return updatedAddresses.map((address) => ({
              ...address,
              isDefault: address.id === newDefaultAddressId,
            }));
          }
          return updatedAddresses;
        });
      }
      setBackdropOpen(false);
    } catch (err) {
      console.error('Error updating cart item checkout:', err);
      setBackdropOpen(false);
      throw err;
    }
  };
  return { deleteAddressById };
};

export default useDeleteAddress;
