import { ArrowDropDown } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Button,
} from '@mui/material';
import React from 'react';
import CustomTypography from '../../../../muicomponents/shared/CustomTypography';

const AccordionComponent = ({
  title,
  checkBoxes,
  checkedFilterState,
  handleCheckboxChange,
  handleClear,
}) => {
  const handleClearClick = (event) => {
    event.stopPropagation();
    handleClear();
  };

  return (
    <Box>
      <Accordion
        sx={{
          boxShadow: 'none',
          borderBottom: '1px solid #D3D3D3',
        }}
        defaultExpanded
      >
        <AccordionSummary
          expandIcon={<ArrowDropDown />}
          aria-controls={`${title}-content`}
          id={`${title}-header`}
        >
          <CustomTypography sx={{ fontWeight: '600' }}>
            {title}{' '}
            {checkedFilterState && checkedFilterState.length > 0 && (
              <Button
                onClick={handleClearClick}
                style={{
                  fontSize: '12px',
                  padding: '0 0.5rem',
                  borderRadius: '0.2rem',
                  backgroundColor: 'rgb(6, 155, 170)',
                  color: 'white',
                  textTransform: 'none',
                  minWidth: 'auto',
                }}
              >
                clear
              </Button>
            )}
          </CustomTypography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px 16px 16px' }}>
          {checkBoxes &&
            checkBoxes.length > 0 &&
            checkBoxes.map((data, index) => {
              return (
                <Box
                  key={`${data}-${title}-${index}`}
                  sx={{ textTransform: 'uppercase' }}
                >
                  <Checkbox
                    checked={checkedFilterState.includes(data)}
                    sx={{
                      '&.Mui-checked': {
                        color: 'rgb(6, 155, 170)',
                      },
                    }}
                    onChange={(event) => handleCheckboxChange(event, data)}
                  />
                  {data}
                </Box>
              );
            })}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default AccordionComponent;
