import { Box } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../../muicomponents/shared/CustomTypography";

const AppliedCoupon = ({ code, discount }) => {
  return (
    <Box display={"flex"} justifyContent={"space-between"}>
      <CustomTypography variant={"subtitle1"}>{code}</CustomTypography>
      <CustomTypography variant={"subtitle1"}>₹{discount}</CustomTypography>
    </Box>
  );
};

export default AppliedCoupon;
