import { Box, Checkbox } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../../muicomponents/shared/CustomTypography";

const CouponItem = () => {
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "50px",
        backgroundColor: "white",
        padding: "1rem 1rem 1rem 0.3rem",
        marginBottom: "0.1rem",
      }}
    >
      <Box display={"flex"} gap={"0.5rem"}>
        <Box>
          <Checkbox
            checked={true}
            sx={{
              "&.Mui-checked": {
                color: "rgb(6, 155, 170)",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            padding: "0.5rem",
            border: "1.5px dashed rgb(6, 155, 170)",
            borderRadius: "0.1rem",
          }}
        >
          <CustomTypography
            variant={"subtitle2"}
            sx={{ fontWeight: "600", color: "rgb(6, 155, 170)" }}
          >
            HELLOFI123
          </CustomTypography>
        </Box>
      </Box>

      <Box sx={{ marginLeft: "3rem", paddingTop: "0.5rem" }}>
        <CustomTypography
          variant={"subtitle1"}
          sx={{ fontWeight: 600, fontSize: "0.90rem" }}
        >
          Save ₹2000
        </CustomTypography>
        <CustomTypography variant={"subtitle1"} sx={{ fontSize: "0.85rem" }}>
          ₹300 off on minimum purchase of ₹850 .
        </CustomTypography>
        <CustomTypography variant={"subtitle1"} sx={{ fontSize: "0.85rem" }}>
          Expires on: 31st October 2024 04:19 PM
        </CustomTypography>
      </Box>
    </Box>
  );
};

export default CouponItem;
