import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Rating, styled, useMediaQuery } from '@mui/material';
import '../ComponentsCss/greview.css';

const StyledTypography = styled(Typography)({
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 7,
  lineHeight: '1.5em',
  maxHeight: '10.5em',
  minHeight: '10.5em',
});

export default function GReviewCard({ review }) {
  const starRatingMapping = {
    FIVE: 5,
    FOUR: 4,
    THREE: 3,
    TWO: 2,
    ONE: 1,
  };

  const reviewRating = starRatingMapping[review.starRating];
  const isSmallScreen = useMediaQuery('(max-width:576px)');

  const commentStyle = {
    lineHeight: isSmallScreen ? '1em' : '1.5em',
    fontSize: isSmallScreen ? '0.8rem' : '1rem',
    maxHeight: isSmallScreen ? '7em' : '10.5em',
    minHeight: isSmallScreen ? '7em' : '10.5em',
  };

  const nameStyle = {
    fontFamily: 'Poppins',
    fontWeight: '300',
    fontSize: isSmallScreen ? '1rem' : '1.25rem',
  };

  return (
    <Box sx={{ minWidth: 200 }}>
      <Card variant='outlined' style={{ borderRadius: '1.5rem' }}>
        <CardContent className='cared-content'>
          <Box className='card-wrapper'>
            <Box>
              <StyledTypography
                className='review'
                color='text.secondary'
                gutterBottom
                style={commentStyle}
              >
                {review.comment}
              </StyledTypography>
            </Box>
            <Box>
              <Rating name='simple-controlled' value={reviewRating} readOnly />
            </Box>
            <Box>
              <Typography sx={nameStyle} variant='h5'>
                {review.displayName}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
