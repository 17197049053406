import { Box } from '@mui/material';
import React from 'react';
import EditAddressMobile from '../components/addAddressMobile/editAddress/EditAddressMobile';

const EditAddressPageMobile = () => {
  return (
    <Box>
      <EditAddressMobile />
    </Box>
  );
};

export default EditAddressPageMobile;
