import { Box } from '@mui/material';
import React from 'react';

const ProductDetailsCard = ({ children, sx, ...props }) => {
  return (
    <Box
      sx={{
        padding: '1rem 0.5rem',
        borderBottom: '0.5px solid #D3D3D3',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default ProductDetailsCard;
