import React from 'react';
import NavbarCartMobile from '../../../../../layout/NavbarCartMobile';
import Form from '../../addAddress/addressForm/Form';
import { useLocation } from 'react-router-dom';

const EditAddressMobile = () => {
  const location = useLocation();
  const { address } = location.state || {};
  return (
    <>
      <NavbarCartMobile
        title={'Edit Address'}
        isAddressMain={false}
        isAddAddressPage={true}
        navigateReturn={'/checkout/address/list'}
      />
      <Form isMobile={true} address={address} isEdit={true} />
    </>
  );
};

export default EditAddressMobile;
