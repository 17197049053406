// GoogleAuthButton.js
import React from 'react';

const GoogleAuthButton = () => {
  const clientId =
    '4031922216-bl38r2941p0din86an91n6t6j5tdkdbn.apps.googleusercontent.com';
  const redirectUri = 'http://localhost:4000/oauth2callback';
  const scopes = 'https://www.googleapis.com/auth/business.manage';

  const handleSignIn = () => {
    const authUrl = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${encodeURIComponent(
      scopes
    )}&access_type=offline`;

    window.location.href = authUrl;
  };

  return <button onClick={handleSignIn}>Sign in with Google</button>;
};

export default GoogleAuthButton;
