import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import {
  allAddressAtom,
  selectedAddressIdState,
} from "../../../../../store/atoms/addAddress/addAddressAtoms";
import { cartItems } from "../../../../../store/atoms/addToCart/addToCart";
import useAddAddressId from "../../../hooks/addAddress/useAddAddressId";
import AppliedCoupon from "../../addToCart/modals/components/AppliedCoupon";
import AppliedCouponModal from "../../addToCart/modals/AppliedCouponModal";
import { Info } from "@mui/icons-material";

const PriceDetails = ({ isPaymentPage = false }) => {
  const allAddress = useRecoilValue(allAddressAtom);
  const navigate = useNavigate();
  const addAddressIdToCart = useAddAddressId();
  const cartData = useRecoilValue(cartItems);
  const [modalOpen, setModalOpen] = useState(false);

  const handleNavigate = () => {
    addAddressIdToCart();
    navigate("/checkout/payment");
  };

  return (
    <>
      {cartData !== null && (
        <>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={"0.6rem"}
            padding={"1rem  0 0 1rem"}
          >
            <Box margin={"1rem 0 0.4rem"}>
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontWeight: "600", color: "#535766" }}
              >
                PRICE DETAILS {`(${cartData.count}) Items`}
              </CustomTypography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontSize: "0.85rem", fontWeight: "400" }}
              >
                Total MRP
              </CustomTypography>
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontWeight: "500" }}
              >
                ₹{cartData.totalMRP}
              </CustomTypography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontSize: "0.86rem", fontWeight: "400" }}
              >
                Discount on MRP
              </CustomTypography>
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontWeight: "500", color: "#03a685" }}
              >
                -₹{cartData.discountOnMRP}
              </CustomTypography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontSize: "0.86rem", fontWeight: "400" }}
              >
                Coupon Discount{" "}
                {cartData.coupon !== null && (
                  <Info
                    onClick={() => setModalOpen(true)}
                    sx={{
                      color: "black",
                      backgroundColor: "white",
                      fontSize: "1rem",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  />
                )}
              </CustomTypography>
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontWeight: "500" }}
              >
                ₹{cartData.couponDiscount === 0 ? "0" : cartData.couponDiscount}{" "}
              </CustomTypography>
            </Box>

            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontSize: "0.86rem", fontWeight: "400" }}
              >
                Platform fee
              </CustomTypography>
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontWeight: "500", color: "#03a685" }}
              >
                Free
              </CustomTypography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontSize: "0.86rem", fontWeight: "400" }}
              >
                Delivery Charges
              </CustomTypography>
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontWeight: "500", color: "#03a685" }}
              >
                Free
              </CustomTypography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              sx={{ borderTop: "1px solid #eaeaec", paddingTop: "1rem" }}
            >
              <CustomTypography
                variant={"subtitle1"}
                sx={{ fontWeight: "600" }}
              >
                Total Amount
              </CustomTypography>
              <CustomTypography
                variant={"subtitle1"}
                sx={{ fontWeight: "600" }}
              >
                ₹{cartData.totalAmount}
              </CustomTypography>
            </Box>
            {allAddress.length > 0 && !isPaymentPage && (
              <Box width={"100%"}>
                <Button
                  variant="outlined"
                  onClick={handleNavigate}
                  sx={{
                    width: "100%",
                    color: "white",
                    borderRadius: "0.2rem",
                    padding: "0.5rem",
                    backgroundColor: "rgb(6, 155, 170)",
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "rgb(6, 155, 170)",
                    },
                  }}
                >
                  CONTINUE
                </Button>
              </Box>
            )}
          </Box>
          {cartData.coupon !== null && (
            <AppliedCouponModal
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
            />
          )}
        </>
      )}
    </>
  );
};

export default PriceDetails;
