import { useQuery } from "@apollo/client";
import { useEffect, useCallback } from "react";
import { cartItems } from "../../../../store/atoms/addToCart/addToCart";
import { useRecoilState } from "recoil";
import { QUERY_GET_CART_ITEM_BY_ID } from "../../../../graphql/Query";

const useGetCartItems = (userId) => {
  const [cartData, setCartData] = useRecoilState(cartItems);

  const { data, loading, error, refetch } = useQuery(
    QUERY_GET_CART_ITEM_BY_ID,
    {
      variables: { userId },
    }
  );

  const sortCartItems = useCallback((items) => {
    return [...items].sort((a, b) => {
      if (a.isCheckedForCheckout === b.isCheckedForCheckout) {
        return 0;
      }
      return a.isCheckedForCheckout ? -1 : 1;
    });
  }, []);

  useEffect(() => {
    if (data && data.getCartItemsByUserId) {
      const sortedItems = sortCartItems(data.getCartItemsByUserId.items);
      setCartData({
        ...data.getCartItemsByUserId,
        items: sortedItems,
      });
    }
  }, [data, setCartData, sortCartItems]);

  return {
    cartData,
    loading,
    error,
    refetch,
  };
};

export default useGetCartItems;
