import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from 'recoil';
import { Box, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import {
  categoryState,
  selectedFilterState,
  sortState,
} from '../../../../../../store/atoms/productListing/productListing';
import { productsSelector } from '../../../../../../store/selectors/productListing/productListing';
import ProductSmall from './ProductSmall';
import CustomTypography from '../../../../../../muicomponents/shared/CustomTypography';

const ProductCardsSmall = () => {
  let { category } = useParams();
  const theme = useTheme();
  const [filters, setFilters] = useRecoilState(selectedFilterState);
  const categoryAtom = useRecoilValue(categoryState);
  const [allProducts, setAllProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const setSortState = useSetRecoilState(sortState);
  const sortCriteria = useRecoilValue(sortState);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  if (category === 'Desktop-Imac') {
    category = 'Desktop/Imac';
  }

  const productsLoadable = useRecoilValueLoadable(
    productsSelector({ category, filters, page, limit, sortCriteria })
  );

  const observer = useRef();
  const lastProductElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    if (category != categoryAtom) {
      setFilters({
        brand: [],
        condition: [],
        price: ['0', '250000'],
        storage: [],
        ram: [],
        connectivity: [],
        warranty: [],
      });
      setSortState({
        field: '',
        order: '',
      });
    }
  }, []);
  useEffect(() => {
    setPage(1);
    setHasMore(true);
  }, [filters, category, sortCriteria]);

  useEffect(() => {
    if (productsLoadable.state === 'hasValue') {
      const newProducts = productsLoadable.contents.products;
      if (page == 1) {
        setAllProducts([...newProducts]);
      } else {
        setAllProducts((prev) => [...prev, ...newProducts]);
      }
      setLoading(false);
      if (newProducts.length < limit) {
        setHasMore(false);
      }
    } else if (productsLoadable.state === 'loading') {
      setLoading(true);
    }
  }, [productsLoadable, limit]);

  if (productsLoadable.state === 'loading' && page === 1) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'1rem'}
        sx={{ width: '100%' }}
      >
        {allProducts.map((product, index) => (
          <ProductSmall
            ref={
              allProducts.length === index + 1 ? lastProductElementRef : null
            }
            key={`${product.id}-${index}`}
            product={product}
          />
        ))}
      </Box>
      {loading && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          width='100%'
          padding='1rem'
        >
          <CircularProgress />
        </Box>
      )}
      {!hasMore && allProducts.length > 0 && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          width='100%'
          padding='1rem'
        >
          <span>No more products</span>
        </Box>
      )}
    </Box>
  );
};

export default ProductCardsSmall;
