import { useQuery } from '@apollo/client';
import { QUERY_GET_ALL_OFFER } from '../../../../graphql/Query';

const useGetAllOffers = () => {
  const { data, loading, error, refetch } = useQuery(QUERY_GET_ALL_OFFER);

  const offers = data?.getAllOffer || [];

  return { offers, loading, error, refetch };
};

export default useGetAllOffers;
