import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Modal,
  Slide,
  IconButton,
} from '@mui/material';
import CustomTypography from '../../../../../muicomponents/shared/CustomTypography';
import { Close } from '@mui/icons-material';
import { useRecoilState } from 'recoil';
import { sortState } from '../../../../../store/atoms/productListing/productListing';

const SortModal = ({ sortModalOpen, setSortModalOpen }) => {
  const [sortCriteria, setSortCriteria] = useRecoilState(sortState);

  const handleModalClose = () => {
    setSortModalOpen(false);
  };

  const handleSortChange = (event) => {
    const { value } = event.target;
    let field = '';
    let order = '';
    if (value === 'price_asc') {
      field = 'productPrice';
      order = 'asc';
    } else if (value === 'price_desc') {
      field = 'productPrice';
      order = 'desc';
    }
    setSortCriteria({ field, order });
    handleModalClose();
  };

  const isChecked = (value) => {
    if (value === 'price_asc') {
      return (
        sortCriteria.field === 'productPrice' && sortCriteria.order === 'asc'
      );
    }
    if (value === 'price_desc') {
      return (
        sortCriteria.field === 'productPrice' && sortCriteria.order === 'desc'
      );
    }
    return false;
  };

  const customRadioStyle = {
    '&.Mui-checked': {
      color: 'rgb(6, 155, 170)',
    },
    '& .MuiSvgIcon-root': {
      fontSize: 28,
    },
    '&:hover': {
      backgroundColor: 'rgba(6, 155, 170, 0.04)',
    },
  };

  return (
    <Modal open={sortModalOpen} onClose={handleModalClose} closeAfterTransition>
      <Slide direction='up' in={sortModalOpen} mountOnEnter unmountOnExit>
        <Box
          sx={{
            position: 'fixed',
            border: 'none',
            outline: 'none',
            bottom: '0',
            left: '0',
            width: '100%',
            height: 'auto',
            minHeight: '30%',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            sx={{ padding: '1rem', borderBottom: '1px solid #D3D3D3' }}
          >
            <Box>
              <CustomTypography variant='h6' sx={{ fontWeight: '600' }}>
                Sort By:
              </CustomTypography>
            </Box>
            <Box>
              <IconButton onClick={handleModalClose}>
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Box display={'flex'} flexDirection={'column'} padding={'1rem'}>
            <FormControl component='fieldset'>
              <RadioGroup
                aria-label='price-sort'
                name='price-sort'
                onChange={handleSortChange}
              >
                <FormControlLabel
                  value='price_asc'
                  control={<Radio sx={customRadioStyle} />}
                  label='Price - Low to High'
                  checked={isChecked('price_asc')}
                />
                <FormControlLabel
                  value='price_desc'
                  control={<Radio sx={customRadioStyle} />}
                  label='Price - High to Low'
                  checked={isChecked('price_desc')}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

export default SortModal;
