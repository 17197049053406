import React from 'react';
import {
  initialFilters,
  selectedFilterState,
} from '../../../../../store/atoms/productListing/productListing';
import { useRecoilState, useRecoilValue } from 'recoil';
import AccordionComponent from '../AccordionComponent';

const Ram = React.memo(({ ram, checkedRam, setTempFilters }) => {
  const handleCheckBoxConditions = (event, ramName) => {
    if (event.target.checked) {
      setTempFilters((prevSelected) => ({
        ...prevSelected,
        ram: [...prevSelected.ram, ramName],
      }));
    } else {
      setTempFilters((prevSelected) => ({
        ...prevSelected,
        ram: prevSelected.ram.filter((name) => name !== ramName),
      }));
    }
  };

  const handleClear = (event) => {
    setTempFilters((prevSelected) => ({
      ...prevSelected,
      ram: [],
    }));
  };
  return (
    <>
      {ram.length > 0 && (
        <AccordionComponent
          title={'Ram'}
          checkBoxes={ram}
          checkedFilterState={checkedRam}
          handleCheckboxChange={handleCheckBoxConditions}
          handleClear={handleClear}
        />
      )}
    </>
  );
});

export default Ram;
