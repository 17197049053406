import React, { useEffect, useCallback } from 'react';
import { GET_ADDRESS_BY_ID } from '../../../../graphql/Query';
import { useQuery } from '@apollo/client';
import { selectedAddress } from '../../../../store/atoms/payment/paymentAtom';
import { useRecoilState } from 'recoil';

const useGetAddressById = (addressId) => {
  const [selectedAddressState, setSelectedAddressState] =
    useRecoilState(selectedAddress);
  const { data, loading, error, refetch } = useQuery(GET_ADDRESS_BY_ID, {
    variables: { addressId },
    fetchPolicy: 'cache-and-network',
    skip: !addressId,
  });

  const memoizedSetSelectedAddressState = useCallback(
    (newState) => {
      setSelectedAddressState(newState);
    },
    [setSelectedAddressState]
  );

  useEffect(() => {
    if (data) {
      memoizedSetSelectedAddressState(data.getAddressByID);
    }
  }, [data, memoizedSetSelectedAddressState]);

  return {
    selectedAddressState,
    loading,
    error,
    refetch,
  };
};

export default useGetAddressById;
