import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import ResponsiveContainer from "../../../muicomponents/shared/ResponsiveConatiner";
import NavbarCart from "../../../layout/NavbarCart";
import Payment from "../components/payment/Payment";
import NavbarCartMobile from "../../../layout/NavbarCartMobile";

const PaymentPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
      }}
    >
      {isSmallScreen ? (
        <Box position={"sticky"} sx={{ top: "0", zIndex: 100 }}>
          <NavbarCartMobile
            title={"Payment"}
            step={"3"}
            navigateReturn={"/checkout/address"}
          />
        </Box>
      ) : (
        <NavbarCart />
      )}
      <ResponsiveContainer
        sx={{
          padding: isSmallScreen ? "0" : "0px 1rem",
          margin: "auto",
          maxWidth: "1060px",
          minHeight: "400px",
          backgroundColor: isSmallScreen ? "#f4f4f5" : "white",
        }}
      >
        <Payment />
      </ResponsiveContainer>
    </Box>
  );
};

export default PaymentPage;
