import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { QUERY_GET_ALL_ADDRESS } from '../../../../graphql/Query';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  allAddressAtom,
  selectedAddressIdState,
} from '../../../../store/atoms/addAddress/addAddressAtoms';

const useGetAllAddress = (userId) => {
  const [allAddress, setAllAddress] = useRecoilState(allAddressAtom);
  const [selectedIdState, setSelectedIdState] = useRecoilState(
    selectedAddressIdState
  );
  const { data, loading, error, refetch } = useQuery(QUERY_GET_ALL_ADDRESS, {
    variables: { userId },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data) {
      setAllAddress(data.getAllAddresses.address);
      if (data.getAllAddresses.address.length > 0 && !selectedIdState) {
        const defaultAddress = data.getAllAddresses.address.find(
          (address) => address.isDefault
        );
        setSelectedIdState(defaultAddress.id);
      }
    }
  }, [data, setAllAddress]);

  return {
    allAddress,
    loading,
    error,
    refetch,
    addressCount: data?.getAllAddresses.count,
  };
};

export default useGetAllAddress;
