import { atom } from 'recoil';

export const allAddressAtom = atom({
  key: 'allAddressAtom',
  default: [],
});

export const allAddressPageBackdrop = atom({
  key: 'allAddressPageBackdrop',
  default: false,
});

export const selectedAddressIdState = atom({
  key: 'selectedAddressIdState',
  default: null,
});

export const defaultAddressMobile = atom({
  key: 'defaultAddressMobile',
  default: null,
});

export const countOfDefaultAddressMobile = atom({
  key: 'countOfDefaultAddressMobile',
  default: undefined,
});
