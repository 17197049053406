import { useMediaQuery } from "@mui/material";
import { QUERY_GET_FILTERED_BUY_PRODUCT } from "../../../../../graphql/Query";
import RelatedProductContainer from "../../productDetails/RelatedProductContainer";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import "../../../css/style.css";
import { Box } from "@mui/material";
import { useQuery } from "@apollo/client";

const YouMayAlsoLike = () => {
  const isSmallScreen = useMediaQuery("(max-width:767px)");

  const { data, loading, error } = useQuery(QUERY_GET_FILTERED_BUY_PRODUCT, {
    variables: {
      filterFileld: "productCategory",
      filterVal: "Laptops",
    },
  });
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box>
        <CustomTypography
          variant="subtitle1"
          sx={{
            fontOpticalSizing: "auto",
            fontWeight: "600",
            fontSize: isSmallScreen ? "20px" : "24px",
          }}
        >
          You may also like
        </CustomTypography>
      </Box>
      <RelatedProductContainer
        products={data.getFilterBuyProduct}
        length={data.getFilterBuyProduct.length}
      />
    </Box>
  );
};

export default YouMayAlsoLike;
