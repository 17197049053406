import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import DefaultAddressMobile from "./DefaultAddressMobile";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import { useNavigate } from "react-router-dom";
import NonDefaultAddressMobile from "./NonDefaultAddressMobile";

const AddressList = ({ addresses }) => {
  const defaultAddress = addresses.find((address) => address.isDefault);
  const navigate = useNavigate();
  const nonDefaultAddress = addresses.filter((address) => !address.isDefault);
  useEffect(() => {});
  return (
    <Box
      sx={{
        backgroundColor: "#f4f4f5",
        margin: "0 0 1rem 0",
      }}
    >
      <Box sx={{ padding: "1rem", backgroundColor: "white" }}>
        <Button
          onClick={() => navigate("/checkout/address/add")}
          variant={"contained"}
          sx={{
            width: "100%",
            color: "black",
            borderRadius: "0.2rem",
            backgroundColor: "white",
            border: "1px solid black",
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "white",
            },
          }}
        >
          <CustomTypography
            variant={"subtitle1"}
            fontWeight="600"
            sx={{ fontSize: "0.9rem" }}
          >
            Add new Address
          </CustomTypography>
        </Button>
      </Box>
      <Box sx={{ overflowX: "hidden" }}>
        <DefaultAddressMobile defaultAddress={defaultAddress} />
        <NonDefaultAddressMobile addresses={nonDefaultAddress} />
      </Box>
      <Box
        width={"100%"}
        sx={{
          position: "fixed",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "white",
          padding: "1rem",
          zIndex: 1000,
        }}
      >
        <Button
          variant="outlined"
          onClick={() => navigate("/checkout/address")}
          sx={{
            width: "100%",
            color: "white",
            borderRadius: "0.2rem",
            padding: "0.5rem",
            backgroundColor: "rgb(6, 155, 170)",
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "rgb(6, 155, 170)",
            },
          }}
        >
          <CustomTypography variant="subtitle1" sx={{ fontWeight: "600" }}>
            Continue
          </CustomTypography>
        </Button>
      </Box>
    </Box>
  );
};

export default AddressList;
