import { Box } from '@mui/material';
import React, { Children } from 'react';

const AssuredCard = ({ children, sx, ...props }) => {
  return (
    <Box
      sx={{
        borderRadius: '1rem',
        padding: '2rem',
        border: '0.5px solid black',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export default AssuredCard;
