import '../../css/style.css';
import React from 'react';
import { useMediaQuery } from '@mui/material';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import { Box } from '@mui/material';

const ImageZoomSmall = ({
  imageUrl,
  zoomImageUrl,
  isSmallScreen,
  handlers,
}) => {
  return (
    <Box
      {...(isSmallScreen ? handlers : {})}
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: isSmallScreen ? '100%' : '65%',
        height: isSmallScreen ? '75%' : '100%',
        overflow: 'hidden',
        padding: isSmallScreen ? '0.5rem' : '0.5rem 2rem 0.5rem 0.5rem',
        borderRight: isSmallScreen ? 'none' : '1px solid black',
      }}
    >
      <InnerImageZoom
        src={imageUrl}
        zoomSrc={zoomImageUrl}
        zoomType='click'
        moveType='drag'
        zoomScale={0.7}
        fadeDuration={150}
        fullscreenOnMobile={true}
        mobileBreakpoint={1024}
        hideHint={true}
        className='imagezoom'
      />
    </Box>
  );
};

export default ImageZoomSmall;
