import { Box } from '@mui/material';
import React from 'react';
import ExperienceContainer from '../../muiComponents/ExperienceContainer';
import CustomCard from '../../muiComponents/CustomCard';
import YoutubeVideoLink from './YoutubeVideoLink';
import ProductDescriptionList from './ProductDescriptionList';

const ProductExperience = () => {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={'1rem'}>
      <YoutubeVideoLink />
      <ProductDescriptionList />
    </Box>
  );
};

export default ProductExperience;
