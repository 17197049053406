import { atom } from "recoil";

export const orderSummaryAtom = atom({
  key: "orderSummaryAtom",
  default: null,
});

export const activeProductAtom = atom({
  key: "activeProductAtom",
  default: null,
});

export const latestPaymentAtom = atom({
  key: "latestPaymentAtom",
  default: null,
});
