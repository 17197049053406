import { Box, useMediaQuery } from '@mui/material';
import React from 'react';
import CustomTypography from '../../../../../../muicomponents/shared/CustomTypography';
import { useTheme } from '@emotion/react';

const Discounts = ({ isSmall = false }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('tablet'));
  const isxsSmallScreen = useMediaQuery(theme.breakpoints.down('351'));
  return (
    <Box
      sx={{ padding: isSmall ? '0' : '0 1rem' }}
      display={'flex'}
      flexDirection={'row'}
    >
      <CustomTypography
        variant={'subtitle2'}
        fontSize={isxsSmallScreen ? '0.72rem' : isSmallScreen ? '0.75rem' : ''}
        fontWeight={'500'}
        sx={{ color: 'rgb(119, 119, 119)' }}
      >
        No Cost EMI/Easy EMI <br />
        Instant Discount Available
      </CustomTypography>
    </Box>
  );
};

export default Discounts;
