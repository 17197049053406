import { Box } from "@mui/material";
import React from "react";
import NavbarCartMobile from "../../../layout/NavbarCartMobile";
import AddAddressMobile from "../components/addAddressMobile/AddAddressMobile";

const AddToAddressMobilePage = () => {
  return (
    <Box sx={{ minHeight: "100vh", minWidth: "300px" }}>
      <NavbarCartMobile
        title={"address"}
        step={"2"}
        navigateReturn={"/checkout/cart"}
      />
      <AddAddressMobile />
    </Box>
  );
};

export default AddToAddressMobilePage;
