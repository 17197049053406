import { Box } from '@mui/material';
import React from 'react';
import CustomTypography from '../../../../../../muicomponents/shared/CustomTypography';
import DiscountFunction from '../../../../../../utils/DiscountFunction';

const OfferBadge = ({ price, mrp }) => {
  return (
    <Box
      position={'absolute'}
      display={'flex'}
      flexDirection={'row'}
      zIndex={'100'}
      gap={'0.3rem'}
      sx={{
        padding: '0.3rem 0.4rem ',
        borderTopLeftRadius: '0.5rem',
        borderBottomRightRadius: '0.5rem',
        background: '#fada5f',
      }}
    >
      <CustomTypography variant={'subtitle2'} sx={{ fontWeight: '700' }}>
        {DiscountFunction(price, mrp)}%
      </CustomTypography>
      <CustomTypography variant={'subtitle2'} sx={{ fontWeight: '600' }}>
        OFF
      </CustomTypography>
    </Box>
  );
};

export default OfferBadge;
