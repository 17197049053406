import { atom } from "recoil";

export const cartItems = atom({
  key: "cartItems",
  default: null,
});

export const cartPageBackdrop = atom({
  key: "cartPageBackdrop",
  default: false,
});
