import { Box, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import CustomCard from '../../muiComponents/CustomCard';
import Youtube from '../../icons/Youtube';
import CustomTypography from '../../../../muicomponents/shared/CustomTypography';
import { ArrowCircleRight, Headphones } from '@mui/icons-material';
import { useRecoilValue } from 'recoil';
import {
  loadingStateAtom,
  productAtom,
  productDetailsAtom,
} from '../../../../store/atoms/productDetails/productAtom';

const YoutubeVideoLink = () => {
  const product = useRecoilValue(productDetailsAtom);
  const loading = useRecoilValue(loadingStateAtom);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('desktop'));
  const isBetweenLaptopAndDesktop = useMediaQuery(
    theme.breakpoints.between(768, 'desktop')
  );
  const phoneNumber = '+918150835583';
  const whatsappURL = `https://wa.me/${phoneNumber}?action=video`;
  if (loading) {
    return <p>Loading....</p>;
  }
  return (
    <Box
      display={'flex'}
      flexDirection={isSmallScreen ? 'column' : 'row'}
      padding={
        isBetweenLaptopAndDesktop ? '0' : isSmallScreen ? '0 0.8rem' : ''
      }
      gap={'1rem'}
    >
      <CustomCard
        onClick={() => window.open(product.liveLink, '_blank')}
        flexBasis={isSmallScreen ? '100%' : '50%'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        gap={'0.5rem'}
        sx={{
          maxWidth: isSmallScreen ? '100%' : '50%',

          backgroundColor: 'white',
          minHeight: '4rem',
          '&:hover': isSmallScreen
            ? {}
            : {
                color: 'rgb(6, 155, 170)',
                cursor: 'pointer',
              },
        }}
      >
        <Box>
          <Youtube />
        </Box>
        <Box>
          <CustomTypography sx={{ fontWeight: '500' }}>
            Real Time Product Video
          </CustomTypography>
        </Box>
        <Box
          sx={{
            '&:hover': {
              color: 'rgb(6, 155, 170)',
            },
          }}
        >
          <ArrowCircleRight />
        </Box>
      </CustomCard>
      <CustomCard
        onClick={() => window.open(whatsappURL, '_blank')}
        flexBasis={isSmallScreen ? '100%' : '50%'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        gap={'0.5rem'}
        sx={{
          maxWidth: isSmallScreen ? '100%' : '50%',

          backgroundColor: 'white',
          minHeight: '4rem',
          '&:hover': isSmallScreen
            ? {}
            : {
                color: 'rgb(6, 155, 170)',
                cursor: 'pointer',
              },
        }}
      >
        <Box>
          <Headphones />
        </Box>
        <Box>
          <CustomTypography sx={{ fontWeight: '500' }}>
            Ask us on video call
          </CustomTypography>
        </Box>
        <Box>
          <ArrowCircleRight />
        </Box>
      </CustomCard>
    </Box>
  );
};

export default YoutubeVideoLink;
