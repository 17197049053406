import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, CircularProgress } from "@mui/material";
import useGetDefaultAddress from "../../hooks/addAddressMobile/useGetDefaultAddress";
import AddressMobile from "./chekoutAddress/AddressMobile";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import useGetAllAddress from "../../hooks/addAddress/useGetAllAddress";
import { useRecoilValue } from "recoil";
import { selectedAddressIdState } from "../../../../store/atoms/addAddress/addAddressAtoms";
import useAddAddressId from "../../hooks/addAddress/useAddAddressId";
import useGetCartItems from "../../hooks/addToCart/useGetCartItems";

const AddAddressMobile = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const selectedAddressId = useRecoilValue(selectedAddressIdState);
  const [selectedAddressMobile, setSelectedAddressMobile] = useState(null);
  const {
    cartData,
    loading: cartDataLoading,
    error: cartDataError,
    refetch: cartDataRefetch,
  } = useGetCartItems(userId);
  const { allAddress, loading, error, refetch, addressCount } =
    useGetAllAddress(userId);
  const addAddressIdToCart = useAddAddressId();
  const handleNavigate = () => {
    addAddressIdToCart();
    navigate("/checkout/payment");
  };

  useEffect(() => {
    cartDataRefetch();
    if (cartData && cartData.items.length === 0) {
      navigate("/checkout/cart");
    }
  }, [cartData, cartDataRefetch]);

  useEffect(() => {
    if (addressCount === 0) {
      navigate("/checkout/address/add", { replace: true });
    } else if (allAddress.length > 0) {
      const address = allAddress.find(
        (address) => selectedAddressId === address.id
      );
      setSelectedAddressMobile(address);
    }
  }, [addressCount, allAddress]);

  if (loading && cartDataLoading) return <CircularProgress />;
  if (error && cartDataError) return <p>Error: {error.message}</p>;

  return (
    <Box>
      {selectedAddressMobile !== null ? (
        <>
          <AddressMobile selectedAddress={selectedAddressMobile} />
        </>
      ) : (
        <p>No default address found.</p>
      )}
      <Box
        width={"100%"}
        sx={{
          position: "fixed",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "white",
          padding: "1rem",
          zIndex: 1000,
        }}
      >
        <Button
          onClick={() => handleNavigate()}
          variant="outlined"
          sx={{
            width: "100%",
            color: "white",
            borderRadius: "0.2rem",
            padding: "0.5rem",
            backgroundColor: "rgb(6, 155, 170)",
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "rgb(6, 155, 170)",
            },
          }}
        >
          <CustomTypography variant="subtitle1" sx={{ fontWeight: "600" }}>
            Continue
          </CustomTypography>
        </Button>
      </Box>
    </Box>
  );
};

export default AddAddressMobile;
