import { Box, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import useGetProductById from '../../hooks/useGetProductById';
import ProductScrollableImageList from './ProdcutScrollableImageList';
import ProductMainImage from './ProductMainImage';
import { useRecoilValue } from 'recoil';
import {
  loadingStateAtom,
  productAtom,
  productDetailsAtom,
} from '../../../../store/atoms/productDetails/productAtom';
const ProductImages = () => {
  const isSmallScreen = useMediaQuery('(max-width:767px)');
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const product = useRecoilValue(productDetailsAtom);
  return (
    <Box
      display='flex'
      gap='1rem'
      sx={{ padding: isSmallScreen ? '0' : '0.5rem 0.2rem' }}
    >
      <Box display={isSmallScreen ? 'none' : 'block'}>
        {!isSmallScreen && (
          <ProductScrollableImageList
            setActiveImageIndex={setActiveImageIndex}
            activeImageIndex={activeImageIndex}
          />
        )}
      </Box>
      <Box sx={{ flex: '1 1 auto' }}>
        <ProductMainImage activeImageIndex={activeImageIndex} />
      </Box>
    </Box>
  );
};

export default ProductImages;
