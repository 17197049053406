import { Box } from '@mui/material';
import React from 'react';

const FlexCenter = ({ children, sx, ...rest }) => {
  return (
    <Box display={'flex'} alignItems={'center'} sx={sx} {...rest}>
      {children}
    </Box>
  );
};

export default FlexCenter;
