import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { cartPageBackdrop } from '../../store/atoms/addToCart/addToCart';

const MuiBackdrop = () => {
  const open = useRecoilValue(cartPageBackdrop);

  return (
    <Backdrop
      sx={{
        color: 'rgb(6, 155, 170)',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
    >
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};

export default MuiBackdrop;
