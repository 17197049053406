import Footer from '../../Footer';
import Navigation from '../../Navigation';
import { QUERY_GET_FILTERED_BUY_PRODUCT } from '../../../graphql/Query';
import { GET_BUY_PRODUCT_BY_PRICERANGE } from '../../../graphql/Query';
import { useMutation, useQuery } from '@apollo/client';
import '../../../ComponentsCss/Buying/PhoneRefurbished.css';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
  Spinner,
  Image,
  Dropdown,
  Modal,
  Toast,
  ListGroup,
  Accordion,
} from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FaTag, FaShoppingCart, FaShippingFast } from 'react-icons/fa';
import { CartContext } from '../../Contex/ProductDetailContex';
import { useContext, useEffect, useState } from 'react';
import { GET_SEARCH_BUY_PRODUCT } from '../../../graphql/Query';
import { useLazyQuery } from '@apollo/client';
import ProductList from './productLists';
import { QUERY_GET_EXPLORE_BRAND } from '../../../graphql/Query';

const BuyPhone = () => {
  const location = useLocation();
  let { category, condition } = useParams();
  if (category === 'Desktop-Imac') {
    category = 'Desktop/Imac';
  }

  const { data: getExploreByBrands, loading: ExploreLoading } = useQuery(
    QUERY_GET_EXPLORE_BRAND,
    {
      variables: {
        category: category,
      },
    }
  );
  const [searchBuyProduct, { loading, error, data: myValues }] = useLazyQuery(
    GET_SEARCH_BUY_PRODUCT
  );
  const [openAccordionItem, setOpenAccordionItem] = useState(null);
  const [searchdata, setSearchData] = useState(null);
  const [showDefault, setShowDefaulte] = useState(true);
  const [filterclicked, setfilterclicked] = useState(false);
  const [sortclicked, setsortclicked] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handlefilterclick = () => {
    setfilterclicked(true);
  };
  const handlesortclick = () => {
    setsortclicked(true);
  };
  const handleAccordionItemClick = (itemKey) => {
    setOpenAccordionItem(itemKey);
  };
  let [
    getFilterProduct,
    { buyProLoading, productError, data: getFilterBuyProduct },
  ] = useLazyQuery(QUERY_GET_FILTERED_BUY_PRODUCT);
  let [
    GetProductByPrice,
    { buyProLoadingPrice, productE, data: getProductByPrice },
  ] = useLazyQuery(GET_BUY_PRODUCT_BY_PRICERANGE);
  useEffect(() => {
    if (location.state?.search) {
      searchBuyProduct({
        variables: {
          name: location.state.searchInput,
        },
      }).then((data) => setSearchData(data));
    } else {
      let payload = {
        filterFileld: 'productCategory',
        filterVal: category,
        filterFileld2: 'condition',
        filterVal2: condition,
      };

      getFilterProduct({
        variables: {
          filterFileld: 'productCategory',
          filterVal: category,
          filterFileld2: 'condition',
          filterVal2: condition,
        },
      });
    }
  }, []);

  const navigate = useNavigate();

  const handlePriceFilter = (e) => {
    GetProductByPrice({
      variables: {
        productRange: `${e.target.value}`,
        category: category,
      },
    });
    setShowDefaulte(false);
    setfilterclicked(false);
  };
  const handleRamFilter = (ram) => {
    getFilterProduct({
      variables: {
        filterFileld: 'productCategory',
        filterVal: category,
        filterFileld2: 'ram',
        filterVal2: `${ram}`,
      },
    });
    setShowDefaulte(true);
    setfilterclicked(false);
  };
  const handleStorageFilter = (storage) => {
    getFilterProduct({
      variables: {
        filterFileld: 'productCategory',
        filterVal: category,
        filterFileld2: 'storage',
        filterVal2: `${storage}`,
      },
    });
    setShowDefaulte(true);
    setfilterclicked(false);
  };

  const handleBrandFilter = (brand) => {
    getFilterProduct({
      variables: {
        filterFileld: 'productCategory',
        filterVal: category,
        filterFileld2: 'productBrand',
        filterVal2: `${brand}`,
      },
    });
    setShowDefaulte(true);
    setfilterclicked(false);
  };
  const handleNetworkFilter = (network) => {
    getFilterProduct({
      variables: {
        filterFileld: 'productCategory',
        filterVal: category,
        filterFileld2: 'networkType',
        filterVal2: `${network}`,
      },
    });
    setShowDefaulte(true);
    setfilterclicked(false);
  };
  const handleScreenSizeFilter = (size) => {
    getFilterProduct({
      variables: {
        filterFileld: 'productCategory',
        filterVal: category,
        filterFileld2: 'screenSize',
        filterVal2: `${size}`,
      },
    });
    setShowDefaulte(true);
    setfilterclicked(false);
  };
  const handleTypeFilter = (type) => {
    getFilterProduct({
      variables: {
        filterFileld: 'productCategory',
        filterVal: category,
        filterFileld2: 'type',
        filterVal2: `${type}`,
      },
    });
    setShowDefaulte(true);
    setfilterclicked(false);
  };
  const handleNewArrival = () => {
    getFilterProduct({
      variables: {
        filterFileld: 'productCategory',
        filterVal: category,
        // "filterFileld2": "condition",
        // "filterVal2": condition,
      },
    });
    setShowDefaulte(true);
    setfilterclicked(false);
  };

  return (
    <>
      <Navigation />
      <div className='d-flex card-wrapper'>
        <div className='filter-card'>
          <div className='fixed-filter-card'>
            <Card
              className={
                !filterclicked && !sortclicked
                  ? 'm-5 p-3 shadow bg-body-tertiary rounded mobilefilterdesk'
                  : 'm-5 p-3 shadow bg-body-tertiary rounded mobilefilter'
              }
            >
              <Card.Header className='fs-5 ps-0 semibold bg-white'>
                <div className='d-flex justify-content-between'>
                  <span
                    className='fw-semibold'
                    onClick={() => handlefilterclick()}
                  >
                    Filter
                  </span>
                  <span
                    className='fw-semibold'
                    onClick={() => handlesortclick()}
                  >
                    Sort
                  </span>
                </div>
              </Card.Header>
            </Card>

            <Card
              className={
                filterclicked
                  ? 'm-5 p-3 shadow bg-body-tertiary rounded filterpart2show'
                  : 'm-5 p-3 shadow bg-body-tertiary rounded filterpart2'
              }
            >
              <Card.Header className='fs-5 ps-0 semibold bg-white'>
                <div className='d-flex justify-content-between'>
                  <span className='fw-semibold'>Filter</span>
                  <span
                    className='text-danger fw-semibold d-lg-none'
                    style={{ cursor: 'pointer' }}
                    onClick={() => setfilterclicked(false)}
                  >
                    Close
                  </span>
                </div>
              </Card.Header>
              <div>
                <div>
                  <p>Category</p>
                  <Toast>
                    <Toast.Body>{category}</Toast.Body>
                  </Toast>
                </div>
                <Accordion className='mt-3'>
                  <Accordion.Item eventKey='0'>
                    <Accordion.Header
                      onClick={() => handleAccordionItemClick('0')}
                    >
                      Brands
                    </Accordion.Header>
                    <Accordion.Collapse
                      eventKey='0'
                      in={openAccordionItem === '0'}
                    >
                      <Accordion.Body>
                        <Form>
                          <div className='mb-3 filteritem'>
                            <div className='text-capitalize'>
                              {getExploreByBrands &&
                                getExploreByBrands?.getExploreByBrands?.map(
                                  (data) => {
                                    return (
                                      <div>
                                        <Form.Check
                                          type='radio'
                                          name='brand'
                                          label={data.brandName}
                                          onClick={() =>
                                            handleBrandFilter(data.brandName)
                                          }
                                        />
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </div>
                        </Form>
                      </Accordion.Body>
                    </Accordion.Collapse>
                  </Accordion.Item>
                </Accordion>
                <Accordion className='mt-3'>
                  <Accordion.Item eventKey='1'>
                    <Accordion.Header
                      onClick={() => handleAccordionItemClick('1')}
                    >
                      Price
                    </Accordion.Header>
                    <Accordion.Collapse
                      eventKey='1'
                      in={openAccordionItem === '1'}
                    >
                      <Accordion.Body>
                        <Form>
                          <div className='mb-3 filteritem'>
                            <div className='d-flex justify-content-between'>
                              <Form.Check
                                type='radio'
                                name='price'
                                value='0-10000'
                                label={`Under ₹ 10000`}
                                onClick={handlePriceFilter}
                              />
                            </div>
                            <div className='d-flex justify-content-between'>
                              <Form.Check
                                type='radio'
                                name='price'
                                value='10000-20000'
                                label={`₹ 10000 to  ₹ 20000`}
                                onClick={handlePriceFilter}
                              />
                            </div>
                            <div className='d-flex justify-content-between'>
                              <Form.Check
                                type='radio'
                                name='price'
                                value='20000-30000'
                                label={`₹ 20000 to  ₹ 30000`}
                                onClick={handlePriceFilter}
                              />
                            </div>
                            <div className='d-flex justify-content-between'>
                              <Form.Check
                                type='radio'
                                name='price'
                                value='30000-40000'
                                label={`₹ 30000 to  ₹ 40000`}
                                onClick={handlePriceFilter}
                              />
                            </div>
                            <div className='d-flex justify-content-between'>
                              <Form.Check
                                type='radio'
                                name='price'
                                value='40000-60000'
                                label={`₹ 40000 to  ₹ 60000`}
                                onClick={handlePriceFilter}
                              />
                            </div>
                            <div className='d-flex justify-content-between'>
                              <Form.Check
                                type='radio'
                                name='price'
                                value='60000-600000'
                                label={`Above ₹ 60000`}
                                onClick={handlePriceFilter}
                              />
                            </div>
                          </div>
                        </Form>
                      </Accordion.Body>
                    </Accordion.Collapse>
                  </Accordion.Item>
                </Accordion>
                {category === 'Phones' ||
                category === 'Laptops' ||
                category === 'Desktop/Imac' ? (
                  <Accordion className='mt-3'>
                    <Accordion.Item eventKey='2'>
                      <Accordion.Header
                        onClick={() => handleAccordionItemClick('2')}
                      >
                        RAM
                      </Accordion.Header>
                      <Accordion.Collapse
                        eventKey='2'
                        in={openAccordionItem === '2'}
                      >
                        <Accordion.Body>
                          <Form>
                            <div className='mb-3 filteritem'>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  onClick={() => handleRamFilter('2GB')}
                                  type='radio'
                                  name='ram'
                                  label={`2 GB`}
                                />
                              </div>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  onClick={() => handleRamFilter('3GB')}
                                  name='ram'
                                  label={`3 GB`}
                                />
                              </div>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  onClick={() => handleRamFilter('4GB')}
                                  type='radio'
                                  name='ram'
                                  label={`4 GB`}
                                />
                              </div>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  onClick={() => handleRamFilter('6GB')}
                                  name='ram'
                                  label={`6 GB`}
                                />
                              </div>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  onClick={() => handleRamFilter('8GB')}
                                  name='ram'
                                  label={`8 GB`}
                                />
                              </div>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  name='ram'
                                  onClick={() => handleRamFilter('12GB')}
                                  label={`12 GB`}
                                />
                              </div>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  name='ram'
                                  onClick={() => handleRamFilter('16GB')}
                                  label={`16 GB`}
                                />
                              </div>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  name='ram'
                                  label={`32 GB`}
                                  onClick={() => handleRamFilter('32GB')}
                                />
                              </div>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  name='ram'
                                  label={`64 GB`}
                                  onClick={() => handleRamFilter('64GB')}
                                />
                              </div>
                            </div>
                          </Form>
                        </Accordion.Body>
                      </Accordion.Collapse>
                    </Accordion.Item>
                  </Accordion>
                ) : (
                  ''
                )}
                {category === 'Phones' || category === 'Tablets' ? (
                  <Accordion className='mt-3'>
                    <Accordion.Item eventKey='3'>
                      <Accordion.Header
                        onClick={() => handleAccordionItemClick('3')}
                      >
                        Internal Storage
                      </Accordion.Header>
                      <Accordion.Collapse
                        eventKey='3'
                        in={openAccordionItem === '3'}
                      >
                        <Accordion.Body>
                          <Form>
                            <div className='mb-3 filteritem'>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  name='storage'
                                  label={`16 GB`}
                                  onClick={() => handleStorageFilter('16GB')}
                                />
                              </div>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  name='storage'
                                  label={`32 GB`}
                                  onClick={() => handleStorageFilter('32GB')}
                                />
                              </div>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  name='storage'
                                  label={`64 GB`}
                                  onClick={() => handleStorageFilter('64GB')}
                                />
                              </div>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  name='storage'
                                  label={`128 GB`}
                                  onClick={() => handleStorageFilter('128GB')}
                                />
                              </div>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  name='storage'
                                  label={`256 GB`}
                                  onClick={() => handleStorageFilter('256GB')}
                                />
                              </div>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  name='storage'
                                  label={`512 GB`}
                                  onClick={() => handleStorageFilter('512GB')}
                                />
                              </div>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  name='storage'
                                  label={`1 TB`}
                                  onClick={() => handleStorageFilter('1TB')}
                                />
                              </div>

                              {category === 'Tablets' ? (
                                <div className='d-flex justify-content-between'>
                                  <Form.Check
                                    type='radio'
                                    name='storage'
                                    label={`2 TB`}
                                    onClick={() => handleStorageFilter('2TB')}
                                  />
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </Form>
                        </Accordion.Body>
                      </Accordion.Collapse>
                    </Accordion.Item>
                  </Accordion>
                ) : category === 'Tablets' ||
                  category === 'Desktop/Imac' ||
                  category === 'Laptops' ? (
                  <Accordion className='mt-3'>
                    <Accordion.Item eventKey='3'>
                      <Accordion.Header
                        onClick={() => handleAccordionItemClick('3')}
                      >
                        Internal Storage
                      </Accordion.Header>
                      <Accordion.Collapse
                        eventKey='3'
                        in={openAccordionItem === '3'}
                      >
                        <Accordion.Body>
                          <Form>
                            <div className='mb-3 filteritem'>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  name='storage'
                                  label={`Above 512GB `}
                                  onClick={() =>
                                    handleStorageFilter('Above512')
                                  }
                                />
                              </div>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  name='storage'
                                  label={`Below 512GB`}
                                  onClick={() =>
                                    handleStorageFilter('Below512')
                                  }
                                />
                              </div>
                            </div>
                          </Form>
                        </Accordion.Body>
                      </Accordion.Collapse>
                    </Accordion.Item>
                  </Accordion>
                ) : (
                  ''
                )}
                {category === 'Laptops' ? (
                  <Accordion className='mt-3'>
                    <Accordion.Item eventKey='3'>
                      <Accordion.Header
                        onClick={() => handleAccordionItemClick('3')}
                      >
                        Screen Size
                      </Accordion.Header>
                      <Accordion.Collapse
                        eventKey='3'
                        in={openAccordionItem === '3'}
                      >
                        <Accordion.Body>
                          <Form>
                            <div className='mb-3 filteritem'>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  label={`Below 13 inches`}
                                  name='network'
                                  onClick={() =>
                                    handleScreenSizeFilter('Below 13 inches')
                                  }
                                />
                              </div>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  name='network'
                                  label={`13-14 inches`}
                                  onClick={() =>
                                    handleScreenSizeFilter('13-14 inches')
                                  }
                                />
                              </div>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  name='network'
                                  label={`14-15 inches`}
                                  onClick={() =>
                                    handleScreenSizeFilter('14-15 inches')
                                  }
                                />
                              </div>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  name='network'
                                  label={`Above 15 inches`}
                                  onClick={() =>
                                    handleScreenSizeFilter('Above 15 inches')
                                  }
                                />
                              </div>
                            </div>
                          </Form>
                        </Accordion.Body>
                      </Accordion.Collapse>
                    </Accordion.Item>
                  </Accordion>
                ) : category === 'Desktop/Imac' ? (
                  <Accordion className='mt-3'>
                    <Accordion.Item eventKey='3'>
                      <Accordion.Header
                        onClick={() => handleAccordionItemClick('3')}
                      >
                        Screen Size
                      </Accordion.Header>
                      <Accordion.Collapse
                        eventKey='3'
                        in={openAccordionItem === '3'}
                      >
                        <Accordion.Body>
                          <Form>
                            <div className='mb-3 filteritem'>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  label={`Below 30 inches`}
                                  name='network'
                                  onClick={() => handleNetworkFilter('4G')}
                                />
                              </div>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  name='network'
                                  label={`Above 30 inches `}
                                  onClick={() => handleNetworkFilter('5G')}
                                />
                              </div>
                            </div>
                          </Form>
                        </Accordion.Body>
                      </Accordion.Collapse>
                    </Accordion.Item>
                  </Accordion>
                ) : (
                  ''
                )}
                {category === 'Phones' ? (
                  <Accordion className='mt-3'>
                    <Accordion.Item eventKey='3'>
                      <Accordion.Header
                        onClick={() => handleAccordionItemClick('3')}
                      >
                        Network Type
                      </Accordion.Header>
                      <Accordion.Collapse
                        eventKey='3'
                        in={openAccordionItem === '3'}
                      >
                        <Accordion.Body>
                          <Form>
                            <div className='mb-3 filteritem'>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  label={`4G`}
                                  name='network'
                                  onClick={() => handleNetworkFilter('4G')}
                                />
                              </div>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  name='network'
                                  label={`5G`}
                                  onClick={() => handleNetworkFilter('5G')}
                                />
                              </div>
                            </div>
                          </Form>
                        </Accordion.Body>
                      </Accordion.Collapse>
                    </Accordion.Item>
                  </Accordion>
                ) : category === 'Tablets' ? (
                  <Accordion className='mt-3'>
                    <Accordion.Item eventKey='3'>
                      <Accordion.Header
                        onClick={() => handleAccordionItemClick('3')}
                      >
                        Connectivity{' '}
                      </Accordion.Header>
                      <Accordion.Collapse
                        eventKey='3'
                        in={openAccordionItem === '3'}
                      >
                        <Accordion.Body>
                          <Form>
                            <div className='mb-3 filteritem'>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  label={`Only WiFi`}
                                  name='network'
                                  onClick={() =>
                                    handleNetworkFilter('Only WiFi')
                                  }
                                />
                              </div>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  name='network'
                                  label={`Cellular + WiFi`}
                                  onClick={() =>
                                    handleNetworkFilter('Cellular + WiFi')
                                  }
                                />
                              </div>
                            </div>
                          </Form>
                        </Accordion.Body>
                      </Accordion.Collapse>
                    </Accordion.Item>
                  </Accordion>
                ) : (
                  ''
                )}
                {category === 'Other Accessories' ? (
                  <Accordion className='mt-3'>
                    <Accordion.Item eventKey='3'>
                      <Accordion.Header
                        onClick={() => handleAccordionItemClick('3')}
                      >
                        {' '}
                        Type
                      </Accordion.Header>
                      <Accordion.Collapse
                        eventKey='3'
                        in={openAccordionItem === '3'}
                      >
                        <Accordion.Body>
                          <Form>
                            <div className='mb-3 filteritem'>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  label={`Camera`}
                                  name='network'
                                  onClick={() => handleTypeFilter('Camera')}
                                />
                              </div>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  label={`Airpod`}
                                  name='network'
                                  onClick={() => handleTypeFilter('Airpod')}
                                />
                              </div>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  label={`Earphone`}
                                  name='network'
                                  onClick={() => handleTypeFilter('Earphone')}
                                />
                              </div>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  label={`Drone`}
                                  name='network'
                                  onClick={() => handleTypeFilter('Drone')}
                                />
                              </div>
                              <div className='d-flex justify-content-between'>
                                <Form.Check
                                  type='radio'
                                  label={`Printer`}
                                  name='network'
                                  onClick={() => handleTypeFilter('Printer')}
                                />
                              </div>
                            </div>
                          </Form>
                        </Accordion.Body>
                      </Accordion.Collapse>
                    </Accordion.Item>
                  </Accordion>
                ) : (
                  ''
                )}
              </div>
            </Card>
            <Card
              className={
                sortclicked
                  ? 'm-5 p-3 shadow bg-body-tertiary rounded filterpart2show'
                  : 'm-5 p-3 shadow bg-body-tertiary rounded filterpart2'
              }
            >
              <Card.Header className='fs-5 ps-0 semibold bg-white'>
                <div className='d-flex justify-content-between'>
                  <span className='fw-semibold'>Sort By</span>
                  <span
                    className='text-danger fw-semibold d-lg-none'
                    style={{ cursor: 'pointer' }}
                    onClick={() => setsortclicked(false)}
                  >
                    Close
                  </span>
                </div>
              </Card.Header>
              <div>
                <Form>
                  <div className='mb-3 filteritem'>
                    <div className='d-flex justify-content-between'>
                      <Form.Check
                        type='radio'
                        name='price'
                        value='0-1000000'
                        label={`Price Low to High`}
                        onClick={handlePriceFilter}
                      />
                    </div>
                    <div className='d-flex justify-content-between'>
                      <Form.Check
                        type='radio'
                        name='price'
                        value='0-1000000-sort'
                        label={`Price High to Low`}
                        onClick={handlePriceFilter}
                      />
                    </div>
                    <div className='d-flex justify-content-between'>
                      <Form.Check
                        type='radio'
                        name='price'
                        // value="20000-30000"
                        label={`New Arrivals`}
                        onClick={() => handleNewArrival()}
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </Card>
          </div>
        </div>
        <div className='d-flex flex-wrap justify-content-start  buycart'>
          {searchdata ? (
            <ProductList products={searchdata.data?.searchBuyProduct} />
          ) : (
            ''
          )}

          {showDefault ? (
            buyProLoading ? (
              <Spinner
                animation='border'
                variant='success'
                className='mx-auto d-block '
              />
            ) : (
              <ProductList
                products={getFilterBuyProduct?.getFilterBuyProduct}
              />
            )
          ) : buyProLoadingPrice ? (
            <Spinner
              animation='border'
              variant='success'
              className='mx-auto d-block '
            />
          ) : (
            <ProductList products={getProductByPrice?.getProductByPrice} />
          )}
        </div>
      </div>
    </>
  );
};
export default BuyPhone;
