import React from 'react';
import { LocalShippingOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import FlexCenter from '../../muiComponents/FlexCenter';
import FlexBetween from '../../muiComponents/FlexBetween';
import CustomCard from '../../muiComponents/CustomCard';
import CustomTypography from '../../../../muicomponents/shared/CustomTypography';
import { useRecoilValue } from 'recoil';
import { loadingStateAtom } from '../../../../store/atoms/productDetails/productAtom';
const ProductDevliveryDates = () => {
  const currentDate = new Date();
  const currentDate2 = new Date();
  currentDate.setDate(currentDate.getDate() + 7);
  currentDate2.setDate(currentDate2.getDate() + 5);
  const options = { month: 'short', day: '2-digit' };
  const deliveredDate = currentDate.toLocaleDateString(undefined, options);
  const deliveredDate2 = currentDate2.toLocaleDateString(undefined, options);
  const loading = useRecoilValue(loadingStateAtom);
  return (
    <CustomCard sx={{ marginTop: '0.2rem' }}>
      <FlexCenter gap={'0.5rem'}>
        <Box>
          <LocalShippingOutlined sx={{ color: 'rgb(119, 119, 119)' }} />
        </Box>
        <Box>
          <CustomTypography
            variant='subtitle1'
            sx={{ color: 'rgb(119, 119, 119)', fontWeight: '500' }}
          >
            Delivery By |{' '}
            <span style={{ color: '#36454F', fontWeight: '700' }}>
              {deliveredDate2} - {deliveredDate}
            </span>
          </CustomTypography>
        </Box>
      </FlexCenter>
    </CustomCard>
  );
};

export default ProductDevliveryDates;
