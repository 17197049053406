import { selector } from "recoil";
import { cartItems } from "../../atoms/addToCart/addToCart";

export const countOfSelectedItems = selector({
  key: "countOfSelectedItems",
  get: ({ get }) => {
    const cart = get(cartItems);
    return cart.items.filter((item) => item.isCheckedForCheckout).length;
  },
});

export const totalMRP = selector({
  key: "totalMRP",
  get: ({ get }) => {
    const items = get(cartItems);

    return items.reduce((total, item) => {
      if (item.isCheckedForCheckout && item.productId && item.productId.mrp) {
        const mrpValue = Number(item.productId.mrp);
        if (!isNaN(mrpValue)) {
          return total + mrpValue;
        }
      }
      return total;
    }, 0);
  },
});

export const cartTotals = selector({
  key: "cartTotals",
  get: ({ get }) => {
    const items = get(cartItems);

    const totals = items.reduce(
      (acc, item) => {
        if (item.isCheckedForCheckout) {
          const mrp = Number(item.productId?.mrp) || 0;
          const productPrice = Number(item.productId.productPrice) || 0;

          if (!isNaN(mrp) && !isNaN(productPrice)) {
            acc.totalMRP += mrp;
            acc.totalProductPrice += productPrice;
            acc.selectedItemsCount++;
          }
        }
        return acc;
      },
      {
        totalMRP: 0,
        totalProductPrice: 0,
        selectedItemsCount: 0,
      }
    );

    totals.totalDiscount = Math.max(
      totals.totalMRP - totals.totalProductPrice,
      0
    );

    return totals;
  },
});
