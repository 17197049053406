import { Box, useMediaQuery, useTheme } from '@mui/material';
import React, { forwardRef } from 'react';
import OfferBadge from '../shared/OfferBadge';
import ProductTitle from '../shared/ProductTitle';
import ProductCondition from '../shared/ProductCondition';
import ProductPrice from '../shared/ProductPrice';
import EmiPlan from '../shared/EmiPlan';
import Discounts from '../shared/Discounts';
import { useNavigate } from 'react-router-dom';

const ProductSmall = forwardRef(({ product }, ref) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('tablet'));

  const handleClick = () => {
    navigate(`/buying-product-details/${product.id}`);
  };

  return (
    <Box sx={{ margin: '0.25rem' }} onClick={handleClick} ref={ref}>
      <OfferBadge price={product.productPrice} mrp={product.mrp} />
      <Box
        display={'flex'}
        flexDirection={'row'}
        gap={'0.5rem'}
        position={'relative'}
        sx={{
          width: '100%',
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
          minHeight: '150px',
          borderRadius: '0.5rem',
          padding: '1rem 0.8rem',
        }}
      >
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <img
            src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${product.image[0]}`}
            alt=''
            style={{
              width: isSmallScreen ? '112px' : '176px',
              height: isSmallScreen ? '112px' : '176px',
              objectFit: 'cover',
              borderRadius: '0.5rem',
            }}
          />
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          gap={'0.1rem'}
        >
          <ProductTitle title={product.productName} isSmall={true} />
          <ProductCondition condition={product.condition} isSmall={true} />
          <EmiPlan isSmall={true} />
          <ProductPrice price={product.productPrice} mrp={product.mrp} />
          <Discounts isSmall={true} />
        </Box>
      </Box>
    </Box>
  );
});

export default ProductSmall;
