import { useMutation } from "@apollo/client";
import React from "react";
import { EMPTY_CART } from "../../../../graphql/Mutations";
import useSnackbarNotifier from "../../../shared/hooks/useSnackbarNotifier";
import {
  cartItems,
  cartPageBackdrop,
} from "../../../../store/atoms/addToCart/addToCart";
import { useSetRecoilState } from "recoil";

const useEmptyCart = () => {
  const [clearCart, { loading, error }] = useMutation(EMPTY_CART);
  const showSnackbar = useSnackbarNotifier();
  const setCartItemsState = useSetRecoilState(cartItems);
  const setBackdropOpen = useSetRecoilState(cartPageBackdrop);
  const emptyCart = async (userId) => {
    try {
      setBackdropOpen(true);
      const { data } = await clearCart({
        variables: {
          userId,
        },
      });
      if (data.clearCart) {
        const { message, deletedCount } = data.clearCart;
        setCartItemsState(null);
        setBackdropOpen(false);
        showSnackbar(`${deletedCount} ${message}`, "error");
      }
    } catch (error) {
      console.error("Error updating cart item checkout:", error);
      setBackdropOpen(false);
    }
  };
  return { emptyCart };
};

export default useEmptyCart;
