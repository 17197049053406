import * as yup from "yup";

export const couponSchema = yup.object().shape({
  code: yup.string().required("required"),
  description: yup.string().required("required"),
  discountType: yup.string().required("required"),
  discountValue: yup.string().required("required"),
  startingDate: yup.date().required("required"),
  endingDate: yup.date().required("required"),
});

export default couponSchema;
