import { Typography } from '@mui/material';
import React from 'react';

const CustomTypography = ({ children, sx, ...props }) => {
  return (
    <Typography sx={{ fontFamily: '"Poppins", sans-serif', ...sx }} {...props}>
      {children}
    </Typography>
  );
};

export default CustomTypography;
