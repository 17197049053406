import { atom } from "recoil";

export const selectedAddress = atom({
  key: "selectedAddress",
  default: null,
});

export const paymentInfoModalOpen = atom({
  key: "paymentInfoModalOpen",
  default: false,
});

export const paymentSuccessAtom = atom({
  key: "paymentSuccessAtom",
  default: false,
});

export const orderIdAtom = atom({
  key: "orderIdAtom",
  default: "",
});

export const allCheckedCartItems = atom({
  key: "allCheckedCartItems",
  default: [],
});
