import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import ProductCards from './productsComponents/laptop/ProductCards';
import { filteredProductsSelector } from '../../../../store/selectors/productListing/productListing';
import ProductCardsSmall from './productsComponents/mobile/ProductCardsSmall';
import SortAndFilterComponent from './filterComponents/SortAndFilterComponent';

const ProductsContainer = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('laptop'));

  return (
    <>
      {isSmallScreen ? (
        <>
          <Box
            sx={{
              width: '100%',
              padding: '1rem',
            }}
          >
            <ProductCardsSmall />
            <SortAndFilterComponent />
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              width: '100%',
              overflow: 'auto',
              borderRadius: '0.5rem',

              position: 'relative',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            <Box display={'flex'}></Box>
            <ProductCards />
          </Box>
        </>
      )}
    </>
  );
};

export default ProductsContainer;
