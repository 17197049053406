import { useMutation } from "@apollo/client";
import { MUTATION_APPLY_COUPON } from "../../../../graphql/Mutations";
import useSnackbarNotifier from "../../../shared/hooks/useSnackbarNotifier";
import {
  cartItems,
  cartPageBackdrop,
} from "../../../../store/atoms/addToCart/addToCart";
import { useSetRecoilState } from "recoil";

const useAddCoupon = () => {
  const userId = localStorage.getItem("userId");
  const setCartData = useSetRecoilState(cartItems);
  const setBackdropOpen = useSetRecoilState(cartPageBackdrop);
  const showSnackbar = useSnackbarNotifier();

  const [applyCoupon, { loading }] = useMutation(MUTATION_APPLY_COUPON);

  const addCoupon = async (couponCode) => {
    try {
      setBackdropOpen(true);
      const result = await applyCoupon({
        variables: { userId, couponCode },
      });      
      if (result.data && result.data.applyCoupon) {
        const { coupon, message, couponDiscount, totalAmount } =
          result.data.applyCoupon;
        setCartData((prevState) => ({
          ...prevState,
          coupon,
          couponDiscount,
          totalAmount,
        }));
        showSnackbar(message, "success");
      }
    } catch (error) {
      console.error("Caught error:", error);
      let errorMessage = "An unexpected error occurred.";
      if (
        error.networkError &&
        error.networkError.result &&
        error.networkError.result.errors
      ) {
        errorMessage = error.networkError.result.errors[0].message;
      } else if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        errorMessage = error.graphQLErrors[0].message;
      } else if (error.message) {
        errorMessage = error.message;
      }

      showSnackbar(errorMessage, "error");
    } finally {
      setBackdropOpen(false);
    }
  };

  return { addCoupon, loading };
};

export default useAddCoupon;
