import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import useGetAllAddress from '../../hooks/addAddress/useGetAllAddress';
import AllAddress from './allAddress/AllAddress';
import Form from './addressForm/Form';

const AddressWrapper = () => {
  const userId = localStorage.getItem('userId');
  const { allAddress, loading, error, refetch } = useGetAllAddress(userId);

  useEffect(() => {
    refetch();
  }, []);

  if (loading) {
    return <Box>Loading....</Box>;
  }

  if (error) {
    return <Box>Error: {error.message}</Box>;
  }

  return (
    <Box>
      {allAddress && allAddress.length > 0 ? (
        <AllAddress addresses={allAddress} />
      ) : (
        <Form />
      )}
    </Box>
  );
};

export default AddressWrapper;
