import { Close } from "@mui/icons-material";
import {
  Slide,
  Modal,
  Box,
  Typography,
  IconButton,
  useMediaQuery,
} from "@mui/material";

import React from "react";
import OfferCard from "../../muiComponents/OfferCard";
import Offer from "../../icons/Offer";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
const OfferModal = ({ setModalOpen, modalOpen, offers }) => {
  const backgoundGradients = [
    "linear-gradient(-270deg, rgb(255, 245, 183) 0%, rgb(255, 251, 219) 100%)",
    "linear-gradient(-270deg, #accbee 0%, #e7f0fd 100%)",
    "linear-gradient(-270deg, #50c9c3 0%, #96deda 100%)",
  ];
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <Modal open={modalOpen} onClose={handleModalClose} closeAfterTransition>
      <Slide
        direction={isSmallScreen ? "up" : "left"}
        in={modalOpen}
        mountOnEnter
        unmountOnExit
      >
        <Box
          sx={{
            position: "fixed",
            right: isSmallScreen ? "auto" : 0,
            top: isSmallScreen ? "auto" : 0,
            bottom: isSmallScreen ? 0 : "auto",
            width: isSmallScreen ? "100%" : "auto",
            height: isSmallScreen ? "100%" : "100%",
            minHeight: isSmallScreen ? "50%" : "100%",
            minWidth: !isSmallScreen ? "40%" : "100%",
            maxWidth: isSmallScreen ? "100%" : "60%",
            bgcolor: "background.paper",
            p: isSmallScreen ? "2rem" : "4rem",
            overflowY: "auto",
            transform: "translateX(0)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
              cursor: "pointer",
              borderBottom: isSmallScreen ? "1px solid 	#B2BEB5" : "",
              paddingBottom: "0.3rem",
            }}
          >
            <Box display={"flex"} gap={"0.2rem"}>
              <Box>
                {isSmallScreen && (
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{
                      fontFamily: '"Source Sans 3", sans-serif',
                      fontWeight: "500",
                    }}
                  >
                    Available Offers
                  </Typography>
                )}
              </Box>
            </Box>

            <Box>
              <IconButton onClick={handleModalClose}>
                <Close />
              </IconButton>
            </Box>
          </Box>
          {!isSmallScreen && (
            <Typography
              variant="h6"
              component="h2"
              sx={{
                fontFamily: '"Source Sans 3", sans-serif',
                fontWeight: "500",
              }}
            >
              Available Offers
            </Typography>
          )}
          <Box
            display="flex"
            flexDirection="column"
            gap="1rem"
            sx={{
              width: "100%",
              overflowY: "scroll",
              padding: "0.5rem",
              cursor: "grab",
              "&:active": {
                cursor: "grabbing",
              },
              "&::-webkit-scrollbar": {
                display: "none",
              },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            {offers.map((offer, index) => {
              return (
                <OfferCard
                  key={index}
                  sx={{
                    background: backgoundGradients[index],
                    minWidth: { xs: "85%", sm: "70%", md: "45%", lg: "30%" },
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    backgroundColor: "#f0f0f0",
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    transition: "box-shadow 0.3s ease",
                    "&:hover": {
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "16px",
                      left: "16px",
                    }}
                  >
                    <Offer style={{ fontSize: "1.5rem", color: "#069baa" }} />
                  </Box>
                  <Box
                    sx={{
                      flexGrow: 1,
                      pl: "48px",
                      pr: "16px",
                      py: "16px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CustomTypography
                      variant="body2"
                      sx={{
                        fontWeight: "400",
                        lineHeight: 1.6,
                        fontSize: "0.9rem",
                      }}
                    >
                      {offer.offerDesc}
                    </CustomTypography>
                  </Box>
                </OfferCard>
              );
            })}
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

export default OfferModal;
