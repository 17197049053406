import React from 'react';
import { Container } from 'react-bootstrap';
import TrendingGadgets from './TrendingGadgets';
import { useQuery } from '@apollo/client';
import { QUERY_GET_TRENDING_PRODUCTS } from '../graphql/Query';

const TrendingGadetsContainer = () => {
  const { data: getTrendingBuyProduct, loading: Loading } = useQuery(
    QUERY_GET_TRENDING_PRODUCTS
  );

  return (
    <>
      {!Loading &&
        getTrendingBuyProduct &&
        getTrendingBuyProduct.getTrendingBuyProduct.length > 0 && (
          <TrendingGadgets
            products={getTrendingBuyProduct.getTrendingBuyProduct}
            isBuy={false}
          />
        )}
    </>
  );
};

export default TrendingGadetsContainer;
