import React, { useEffect } from 'react';

function AffordableWidget({ amount }) {
  let widgetAmount = amount * 100;
  useEffect(() => {
    if (window.RazorpayAffordabilitySuite) {
      const key = 'rzp_test_JGqcMdJItVpPIO';
      const widgetConfig = {
        key: key,
        amount: widgetAmount,
      };
      const rzpAffordabilitySuite = new window.RazorpayAffordabilitySuite(
        widgetConfig
      );
      rzpAffordabilitySuite.render();
    } else {
      console.error(
        'Razorpay Affordability Suite is not loaded. Make sure the script is properly loaded.'
      );
    }
  }, [amount]);

  return (
    <div>
      <div id='razorpay-affordability-widget'></div>
    </div>
  );
}

export default AffordableWidget;
