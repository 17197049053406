import React from 'react';
import Navigation from '../../../Components/Navigation';
import ProductListing from '../components/productsListing/ProductListing';
import ResponsiveContainer from '../../../muicomponents/shared/ResponsiveConatiner';

const ProductListingPage = () => {
  return (
    <>
      <Navigation />
      <ResponsiveContainer>
        <ProductListing />
      </ResponsiveContainer>
    </>
  );
};

export default ProductListingPage;
