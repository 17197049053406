import {
  Box,
  Checkbox,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import { Close } from "@mui/icons-material";
import DiscountFunction from "../../../../../utils/DiscountFunction";
import useDeleteCartItem from "../../../hooks/addToCart/useDeleteCartItem";
import CartItemCloseModal from "../modals/CartItemCloseModal";
import useUpdateCartItemCheckout from "../../../hooks/addToCart/useUpdateCartItemCheckout";

const Cart = React.memo(({ cartData, cartId }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const [openModal, setOpenModal] = useState(false);
  const { deleteItemFromCart } = useDeleteCartItem();
  const { toggleCartItemCheckout } = useUpdateCartItemCheckout();

  const handleChangeCheckbox = () => {
    toggleCartItemCheckout(cartData.id, cartId);
  };
  const removeItemFromCart = () => {
    setOpenModal(false);
    deleteItemFromCart(cartData.id);
  };

  const handleModelOpen = () => {
    setOpenModal(true);
  };

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"row"}
        sx={{
          border: isSmallScreen ? "none" : "1px solid #eaeaec",
          padding: isSmallScreen ? "0.8rem 0 0.8rem 0.8rem" : "0.8rem 1rem",
          width: "100%",
          borderRadius: "0.3rem",
          backgroundColor: "white",
        }}
      >
        <Box sx={{ position: "relative", minWidth: "110px", flexShrink: 0 }}>
          <img
            src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${cartData.product.image[0]}`}
            alt=""
            style={{
              height: "148px",
              width: "110px",
              objectFit: "cover",
            }}
          />
          <Box sx={{ position: "absolute", top: "0", left: "0" }}>
            <Checkbox
              onClick={handleChangeCheckbox}
              checked={cartData.isCheckedForCheckout}
              sx={{
                padding: 0,
                "&.Mui-checked": {
                  color: "rgb(6, 155, 170)",
                },
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            paddingLeft: "0.8rem",
            marginBottom: "4px",
          }}
        >
          <Box>
            <CustomTypography
              variant={"subtitle1"}
              sx={{
                fontWeight: "600",
                display: "-webkit-box",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                lineHeight: "1.5em",
                maxHeight: "3em",
              }}
            >
              {cartData.product.productName}
            </CustomTypography>
          </Box>
          <Box>
            <CustomTypography variant={"subtitle2"}>
              Condition:{" "}
              <span style={{ fontWeight: 700, color: "rgb(6, 155, 170)" }}>
                {cartData.product.condition}
              </span>
            </CustomTypography>
          </Box>
          <Box>
            <CustomTypography variant={"subtitle2"}>
              <span
                style={{
                  backgroundColor: "#f5f5f6",
                  padding: "2px 8px",
                  fontWeight: "700",
                }}
              >
                Qty: {cartData.quantity}
              </span>
            </CustomTypography>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={"0.5rem"}
          >
            <CustomTypography variant={"subtitle1"} sx={{ fontWeight: "700" }}>
              ₹{cartData.product.productPrice}{" "}
            </CustomTypography>
            <CustomTypography
              variant={"subtitle1"}
              style={{
                color: "#94969f",
                fontWeight: 0,
                textDecoration: "line-through",
              }}
            >
              {cartData.product.mrp}
            </CustomTypography>
            <CustomTypography
              variant={"subtitle1"}
              sx={{ fontWeight: "500", color: "#f16565" }}
            >
              {DiscountFunction(
                cartData.product.productPrice,
                cartData.product.mrp
              )}
              % OFF
            </CustomTypography>
          </Box>
          <Box>
            <CustomTypography variant={"subtitle2"}>
              Warranty:{" "}
              <span style={{ fontWeight: 700 }}>
                {cartData.product.warranty}
              </span>
            </CustomTypography>
          </Box>
        </Box>
        <Box sx={{ flexShrink: 0 }}>
          <IconButton onClick={handleModelOpen}>
            <Close />
          </IconButton>
        </Box>
      </Box>
      <CartItemCloseModal
        modalOpen={openModal}
        setModalOpen={setOpenModal}
        removeItemFromCart={removeItemFromCart}
        modalTitle={"Remove Item"}
        modalDescription={"Are you sure you want to remove this item?"}
        oneItem={true}
      />
    </>
  );
});

export default Cart;
