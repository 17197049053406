import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import EmptyCart from "../../../../Images/cart/empty-cart_16621287 1.png";
import CartItems from "./cartItems/CartItems";
import PriceComponent from "./priceSection/PriceComponent";
import MuiBackdrop from "../../../../muicomponents/shared/MuiBackdrop";
import AvailableOffersCart from "./cartItems/AvailableOffersCart";
import YouMayAlsoLike from "./relatedProductSection/YouMayAlsoLike";
import useGetCartItems from "../../hooks/addToCart/useGetCartItems";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";

const AddToCart = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const userId = localStorage.getItem("userId");
  const { cartData, loading, error, refetch } = useGetCartItems(userId);

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (loading) {
    return <>Loading....</>;
  }
  if (error) {
    return <>Error:{error}</>;
  }
  return (
    <>
      {cartData != null && cartData.items.length !== 0 ? (
        <>
          <MuiBackdrop />
          <Box>
            <Box
              display={"flex"}
              flexDirection={isSmallScreen ? "column" : "row"}
              gap={isSmallScreen ? "1rem" : "0"}
            >
              <Box
                flexBasis={isSmallScreen ? "100%" : "65%"}
                sx={{
                  width: "100%",
                  padding: isSmallScreen ? "0" : "2rem 1.5rem 0 0",
                  borderRight: isSmallScreen ? "none" : "1px solid #eaeaec",
                }}
              >
                <AvailableOffersCart />
                <CartItems />
              </Box>
              <Box
                flexBasis={isSmallScreen ? "100%" : "35%"}
                sx={{
                  width: "100%",
                  padding: isSmallScreen ? "0" : "1.8rem 0 0 1.2rem",
                }}
              >
                <PriceComponent />
              </Box>
            </Box>
            <Box sx={{ margin: isSmallScreen ? "0.8rem 1rem" : "0.5rem 0 0" }}>
              <YouMayAlsoLike />
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"0.5rem"}
            height={"80vh"}
          >
            <Box>
              <img src={EmptyCart} />
            </Box>
            <Box sx={{ paddingTop: "1rem" }}>
              <CustomTypography variant={"h4"} sx={{ fontWeight: "600" }}>
                Your Cart is Empty
              </CustomTypography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              sx={{ paddingTop: "1rem" }}
            >
              <CustomTypography
                variant={"subtitle1"}
                sx={{ fontWeight: "600", textAlign: "center" }}
              >
                Looks like you have not added anything to your Cart.
              </CustomTypography>
              <CustomTypography
                variant={"subtitle1"}
                sx={{ fontWeight: "600" }}
              >
                Go ahead and explore top categories.
              </CustomTypography>
            </Box>
            <Box></Box>
          </Box>
        </>
      )}
    </>
  );
};

export default AddToCart;
