import React from 'react';
import AddNewAddress from '../components/addAddressMobile/addNewAddress/AddNewAddress';

const AddNewAddressPage = () => {
  return (
    <>
      <AddNewAddress />
    </>
  );
};

export default AddNewAddressPage;
