import { Box, Button, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { KeyboardArrowDown } from '@mui/icons-material/';
import CustomTypography from '../../../../../muicomponents/shared/CustomTypography';
import { useRecoilState } from 'recoil';
import { sortState } from '../../../../../store/atoms/productListing/productListing';

const SortComponent = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortCriteria, setSortCriteria] = useRecoilState(sortState);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSortChange = (value) => {
    let field = 'productPrice';
    let order = value === 'low_to_high' ? 'asc' : 'desc';
    setSortCriteria({ field, order });
    handleClose();
  };

  const isSortSelected = sortCriteria.field === 'productPrice';

  const getSortLabel = () => {
    if (sortCriteria.field === 'productPrice') {
      return sortCriteria.order === 'asc'
        ? ' Price - Low to High'
        : ' Price - High to Low';
    }
  };

  return (
    <Box>
      <Button onClick={handleOpen} sx={{ color: 'black' }}>
        <CustomTypography
          variant={isSortSelected ? 'subtitle2' : 'subtitle1'}
          sx={{
            fontWeight: isSortSelected ? '400' : '500',
          }}
        >
          Sort By {isSortSelected && ': '}
        </CustomTypography>
        <CustomTypography variant={'subtitle2'} sx={{ fontWeight: '600' }}>
          {getSortLabel()}
        </CustomTypography>
        <KeyboardArrowDown />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          '& .MuiMenu-paper': {
            padding: 0,
          },
          '& .MuiMenu-list': {
            padding: 0,
          },
        }}
      >
        <MenuItem
          sx={{ padding: '0 0.5rem', borderBottom: '1px solid black' }}
          onClick={() => handleSortChange('low_to_high')}
          selected={
            sortCriteria.field === 'productPrice' &&
            sortCriteria.order === 'asc'
          }
        >
          <CustomTypography
            variant={'subititle2'}
            sx={{ fontWeight: '500', fontSize: '0.9rem' }}
          >
            Price - Low to High
          </CustomTypography>
        </MenuItem>
        <MenuItem
          sx={{ padding: '0 0.5rem' }}
          onClick={() => handleSortChange('high_to_low')}
          selected={
            sortCriteria.field === 'productPrice' &&
            sortCriteria.order === 'desc'
          }
        >
          <CustomTypography
            variant={'subititle2'}
            sx={{ fontWeight: '500', fontSize: '0.9rem' }}
          >
            Price - High to Low
          </CustomTypography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default SortComponent;
