import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import '../ComponentsCss/WhyChooseUs.css';

import step1 from '../Images/homepage/whyicon/step1.png';
import step2 from '../Images/homepage/whyicon/step2.png';
import step3 from '../Images/homepage/whyicon/step3.png';
import step4 from '../Images/homepage/whyicon/step4.png';
import step5 from '../Images/homepage/whyicon/step5.png';

import Lottie from 'react-lottie';
import pic from '../Anim/hellofi.json';

// import pic from "../Images/137259-outreach-b2b-process.json";

export default function WhyChooseUs() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: pic,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <>
      <Container className='whychooseus'>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              height: 40,
              width: 5,
              backgroundColor: '#44CE30',
              marginTop: 10,
            }}
          ></div>
          <h3 style={{ marginTop: 20, marginLeft: 10, fontWeight: 500 }}>
            Why People Trust Us
          </h3>
        </div>

        <Row>
          <Col md={4}>
            <div
              className='whycard'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div className='imgwhy'>
                {' '}
                <Image src={step1} className='imgchoose'></Image>
              </div>
              <div style={{ textAling: 'left' }}>
                <h4 className='txtchoose'>One Step Solution</h4>
                <p className='txtchoose2'>Buy, Sell & Exchange Your Gadgets</p>
              </div>
            </div>
            <div
              className='whycard'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div className='imgwhy'>
                {' '}
                <Image src={step2} className='imgchoose'></Image>
              </div>
              <div style={{ textAling: 'left' }}>
                <h4 className='txtchoose'>Best Price Guarantee</h4>
                <p className='txtchoose2'>
                  We offer you the best price when you deal
                </p>
              </div>
            </div>
            <div
              className='whycard'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div className='imgwhy'>
                {' '}
                <Image src={step3} className='imgchoose'></Image>
              </div>
              <div style={{ textAling: 'left' }}>
                <h4 className='txtchoose'>Safe And Secure</h4>
                <p className='txtchoose2'>
                  No more privacy concerns for your data
                </p>
              </div>
            </div>
            <div
              className='whycard'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div className='imgwhy'>
                {' '}
                <Image src={step4} className='imgchoose'></Image>
              </div>
              <div style={{ textAling: 'left' }}>
                <h4 className='txtchoose'>24×7 Customer Support</h4>
                <p className='txtchoose2'>Our support is here to help you.</p>
              </div>
            </div>
            <div
              className='whycard'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div className='imgwhy'>
                {' '}
                <Image src={step5} className='imgchoose'></Image>
              </div>
              <div style={{ textAling: 'left' }}>
                <h4 className='txtchoose'>Quick And Hasslefree</h4>
                <p className='txtchoose2'>Send the deal in just 3 steps</p>
              </div>
            </div>
          </Col>

          <Col md={8}>
            {/* <Image src={whyimg} style={{ height: '100%', width: '100%' }}></Image> */}
            <Lottie
              options={defaultOptions}
              style={{ width: '80%', height: '80%', marginTop: 20 }}
            />
         

            {/* <Lottie options={defaultOptions} style={{width:300, height:550,marginTop:20}}/> */}
          </Col>
        </Row>
      </Container>
    </>
  );
}
