import { MUTATION_PURCHASE_ADD_ADDRESS } from "../../../../graphql/Mutations";
import { useMutation } from "@apollo/client";
import { useSetRecoilState } from "recoil";
import {
  allAddressAtom,
  allAddressPageBackdrop,
  selectedAddressIdState,
} from "../../../../store/atoms/addAddress/addAddressAtoms";

const useAddAddress = () => {
  const [addAddress, { loading, error }] = useMutation(
    MUTATION_PURCHASE_ADD_ADDRESS
  );
  const setSelectedAddressID = useSetRecoilState(selectedAddressIdState);
  const setAddress = useSetRecoilState(allAddressAtom);
  const setBackdropOpen = useSetRecoilState(allAddressPageBackdrop);
  const addNewAddress = async (input) => {
    try {
      setBackdropOpen(true);
      const { data } = await addAddress({
        variables: { input },
      });

      if (data.addAddress) {
        const newAddress = data.addAddress;

        setAddress((prevAddresses) => {
          const updatedAddresses = prevAddresses.map((address) => ({
            ...address,
            isDefault: newAddress.isDefault ? false : address.isDefault,
          }));
          updatedAddresses.push(newAddress);
          return updatedAddresses;
        });
        setSelectedAddressID(data.addAddress.id);
      }

      setBackdropOpen(false);
    } catch (err) {
      console.error("Error updating cart item checkout:", err);
      setBackdropOpen(false);
      throw err;
    }
  };
  return { addNewAddress };
};

export default useAddAddress;
