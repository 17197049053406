import {
  Box,
  Button,
  Modal,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Right from "../../../../../Images/payment/Right.png";
import Red from "../../../../../Images/payment/Red.png";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import {
  orderIdAtom,
  paymentInfoModalOpen,
} from "../../../../../store/atoms/payment/paymentAtom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

const PaymentSuccessComponent = ({ success, openModal }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const orderId = useRecoilValue(orderIdAtom);
  const isTabletScreen = useMediaQuery(
    theme.breakpoints.between("laptop", "desktop")
  );
  const setOpenModal = useSetRecoilState(paymentInfoModalOpen);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("tablet"));
  const [countdown, setCountdown] = useState(10);
  useEffect(() => {
    if (openModal) {
      const timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);

      const closeTimer = setTimeout(() => {
        handleModalClose();
      }, 10000);

      return () => {
        clearInterval(timer);
        clearTimeout(closeTimer);
      };
    }
  }, [openModal]);

  useEffect(() => {
    if (countdown <= 0) {
      handleModalClose();
    }
  }, [countdown]);
  const handleModalClose = () => {
    setCountdown(10);
    setOpenModal(false);
    navigate(`/order-summary/${orderId}`);
  };
  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 9999,
        }}
      >
        <Box
          sx={{
            width: "450px",
            justifyContent: success ? "" : "center",
            minHeight: success ? "40vh" : "80vh",
            backgroundColor: "rgb(6, 155, 170)",
            borderRadius: "1rem",
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <Box
            sx={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              sx={{
                width: "100px",
                height: "100px",
                backgroundColor: "white",
                borderRadius: "50%",
              }}
            >
              {success ? (
                <img
                  src={Right}
                  alt=""
                  style={{ width: "100px", height: "100px" }}
                />
              ) : (
                <img
                  src={Red}
                  alt=""
                  style={{ width: "75px", height: "75px" }}
                />
              )}
            </Box>
          </Box>

          <Box
            sx={{
              marginTop: "1rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CustomTypography
              variant={"h5"}
              sx={{ color: "white", fontWeight: "600" }}
            >
              {success ? "Order Confirmed" : "Oops! Payment Failed"}
            </CustomTypography>
          </Box>
          <Box sx={{ marginTop: "1rem" }}>
            {success ? (
              <>
                <CustomTypography
                  variant={"subtitle2"}
                  sx={{
                    color: "white",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  Thanks for being an active member of the HelloFi Family.
                </CustomTypography>
                <CustomTypography
                  variant={"subtitle2"}
                  sx={{
                    color: "white",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  You will receive email confirmation shortly.
                </CustomTypography>
              </>
            ) : (
              <CustomTypography
                variant={"subtitle2"}
                sx={{ color: "white", fontWeight: "600", textAlign: "center" }}
              >
                An error occurred while processing your payment.
              </CustomTypography>
            )}
          </Box>
          <Box>
            {success ? (
              <CustomTypography
                variant={"subtitle2"}
                sx={{ color: "white", fontWeight: "600", textAlign: "center" }}
              >
                Check the status of your order on the My Orders page.
              </CustomTypography>
            ) : (
              <CustomTypography
                variant={"subtitle2"}
                sx={{ color: "white", fontWeight: "600", textAlign: "center" }}
              >
                You are just an inch away from your dream gadget.
              </CustomTypography>
            )}
          </Box>
          {/* {success && (
            <Box>
              <CustomTypography
                variant={"subtitle1"}
                sx={{ color: "white", fontWeight: "700" }}
              >
                Valuable Feedback (if any)
              </CustomTypography>
            </Box>
          )}
          {success && (
            <Box>
              <TextField
                multiline
                rows={4}
                placeholder="Enter your text here..."
                fullWidth
                InputProps={{
                  style: {
                    outline: "none",
                    backgroundColor: "white",
                    border: "none",
                    padding: "1rem",
                  },
                }}
              />
            </Box>
          )} */}
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={"0.5rem"}
            alignItems={"center"}
            sx={{ marginTop: "1rem" }}
          >
            <Button
              vairant={"contained"}
              sx={{
                color: "black",
                width: "80%",
                borderRadius: "3rem",
                padding: "0.5rem",
                backgroundColor: "white",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: "white",
                },
              }}
            >
              <CustomTypography
                variant={"subtitle1"}
                sx={{ fontweight: "700", color: "rgb(6, 155, 170)" }}
              >
                {success ? " Continue Shopping" : "Retry Payment"}
              </CustomTypography>
            </Button>
            <Button
              vairant={"contained"}
              sx={{
                color: "black",
                width: "80%",
                borderRadius: "3rem",
                padding: "0.5rem",
                backgroundColor: "white",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: "white",
                },
              }}
            >
              <CustomTypography
                variant={"subtitle1"}
                sx={{ fontweight: "700", color: "rgb(6, 155, 170)" }}
              >
                {success ? "Go To My Orders" : "Continue Shopping"}
              </CustomTypography>
            </Button>
          </Box>

          <Box>
            <CustomTypography
              variant={"subtitle2"}
              sx={{ color: "white", fontWeight: "600", textAlign: "center" }}
            >
              This will close in {countdown}
            </CustomTypography>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default PaymentSuccessComponent;
