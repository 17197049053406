import { Box, Button, useMediaQuery } from "@mui/material";
import React, { useRef } from "react";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import {
  GET_LATEST_BUY_PRODUCTS,
  QUERY_GET_FILTERED_BUY_PRODUCT,
} from "../../../../graphql/Query";
import { useQuery } from "@apollo/client";
import { useRecoilValue } from "recoil";
import { productAtom } from "../../../../store/atoms/productDetails/productAtom";
import RelatedProductCard from "./RelatedProductCard";
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import RelatedProductContainer from "./RelatedProductContainer";

const RecentlyAdded = ({ product }) => {
  const isSmallScreen = useMediaQuery("(max-width:767px)");

  const { data, loading, error } = useQuery(GET_LATEST_BUY_PRODUCTS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <Box display={"flex"} flexDirection={"column"}>
        <Box>
          <CustomTypography
            variant="h4"
            sx={{
              fontOpticalSizing: "auto",
              fontWeight: "600",
              fontSize: isSmallScreen ? "20px" : "24px",
            }}
          >
            Recently Added
          </CustomTypography>
        </Box>

        <RelatedProductContainer
          products={data.getLatestBuyProducts}
          length={data.getLatestBuyProducts.length}
        />
      </Box>
    </>
  );
};

export default RecentlyAdded;
